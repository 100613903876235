import React from 'react'
import ContentLoader from 'react-content-loader'

const ArticulosTableLoader = ({ ...rest }) => {
  return (
    <div style={{ overflow: 'hidden', margin: '1rem' }}>
      <ContentLoader height="800" width="1000" viewBox="0 0 1000 800" {...rest}>
        <rect x="0" y="15" rx="4" ry="4" width="1000" height="50" />
        <rect x="0" y="75" rx="2" ry="2" width="1000" height="2000" />
        {/* <rect x="15" y="230" rx="2" ry="2" width="170" height="20" />
      <rect x="60" y="230" rx="2" ry="2" width="170" height="20" /> */}
      </ContentLoader>
    </div>
  )
}

export default ArticulosTableLoader
