import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useHandleUrl } from '../../../hooks/useHandleUrl'
import toast from 'react-hot-toast'
import ActivitiesCheckbox from '../NewCelula/ActivitiesCheckbox'
import { Button } from 'reactstrap'
import { getAllActivitiesNotInProject, updateCelula } from '../../../api/jitApi'
import ArticulosTableLoader from '../../Loaders/ArticulosTableLoader'
import TextInput from 'components/ui/Forms/TextInput'

const EditCelula = ({ celula }) => {
  const [code, setCode] = useState(celula.code)
  const [name, setName] = useState(celula.name)
  const navigate = useNavigate()
  const { changeLocation } = useHandleUrl()
  const [checkedActivities, setCheckedActivities] = useState(
    celula.activities.map(cel => cel._id)
  )

  const { idProject } = useParams()
  // TODO: Get all activities not assigned to a project
  const { data, isLoading, error } = useQuery(
    ['activities-not-project', idProject],
    () => getAllActivitiesNotInProject(idProject)
  )

  const handleCheckboxChange = event => {
    const { value } = event.target
    const isSelected = checkedActivities.includes(value)

    if (isSelected) {
      setCheckedActivities(prev => prev.filter(id => id !== value))
    } else {
      setCheckedActivities(prev => [...prev, value])
    }
  }

  const handleOnClick = async () => {
    if (!code || !name || !checkedActivities.length) {
      toast.error('Todos los campos tienen que estar rellenos')
      return
    }

    const body = {
      code,
      name,
      activities: checkedActivities,
    }
    updateCelula(celula._id, body)
      .then(() => {
        toast.success('Célula actualizada correctamente')
        navigate(
          changeLocation(`/viewer/${idProject}/fabrica/gestionar-celulas`)
        )
      })
      .catch(e =>
        toast.error(
          'Hubo un error al actualizar la célula. Vuelva a intentarlo'
        )
      )
  }

  if (!data || isLoading) return <ArticulosTableLoader />
  return (
    <>
      <div className="edit-celula-container">
        <TextInput
          label="Código"
          name="code"
          onChange={e => setCode(e.target.value)}
          value={code}
        />

        <TextInput
          label="Nombre"
          name="name"
          onChange={e => setName(e.target.value)}
          value={name}
        />
        <ActivitiesCheckbox
          activities={[...celula.activities, ...data.data]}
          checkedActivities={checkedActivities}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>

      <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
        <div>
          <Button
            className="button-new-partida button-new-partida--white"
            color="none"
            onClick={() =>
              navigate(
                changeLocation(`/viewer/${idProject}/fabrica/gestionar-celulas`)
              )
            }
          >
            Cancelar
          </Button>
        </div>
        <div>
          <Button
            className="button-new-partida"
            color="none"
            onClick={handleOnClick}
          >
            Actualizar célula
          </Button>
        </div>
      </div>
    </>
  )
}

export default EditCelula
