import React, { useEffect, useState } from 'react'
import SelectTypeUser from './SelectTypeUser'
import AdminsToProject from './AdminsToProject'
import UsersToProject from './UsersToProject'
import { Button, CircularProgress } from '@mui/material'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import CustomSearch from './CustomSearch'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const InviteUser = ({ project }) => {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [radioValue, setRadioValue] = useState('invitado')
  const [projectSelected, setProjectSelected] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [usersFiltered, setUsersFiltered] = useState([])
  const [loadingInvite, setLoadingInvite] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        let projectSelected = await axios.get(`/api/v1/proyectos/${project}`)
        setProjectSelected(projectSelected.data.data)
        let res = await axios.get('/api/v1/auth0/getAllUsers')
        let filteredUsers = res.data.data.filter(u => u.user_id !== user.sub)
        setUsers(
          filteredUsers.map(u => {
            return {
              id: u.user_id,
              email: u.email,
              img: u.picture,
            }
          })
        )
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (projectSelected) {
      let filteredUsers = users.filter(
        user =>
          !projectSelected.users.some(u => u === user.id) &&
          !projectSelected.user.some(u => u === user.id)
      )

      setUsersFiltered(filteredUsers)
    }
  }, [projectSelected, users])

  const handleOnSubmit = async () => {
    setLoadingInvite(true)
    try {
      await axios.patch(
        `/api/v1/proyectos/${projectSelected._id}/addUsers`,
        radioValue === 'invitado'
          ? {
              users: selectedUsers.map(u => {
                return {
                  id: u.id,
                  email: u.email,
                  admin: false,
                }
              }),
              userName: user.nickname,
              projectName: projectSelected.name,
            }
          : {
              user: selectedUsers.map(u => {
                return {
                  id: u.id,
                  email: u.email,
                  admin: true,
                }
              }),
              userName: user.nickname,
              projectName: projectSelected.name,
            }
      )

      setLoadingInvite(false)
      toast.success('Usuario/s invitado/s')
      navigate('/')
    } catch (error) {
      setLoadingInvite(false)
      toast.error('Error al invitar usuario')
    }
  }

  return loading || !users ? (
    <div className="container-circular-progress">
      <CircularProgress size="2rem" color="secondary" />
    </div>
  ) : (
    <div className="main-container d-flex j-center">
      <div style={{ width: '70%', padding: '0 5rem' }}>
        <h1 className="montserrat title-form">Invitar Usuarios</h1>
        <div className="pt-4 pb-4">
          {/* ELEGIR TIPO DE USUARIO */}
          <SelectTypeUser
            setRadioValue={setRadioValue}
            radioValue={radioValue}
          />
        </div>
        {/* BUSCADOR DE USUARIOS */}
        <div className="pt-4 pb-4">
          <CustomSearch
            setSelectedUser={setSelectedUsers}
            usersFilter={usersFiltered}
          />
        </div>
        {/* LISTA DE USUARIOS */}
        <div className="h-450px scroll-auto border-container">
          {projectSelected.user?.length > 0 && (
            <AdminsToProject admins={projectSelected.user} allUsers={users} />
          )}
          {projectSelected.users?.length > 0 && (
            <UsersToProject users={projectSelected.users} allUsers={users} />
          )}
        </div>
        {/* BOTONES  */}
        <div className="pt-2 f-right d-flex g-15">
          <Button
            onClick={() => navigate('/')}
            color="primary"
            variant="contained"
            className="button-white"
          >
            Cancelar
          </Button>
          <Button
            endIcon={
              loadingInvite && (
                <CircularProgress size="1rem" color="secondary" />
              )
            }
            disabled={selectedUsers.length === 0}
            onClick={handleOnSubmit}
            className="btn-submit"
            variant="contained"
          >
            Invitar
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InviteUser
