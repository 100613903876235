import { checkProjectVersion } from 'api/projectApi'
import { ViewerContext } from 'context/ViewerContext'
import toast from 'react-hot-toast'
import launchViewer from 'utils/launchViewer'

const { useProject } = require('context/ProyectoContext')
const { useEffect, useMemo, useContext } = require('react')
const { useSearchParams, useParams, useNavigate } = require('react-router-dom')

export const useLaunchViewer = () => {
  let [searchParams] = useSearchParams()
  const { idProject } = useParams()
  const { versions } = useProject()
  const navigate = useNavigate()
  const {
    setExternalIdsMapping,
    setUniqueProperties,
    setAllModulos,
    setAllDbIds,
  } = useContext(ViewerContext)
  const currentVersion = useMemo(() => {
    if (!versions) return null
    return (
      searchParams.get('version') ||
      Math.max(...versions.map(item => Number(item.attributes.versionNumber)))
    )
  }, [searchParams.get('version'), versions])

  const selectedVersionUrn = useMemo(() => {
    if (!versions) return null
    const versionNumber =
      searchParams.get('version') ||
      Math.max(...versions.map(item => Number(item.attributes.versionNumber)))

    return (
      versions.find(item => item.attributes.versionNumber == versionNumber) ||
      null
    )
  }, [searchParams.get('version'), versions])

  const loadViewer = async setOpenModalVersionTranslate => {
    setExternalIdsMapping(null)
    setUniqueProperties(null)
    setAllModulos(null)
    setAllDbIds(null)
    // TODO: Con el id del proyecto y el número de la versión seleccionada (si no hay seleccionada, coger la última), enviar petición al servidor para obtener la urn de esa versión
    const versionParam = searchParams.get('version') || 0
    console.log('versionParam', versionParam)
    // TODO: Si esa versión no existe, redirigir a viewer/:idProject
    try {
      const { data: dataVersion } = await checkProjectVersion(
        idProject,
        parseInt(versionParam)
      )

      console.log('dataVersion', dataVersion)
      if (
        dataVersion.manifest.status == 'inprogress' ||
        dataVersion.manifest.status == 'pending'
      ) {
        setOpenModalVersionTranslate(true)
      }

      launchViewer(
        dataVersion.manifest.urn,
        null,
        setExternalIdsMapping,
        setUniqueProperties,
        setAllModulos,
        setAllDbIds
      )
    } catch (e) {
      console.log('Error', e.response.data)
      if (e.response.status == 404 && e.response.data.type == 'VERSION_ERROR') {
        toast.error(
          'La versión indicada no existe. Redirigiendo a la última...'
        )
        navigate(`/viewer/${idProject}`)
      }

      if (
        e.response.status == 404 &&
        e.response.data.type == 'GEOMETRY_ERROR'
      ) {
        toast.error(
          'La versión indicada no tiene geometría. Selecciona otra versión.',
          {
            duration: 8000,
          }
        )
      }
    }
  }

  return { currentVersion, selectedVersionUrn, loadViewer }
}
