import React from 'react'

const CheckboxInput = ({ label, children, ...props }) => {
  return (
    <div className="new-celula-checkbox-input-container">
      <input
        type={props.type || 'checkbox'}
        className="new-celula-checkbox-input"
        {...props}
      />
      <label
        htmlFor="activity"
        style={{ marginLeft: '.5rem' }}
        className="checbox-input-label"
      >
        {label}
        {children}
      </label>
    </div>
  )
}

export default CheckboxInput
