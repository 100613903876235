import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { getCurrentProjectInfo } from '../../services/api/queries'
import handleCapitulos from '../../utils/handleCapitulos'
import launchViewer from '../../utils/launchViewer'

export const getAllProjects = createAsyncThunk(
  'proyectos/getAll',
  async filter => {
    const { data } = await axios.get('/api/v1/proyectos?disabled=' + filter)
    return data.data.data
  }
)

export const getProjectById = async id => {
  const { data } = await axios.get(`/api/v1/proyectos/${id}`)
  const response = await axios.get(
    `/api/v1/forge/modelderivative/thumbnail/${data.data?.urn}`
  )
  const project = {
    id: data.data._id,
    name: data.data.name,
    description: data.data.description,
    status: data.data.status,
    urn: data.data.urn,
    code: data.data.code,
    user: data.data.user,
    img: response.data.data,
  }

  return project
}

export const getFilterProjects = async filter => {
  const res = await axios.get('/api/v1/proyectos/filter?filter=' + filter)
  return res.data.data
}

export const getCapitulosYPartidasByProject = createAsyncThunk(
  'proyectos/getCapitulosYPartidas',
  async (id, { getState, dispatch }) => {
    const state = getState()
    const { currentLimitPagination, currentPagePagination } = state.proyecto
    const params = {
      page: currentPagePagination,
      limit: currentLimitPagination,
    }
    // Capitulos
    const { data } = await getCurrentProjectInfo(id)
    const capitulos = await handleCapitulos(data)

    // Partidas

    const { data: partidas, config } = await axios.get(
      `/api/v1/proyectos/${id}/partidas?`,
      {
        params,
      }
    )

    dispatch(setLastQuery({ url: config?.url, params: config?.params }))
    return { capitulos, partidas }
  }
)

export const getHub = createAsyncThunk(
  'proyectos/getHub',
  async (data, { getState, dispatch }) => {
    const { item, initial } = data
    const state = getState()
    const { hub } = state.proyecto
    if (initial) {
      const { data } = await axios.get(
        `/api/v1/forge/datamanagement?id=${item?.id}`
      )
      return data
    }

    if (!item) {
      const { data } = await axios.get(
        '/api/v1/forge/datamanagement?id=https://developer.api.autodesk.com/project/v1/hubs/b.5e1fd49f-0eac-4878-8065-a8710bb8c094'
      )
      return data
    }
    // Comprobar si hay algún elemento del array que tenga item.parentId == item.id
    if (hub.filter(h => h?.parentId === item?.id).length === 0) {
      let { data } = await axios.get(
        `/api/v1/forge/datamanagement?id=${item?.id}`
      )
      let handleData = data.map(child => ({ ...child, parentId: item?.id }))
      let updatedHub = [...hub, ...handleData]
      if (item.type === 'items') {
        launchViewer(data[0].id)
        dispatch(setCurrentUrn(data[0].id))
      }
      return updatedHub
    }
  }
)
// {children, id, text, type, parentId}
//
// {projects } = initialState
// const var = initialState.projects
const initialState = {
  projects: null,
  currentProject: null,
  loading: true,
  lastQuery: {},
  sortBy: ' ', // " " / price / -price
  limitBy: 6,
  loadingPresupuesto: false,
  currentCapitulos: null,
  currentPartidas: null,
  currentUrn: null,
  // currentPagePagination: 1,
  currentLimitPagination: 6, // Partidas mostradas por página
  hub: null,
}

export const proyectoSlice = createSlice({
  name: 'proyecto',
  initialState,
  reducers: {
    setLimitBy: (state, { payload }) => {
      state.currentLimitPagination = payload
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload
    },
    setCurrentProject: (state, { payload }) => {
      state.currentProject = payload
    },
    setLoadingPresupuesto: (state, { payload }) => {
      state.loadingPresupuesto = payload
    },
    setCurrentPartidas: (state, { payload }) => {
      state.currentPartidas = payload
    },
    setLastQuery: (state, { payload }) => {
      state.lastQuery = payload
    },
    setCurrentUrn: (state, { payload }) => {
      state.currentUrn = payload
    },
    setHub: (state, { payload }) => {
      state.hub = payload
    },
  },
  extraReducers: {
    // [getCapitulosYPartidasByProject.pending]: (state) => {
    //   state.loadingPresupuesto = true;
    // },
    // [getCapitulosYPartidasByProject.fulfilled]: (state, { payload }) => {
    //   state.currentCapitulos = payload.capitulos;
    //   state.currentPartidas = payload.partidas;
    //   state.loadingPresupuesto = false;
    // },
    [getAllProjects.pending]: state => {
      state.loading = true
    },
    [getAllProjects.fulfilled]: (state, { payload }) => {
      state.projects = payload
      state.loading = false
    },
    [getHub.fulfilled]: (state, { payload }) => {
      state.hub = payload
    },
  },
})

export const {
  setCurrentProject,
  setLoadingPresupuesto,
  setCurrentPartidas,
  setLastQuery,
  setSortBy,
  setLimitBy,
  setCurrentUrn,
  setHub,
} = proyectoSlice.actions

export default proyectoSlice.reducer
