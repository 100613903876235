import { useState } from 'react'
import './MainDashboard.css'
import ProyectosList from './ProyectosList'

const FILTER_ITEMS = [
  { name: 'Todos', value: null },
  { name: 'Activos', value: false },
  { name: 'Inactivos', value: true },
]

const MainDashboard = () => {
  const [filter, setFilter] = useState(FILTER_ITEMS[0])

  return (
    <section className="section-projects">
      <div className="container">
        <h1 className="heading-primary">Proyectos</h1>
        <nav className="projects-nav">
          <div class="dropdown">
            <button class="btn btn-xl">{filter.name}</button>
            <div class="dropdown-content">
              {FILTER_ITEMS.map(item => (
                <div onClick={() => setFilter(item)}>{item.name}</div>
              ))}
            </div>
          </div>
        </nav>
        <ProyectosList disabled={filter.value} />
      </div>
    </section>
  )
}

export default MainDashboard
