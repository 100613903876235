import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useHeaderNavigation } from '../../../hooks/useHeaderNavigation'

const ViewerPanelHeaderBreadcrumbs = ({ link, param }) => {
  const { getParamName } = useHeaderNavigation()

  return (
    <Link
      key={param}
      underline="hover"
      to={link}
      className="breadcrumb-item-header-nav"
    >
      {getParamName(param)}
    </Link>
  )
}

export default ViewerPanelHeaderBreadcrumbs
