import React, { useState } from 'react'
import { viewer } from 'utils/launchViewer'
import Loader from 'components/Spinner/Loader'
import { useFilterElementsInModel } from 'hooks/useFilterElementsInModel'
import { CustomButton } from 'components/ui'

const ButtonItem = ({ name, onClick }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleOnClick = async () => {
    setIsLoading(true)
    await onClick()
    setIsLoading(false)
  }

  return (
    <CustomButton onClick={handleOnClick} style={{ padding: '1rem 2rem' }}>
      {isLoading ? <Loader props={{ marginLeft: '.5rem' }} /> : name}
    </CustomButton>
  )
}

const ButtonsElementContainer = () => {
  const {
    showCodeElements,
    showNoCodeElements,
    showMeasuredElements,
    showNoMeasuredElements,
  } = useFilterElementsInModel()

  return (
    <div
      // className="select-version-container"
      style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
    >
      <ButtonItem
        name="Medidos"
        onClick={() => showMeasuredElements(true, true)}
      />
      <ButtonItem
        name="No medidos"
        onClick={() => showNoMeasuredElements(true, true)}
      />
      <ButtonItem
        name="Codificados"
        onClick={() => showCodeElements(true, true)}
      />
      <ButtonItem
        name="No Codificados"
        onClick={() => showNoCodeElements(true, true)}
      />
      <ButtonItem
        name="Mostrar todos"
        onClick={() => {
          viewer.clearThemingColors()
          viewer.isolate([])
        }}
      />
    </div>
  )
}

export default ButtonsElementContainer
