import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { DashboardProvider } from '../context/DashboardContext'
import Header from 'components/Header'
import useAxiosInterceptor from 'hooks/useAxiosInterceptor'

const DashboardContainer = () => {
  useAxiosInterceptor()
  return (
    <DashboardProvider>
      <Header />
      <Outlet />
    </DashboardProvider>
  )
}

export default DashboardContainer
