import cls from 'classnames'

const Box = ({ className, children, ...props }) => {
  return (
    <div className={cls('box', className)} {...props}>
      {children}
    </div>
  )
}

export default Box
