import { useAuth0 } from '@auth0/auth0-react'

import './Header.css'
import Dropdown, { DropDownItem } from './ui/Dropdown/Dropdown'
import { useState } from 'react'
import LogoHeader from './Header/LogoHeader'
import ButtonPanelAdmin from './Header/ButtonPanelAdmin'
import { isAuthorizedByRole } from 'utils/auth0'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useAuth0()
  const redirectLogoutUrl = process.env.REACT_APP_AUTH0_LOGOUT_URL
  const { logout, isAuthenticated: isAuthenticatedAuth0 } = useAuth0()

  return (
    <header class="header">
      <LogoHeader />
      <nav className="main-nav">
        {isAuthenticatedAuth0 && (
          <>
            {isAuthorizedByRole(user, 'Admin') && <ButtonPanelAdmin />}
            <ul className="main-nav-list">
              <Dropdown
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                button={
                  <li
                    className="main-nav-user"
                    onClick={() => setIsOpen(prevState => !prevState)}
                  >
                    <p className="m-0 roboto-condensed email-text-header">
                      {user.email}
                    </p>
                    <img alt="Username" src={user.picture} />
                  </li>
                }
              >
                <DropDownItem
                  icon={
                    <ion-icon
                      class="main-nav-logout"
                      name="log-out-outline"
                    ></ion-icon>
                  }
                  item={<p style={{ fontSize: '1.3rem' }}>Desconectarse</p>}
                  onClick={() =>
                    logout({ logoutParams: { returnTo: redirectLogoutUrl } })
                  }
                />
              </Dropdown>
            </ul>
          </>
        )}
      </nav>
    </header>
  )
}

export default Header
