import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useGroupBimLink } from './GroupBimLinkContext'

export const ArticuloBimLinkContext = createContext()

export const ArticuloBimLinkProvider = ({ articulo, children }) => {
  const { addArticulo, updateSingleArticulo } = useGroupBimLink()
  const [selectedActividad, setSelectedActividad] = useState(
    articulo?.actividad || null
  )
  const [selectedArticulo, setSelectedArticulo] = useState(
    articulo?.articulo || null
  )
  const [formula, setFormula] = useState(articulo?.formula || null)
  const isEditArticuloMode = useMemo(() => {
    return articulo ? true : false
  }, [articulo])

  const handleFormula = e => {
    setFormula(e.target.value)
  }

  const createArticulo = () => {
    if (!selectedActividad || !selectedArticulo || !formula)
      return toast.error('Todos los campos tienes que estar rellenos')

    const newArticulo = {
      actividad: selectedActividad,
      articulo: selectedArticulo,
      formula,
    }


    isEditArticuloMode
      ? updateSingleArticulo({ ...newArticulo, uuid: articulo.uuid })
      : addArticulo(newArticulo)
  }

  const data = {
    isEditArticuloMode,
    selectedActividad,
    setSelectedActividad,
    selectedArticulo,
    setSelectedArticulo,
    formula,
    handleFormula,
    createArticulo,
  }

  return (
    <ArticuloBimLinkContext.Provider value={data}>
      {children}
    </ArticuloBimLinkContext.Provider>
  )
}

export const useArticuloBimLink = () => {
  const context = useContext(ArticuloBimLinkContext)
  if (context === undefined) {
    throw new Error(
      'useArticuloBimLink must be used within a ArticuloBimLinkProvider'
    )
  }
  return context
}
