import { Outlet } from 'react-router-dom'
import { useProject } from '../../context/ProyectoContext'
import ViewerPanelHeaderNavigation from './HeaderNavigation/ViewerPanelHeaderNavigation'
import ViewerPanelPageTitle from './ViewerPanelPageTitle'

const ViewerPanel = () => {
  const { currentProject } = useProject()
  return (
    <div className="viewer-panel">
      <div className="viewer-panel-header">
        <h1>{currentProject.name}</h1>
        <ViewerPanelHeaderNavigation />
      </div>
      <ViewerPanelPageTitle />
      <div className="viewer-panel-body">
        <Outlet />
      </div>
    </div>
  )
}

export default ViewerPanel
