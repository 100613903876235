import WrapperPage from '../components/Wrapper/WrapperPage'
import MainDashboard from '../components/dashboard/MainDashboard'
import { PaginationProjectsProvider } from '../context/PaginationProjectsContext'

const Dashboard = () => {
  return (
    <WrapperPage>
      <PaginationProjectsProvider>
        <MainDashboard />
      </PaginationProjectsProvider>
    </WrapperPage>
  )
}

export default Dashboard
