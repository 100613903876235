import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { getCurrentModelVersion } from 'utils/launchViewer'
import {
  getAllManoDeObra,
  updateProject,
  getVersionsImportByProject,
  getModuloC,
  getImportCelulasByModuloId,
  getCurrentProjectAndCheckVersion,
} from 'api/jitApi'
import { getAllExternalIdsByProjectAndVersion } from 'api/estructuraCostes/boqJitApi'
import { getAllActivities } from 'api/estructuraCostes/activitiesJitApi'
import {
  getAllArticulos,
  getAllArticulosByProject,
  getArticuloById,
  getArticuloProyecto,
  updateArticulo,
  updatePriceArticuloProyecto,
} from 'api/estructuraCostes/articulosJitApi'
import {
  createBimlink,
  deleteBimlink,
  getBimlinks,
  updateBimlink,
} from 'api/estructuraCostes/bimLinksJitApi'
import toast from 'react-hot-toast'
import { getAllFields, getDisplayValues } from 'api/modelPropertiesApi'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { useVersionModel } from 'hooks/useVersionModel'

/* -------------------------------------------------------------------------- */
/*                               Project Queries                              */
/* -------------------------------------------------------------------------- */

export const useUpdateProject = () => {
  const queryClient = useQueryClient()
  const { currentVersion } = useVersionModel()
  return useMutation({
    mutationFn: ({ idProject, body }) => updateProject(idProject, body),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['project', data._id],
      })
      queryClient.invalidateQueries({ queryKey: ['celulas', data._id] })
      queryClient.invalidateQueries({
        queryKey: ['modulos-articulos', data._id, currentVersion],
      })
    },
  })
}

export const useGetCurrentProject = idProject => {
  console.log('entroaquí', idProject)
  return useQuery({
    queryKey: ['project', idProject],
    queryFn: () => getCurrentProjectAndCheckVersion(idProject),
  })
}

/* -------------------------------------------------------------------------- */
/*                               Modulo Queries                               */
/* -------------------------------------------------------------------------- */
export const useGetModuloById = (idProject, moduloId) => {
  return useQuery({
    queryKey: ['single-modulo', moduloId],
    queryFn: () => getModuloC(idProject, moduloId),
  })
}

/* -------------------------------------------------------------------------- */
/*                               Célula Queries                               */
/* -------------------------------------------------------------------------- */
export const useCelulasImportByModulo = (
  idProject,
  moduloId,
  currentVersion
) => {
  return useQuery({
    queryKey: ['celulas-import', idProject, moduloId, currentVersion],
    queryFn: () =>
      getImportCelulasByModuloId(idProject, moduloId, currentVersion),
    enabled: !!currentVersion,
  })
}

/* -------------------------------------------------------------------------- */
/*                            Mano de Obra Queries                            */
/* -------------------------------------------------------------------------- */
export const useGetManoDeObra = () => {
  return useQuery({
    queryKey: ['manodeobra'],
    queryFn: getAllManoDeObra,
  })
}

export const useGetVersionImportsByProject = idProject => {
  return useQuery({
    queryKey: ['version-imports', idProject],
    queryFn: () => getVersionsImportByProject(idProject),
  })
}

/* -------------------------------------------------------------------------- */
/*                                 Boq Queries                                */
/* -------------------------------------------------------------------------- */

export const useGetAllExternalIdByProjectAndVersion = idProject => {
  const version = getCurrentModelVersion()
  return useQuery({
    queryKey: ['externalids-medidos'],
    queryFn: () => getAllExternalIdsByProjectAndVersion(idProject, version),
  })
}

/* -------------------------------------------------------------------------- */
/*                               Bimlink Queries                              */
/* -------------------------------------------------------------------------- */
export const useGetAllBimlinks = () => {
  return useQuery({
    queryKey: ['bimlinks'],
    queryFn: getBimlinks,
  })
}

export const useCreateBimlink = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createBimlink,
    onSuccess: () => {
      toast.success('Bimlink creado correctamente')
      navigate(changeLocation(location.pathname.replace('/new-bimlink', '')))
      queryClient.invalidateQueries(['bimlinks'])
    },
    onError: () => {
      toast.error('Error al crear el Bimlink')
    },
  })
}

export const useUpdateBimlink = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: bimlink => updateBimlink(bimlink),
    onSuccess: result => {
      toast.success('Bimlink actualizado correctamente')
      const { data } = result
      console.log('data', data)
      navigate(changeLocation(location.pathname.split('/edit-bimlink')[0]))
      queryClient.invalidateQueries(['bimlinks'])
      queryClient.invalidateQueries(['bimlink', data?._id])
    },
    onError: () => {
      toast.error('Error al crear el Bimlink')
    },
  })
}

export const useDeleteBimlink = id => {
  return useMutation({
    mutationFn: id => deleteBimlink(id),
    onSuccess: () => {
      toast.success('Bimlink eliminado correctamente')
    },
    onError: () => {
      toast.error('Error al eliminar el Bimlink')
    },
  })
}

/* -------------------------------------------------------------------------- */
/*                              Articulos Queries                             */
/* -------------------------------------------------------------------------- */
export const useGetAllArticulos = query => {
  return useQuery({
    queryKey: ['articulos', query?.page || 1, query?.filter || ''],
    queryFn: () => getAllArticulos(query),
  })
}

export const useGetAllArticulosByProject = (projectId, options) => {
  console.log('options', options)
  return useInfiniteQuery({
    queryKey: ['articulos', projectId, options.filter, options.version || 0],
    queryFn: ({ pageParam }) =>
      getAllArticulosByProject(projectId, { ...options, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.data.nextPage
    },
  })
}

export const useUpdatePriceArticuloProyecto = (
  project = null,
  articulo = null,
  toast = false
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, body }) => updatePriceArticuloProyecto(id, body),
    onSuccess: () => {
      toast && toast.success('Precio modificado correctamente')
      queryClient.invalidateQueries({
        queryKey: ['celulas-import'],
      })
      queryClient.invalidateQueries({
        queryKey: ['articulos'],
      })
      if (project && articulo) {
        queryClient.invalidateQueries({
          queryKey: ['articulo', project, articulo],
        })
      }
    },
    onError: () => {
      toast.error('Error al modificar el precio')
    },
  })
}

export const useUpdateArticulo = id => {
  const queryClient = useQueryClient()

  const { idProject } = useParams()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: ({ id, body }) => updateArticulo(id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['articulo', idProject, id],
      })
      queryClient.invalidateQueries({
        queryKey: ['articulos', idProject],
      })
      toast.success('Artículo modificado correctamente')
      navigate(`/viewer/${idProject}/articulos`)
    },
    onError: () => {
      toast.error('Error al modificar el artículo')
    },
  })
}

export const useGetArticuloProyecto = (idArticulo, idProject) => {
  return useQuery({
    queryKey: ['articulo', idProject, idArticulo],
    queryFn: () => getArticuloProyecto(idArticulo, idProject),
  })
}

/* -------------------------------------------------------------------------- */
/*                             Activities Queries                             */
/* -------------------------------------------------------------------------- */
export const useGetAllActivities = (query) => {
  return useQuery({
    queryKey: ['activities', query?.page || 1, query?.filter || ''],
    queryFn:() => getAllActivities(query),
  })
}

/* -------------------------------------------------------------------------- */
/*                          Model properties Queries                          */
/* -------------------------------------------------------------------------- */

export const useGetAllFields = projectId => {
  return useQuery({
    queryKey: ['all-fields', projectId],
    queryFn: () => getAllFields(projectId),
  })
}

export const useGetDisplayValuesFromDisplayName = (projectId, displayName) => {
  const queryClient = useQueryClient()
  const { data } = queryClient.getQueryData(['all-fields', projectId])
  return useQuery({
    queryKey: ['display-values', projectId, data.indexId, displayName?.name],
    queryFn: () =>
      getDisplayValues({
        projectId: data.projectId,
        indexId: data.indexId,
        displayName,
      }),
    enabled: !!displayName,
  })
}
