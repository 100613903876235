import { createContext, useContext, useState } from 'react'
import { useArticulosTablePage } from './ArticulosTablePageContext'

export const TiposContext = createContext()

export const TiposProvider = ({ children }) => {
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState(false)
  const [dbIds, setDbIds] = useState(null)
  const { searchedValue, resetInfo } = useArticulosTablePage()

  const resetAllFilters = () => {
    setPage(1)
    setFilter('')
    resetInfo()
  }

  return (
    <TiposContext.Provider
      value={{
        page,
        setPage,
        filter,
        setFilter,
        dbIds,
        setDbIds,
        searchedValue,
        resetAllFilters,
      }}
    >
      {children}
    </TiposContext.Provider>
  )
}

export const useTiposTable = () => {
  const context = useContext(TiposContext)
  if (!context) {
    throw new Error('useTipos must be used within a TiposProvider')
  }
  return context
}
