import { viewer } from '../../utils/launchViewer'
import ModalAddBimlinkTipo from './ModalAddBimlink/ModalAddBimlinkTipo'
import { useState } from 'react'
import { AddBimLinkToTipoProvider } from 'context/AddBimLinkToTipoContext'
import AddIcon from '@mui/icons-material/Add'
/* global THREE */

import { areSameExternalIds } from 'utils/handleTipos'
import BadgeCell from './BadgeCell'
import ButtonsCell from './ButtonsCell'
import ModalUpdateTipoBimlink from './ModalUpdateTipoBimLink/ModalUpdateTipoBimlink'
import { useUpdateTipoBimLink } from 'lib/react-query/queriesTipoBimLinkProyectos'

function TipoRow({ t }) {
  const { lmvId: dbId, name, revitCategory, tipoBimlink, items } = t

  const parentsDbIds = items.map(item => item.svf2Id) || []
  const externalIds = items.map(item => item.externalId) || []
  const areSame = !tipoBimlink
    ? true
    : areSameExternalIds(externalIds, tipoBimlink.tipoExternalId)
  const mutationUpdateTipo = useUpdateTipoBimLink()
  const { bimlink: { code } = {} } = tipoBimlink || {}
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isModalUpdateTipoOpened, setIsModalUpdateTipoOpened] = useState(false)

  const handleClick = () => {
    viewer.isolate(parentsDbIds)
    viewer.fitToView(parentsDbIds)
  }

  const openModalUpdateTipo = () => {
    setIsModalUpdateTipoOpened(true)
  }

  const updateTipo = () => {
    mutationUpdateTipo.mutate({
      id: tipoBimlink._id,
      body: {
        tipoExternalId: externalIds,
      },
    })
    setIsModalUpdateTipoOpened(false)
  }

  const handleAdd = () => {
    setIsModalOpened(true)
  }

  const buttonsArray = [
    {
      name: 'Vincular bimlink',
      Icon: <AddIcon />,
      onClick: handleAdd,
      class: 'add-bimlink-to-tipo',
    },
    // {
    //   name: 'Ocultar tipo',
    //   Icon: <VisibilityOffIcon />,
    //   onClick: handleHide,
    //   class: 'hide-tipo',
    // },
  ]

  if (tipoBimlink && !tipoBimlink.visible) return null

  return (
    <tr>
      {isModalOpened && (
        <AddBimLinkToTipoProvider
          tipo={{ ...tipoBimlink, name: name, externalIds }}
        >
          <ModalAddBimlinkTipo handleOnClose={() => setIsModalOpened(false)} />
        </AddBimLinkToTipoProvider>
      )}
      {isModalUpdateTipoOpened && (
        <ModalUpdateTipoBimlink
          handleOnClose={() => setIsModalUpdateTipoOpened(false)}
          handleOnClick={updateTipo}
        />
      )}
      <td onClick={handleClick}>{name}</td>
      <td>{revitCategory}</td>

      <td>
        {!tipoBimlink || !tipoBimlink.bimlink ? (
          <ButtonsCell buttonsArray={buttonsArray} />
        ) : (
          <BadgeCell
            code={code}
            areSame={areSame}
            onClick={openModalUpdateTipo}
          />
        )}
      </td>
    </tr>
  )
}

export default TipoRow
