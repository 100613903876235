import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import NotResultsText from '../Articulos/NotResultsText'
import { useArticulosTablePage } from 'context/ArticulosTablePageContext'
import { useGetAllActivities } from 'lib/react-query/queries'
import BoxSkeleton from '../BoxSkeleton'
import { TABLE_HEADER_ACTIVIDADES_ADMIN } from 'utils/constant/constantsArticulos'
import ActividadesRowAdmin from './ActividadesRowAdmin'
import Pagination from 'components/ui/Pagination/Pagination'

const ActividadAdminTable = () => {
  const limit = 15
  const [page, setPage] = useState(1)
  const { searchedValue } = useArticulosTablePage()
  const { data, isLoading, isFetching } = useGetAllActivities({
    page: page,
    limit,
    filter: searchedValue,
  })

  const handlePage = newPage => {
    setPage(newPage)
  }

  useEffect(() => {
    setPage(1)
  }, [searchedValue])

  if (!data || isLoading || isFetching)
    return <Skeleton wrapper={BoxSkeleton} count={12} height={40} />
  if (data.results === 0)
    return (
      <NotResultsText>
        No hay resultados que coincidan con la búsqueda.
      </NotResultsText>
    )
  return (
    <>
      <table className="table-admin">
        <thead>
          <tr>
            {TABLE_HEADER_ACTIVIDADES_ADMIN.map(({ name, className }, i) => (
              <th className={className}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody className="articulos-table-body">
          {data.data.data.map(item => (
            <ActividadesRowAdmin item={item} />
          ))}
        </tbody>
      </table>
      <div style={{ height: '1px' }}></div>
      <div className="pagination-articulos-admin-container">
        {data.total >= limit && (
          <Pagination
            count={2}
            currentPage={page}
            totalPages={Math.ceil(data.total / limit)}
            handlePage={handlePage}
          />
        )}
      </div>
    </>
  )
}

export default ActividadAdminTable
