import axios from 'axios'

const getAllManoDeObra = async () => {
  try {
    const { data } = await axios.get('/api/manodeobra')
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export { getAllManoDeObra }
