import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'

export const getLogin = createAsyncThunk('auth/getLogin', async data => {
  const { email, password } = data
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ email, password })

  try {
    const res = await axios.post('/api/v1/usuarios/login', body, config)
    const response = {
      isAuthenticated: true,
      token: res.data.token,
      name: res.data.name,
    }
    return response
  } catch (err) {
    const errors = err.response.data.errors
    console.log(errors)
    if (errors) {
      // Alerta dependiendo del mensaje de error que de el servidor
      // Con el dispatchEvent entra en .rejected
      errors.forEach(error => dispatchEvent(toast.error(error.msg)))
    }
    // const response = {isAuthenticated: false, token: null}
    // return response
  }
})

const initialState = {
  token: null,
  isAuthenticated: null,
  loading: true,
  name: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload
    },
    setToken: (state, { payload }) => {
      state.token = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setName: (state, { payload }) => {
      state.name = payload
    },
    setLogout: state => {
      state.isAuthenticated = false
      state.token = null
      state.name = null
      state.loading = false
    },
  },
  extraReducers: {
    [getLogin.pending]: state => {
      state.loading = true
    },
    [getLogin.fulfilled]: (state, { payload }) => {
      state.token = payload?.token || null
      state.isAuthenticated = payload?.isAuthenticated || false
      state.loading = false
      state.name = payload?.name || null
    },
    [getLogin.rejected]: (state, { payload }) => {
      state.token = payload?.token || null
      state.isAuthenticated = payload?.isAuthenticated || false
      state.loading = false
    },
  },
})

export const { setToken, setIsAuthenticated, setLoading, setLogout } =
  authSlice.actions

export default authSlice.reducer
