import { useTipos } from './useTipos'
import TipoRow from './TipoRow'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Pagination from 'components/ui/Pagination/Pagination'
import SearchArticulos from 'components/admin/Search/SearchArticulos'
import { useArticulosTablePage } from 'context/ArticulosTablePageContext'
import { useVersionModel } from 'hooks/useVersionModel'
import { CustomButton } from 'components/ui'
import TiposProjectLoader from 'components/Loaders/TiposProjectLoader'

import { OptionsItem, SelectInput } from 'components/ui/Forms/SelectInput'
import { getSelectionViewer, viewer } from 'utils/launchViewer'
import toast from 'react-hot-toast'
import { getBulkPropertiesAsync } from 'utils/getPropertiesViewer'
import { useTiposTable } from 'context/TiposContext'

const OPTIONS_FILTER = [
  {
    name: 'Todos',
    value: 'all',
  },
  {
    name: 'Vinculados',
    value: 'vinculados',
  },
  {
    name: 'No Vinculados',
    value: 'no-vinculados',
  },
]

function TiposTable() {
  const { currentVersion } = useVersionModel()
  const { idProject } = useParams()
  const navigate = useNavigate()

  const {
    page,
    setPage,
    filter,
    setFilter,
    dbIds,
    setDbIds,
    searchedValue,
    resetAllFilters,
  } = useTiposTable()
  const { isLoading, tipos } = useTipos(idProject, {
    page,
    version: currentVersion,
    search: searchedValue,
    filter,
    visible: '',
    dbIds: dbIds,
  })

  const handlePage = value => {
    setPage(value)
  }

  const handleFilter = e => setFilter(e.target.value)

  useEffect(() => {
    if (searchedValue !== '' || filter !== '') {
      setDbIds(null)
    }
    setPage(1)
  }, [currentVersion, filter, searchedValue])

  const filterType = async () => {
    // if (dbIds) return setDbIds(null)
    const selection = getSelectionViewer()
    if (selection.length === 0) {
      setDbIds(null)
      return toast.error('Tienes que seleccionar un elemento en el modelo')
    }

    const dbIdSelected = selection[0]
    console.log('selection', dbIdSelected)
    const parentDbId = await getBulkPropertiesAsync(
      viewer,
      [dbIdSelected],
      ['parent']
    )

    if (parentDbId[0].properties.length === 0)
      return toast.error('No se ha podido sacar el tipo de este elemento')
    const parentDbIdValue = parentDbId[0].properties[0].displayValue
    console.log('parentDbId', parentDbIdValue)
    resetAllFilters()
    setDbIds(parentDbIdValue)
  }

  if (isLoading) return <TiposProjectLoader />

  return (
    <>
      <div
        style={{
          marginBottom: '2rem',
          display: 'flex',
          gap: '2rem',
          justifyContent: 'flex-end',
        }}
      >
        <SearchArticulos placeholder="Buscar por nombre de tipo." />
        <SelectInput
          name="filter-vinculados"
          value={filter}
          onChange={handleFilter}
        >
          {OPTIONS_FILTER.map(item => (
            <OptionsItem name="filter-vinculado-option" value={item.value}>
              {item.name}
            </OptionsItem>
          ))}
        </SelectInput>
        {/* </div> */}
        <div className="button-create-matriculas-container">
          <CustomButton
            className="button-create-matriculas"
            onClick={filterType}
          >
            Filtrar tipo
          </CustomButton>
        </div>
        <div className="button-create-matriculas-container">
          <CustomButton
            className="button-create-matriculas"
            onClick={() => navigate('config-tipos')}
          >
            Gestionar tipos
          </CustomButton>
        </div>
      </div>

      <div className="tipos-container">
        {tipos.data.length === 0 && (
          <p className="not-results-text">
            No hay tipos que coincidan con la búsqueda
          </p>
        )}
        {tipos.data.length > 0 && (
          <table>
            <thead>
              <tr>
                <th className="ta-left">Nombre</th>
                <th className="ta-left">Categoría</th>
                <th className="ta-left">Linked</th>
              </tr>
            </thead>
            <tbody>
              {tipos.data.map(tipo => (
                <TipoRow t={tipo} key={tipo.name} />
              ))}
            </tbody>
          </table>
        )}

        {tipos.totalPages > 1 && (
          <Pagination
            count={2}
            currentPage={page}
            handlePage={handlePage}
            totalPages={tipos.totalPages}
          />
        )}
      </div>
    </>
  )
}

export default TiposTable
