import { CustomButton } from 'components/ui'
import TextInput from 'components/ui/Forms/TextInput'
import AddIcon from '@mui/icons-material/Add'
import WorkspacesIcon from '@mui/icons-material/Workspaces'

import { useBimLink } from 'context/BimLinkContext'
import GroupItems, {
  GroupItemsBody,
  GroupItemsTitle,
  GroupSingleItem,
} from './GroupItems/GroupItems'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from 'hooks/useHandleUrl'
import toast from 'react-hot-toast'
import { Tooltip } from 'react-tooltip'
import { ButtonsGroupItemBimLink } from './ButtonsGroupItemBimLink'
const NewBimLink = () => {
  const {
    isEditMode,
    nameBimLink,
    onChangeNameBimLink,
    groups,
    createBimlink,
    deleteGroup,
  } = useBimLink()
  const { changeLocation } = useHandleUrl()
  const location = useLocation()
  const navigate = useNavigate()

  const createNewGroup = () => {
    if (!nameBimLink)
      return toast.error('El campo del código tiene que estar relleno')
    navigate(changeLocation(`${location.pathname}/group`))
  }

  const handleBack = () => {
    const isEdit = location.pathname.includes('edit-bimlink')
    navigate(
      changeLocation(
        location.pathname.split(isEdit ? '/edit-bimlink' : '/new-bimlink')[0]
      )
    )
  }

  const navigateToEditGroup = group => {
    navigate(changeLocation(`${location.pathname}/edit-group/${group.uuid}`))
  }

  return (
    <div className="new-bimlink-body-container">
      <h1 className="new-bimlink-title">
        {isEditMode ? 'Editar Bimlink' : 'Crear Bimlink'}
      </h1>
      <div className="new-bimlink-form">
        <TextInput
          label="Código BimLink"
          placeholder="bl004"
          value={nameBimLink}
          onChange={onChangeNameBimLink}
        />
        <GroupItems>
          <GroupItemsTitle>Grupos</GroupItemsTitle>
          <CustomButton
            type="light"
            className="custom-input-container group-bimlinks-container-button"
            onClick={createNewGroup}
          >
            <AddIcon />
            <p style={{ fontSize: '14px' }}>Crear nuevo grupo</p>
          </CustomButton>
          {groups.length == 0 ? (
            <p
              style={{
                textAlign: 'center',
                margin: '1rem 0',
                fontStyle: 'italic',
              }}
            >
              No hay ningún grupo creado todavía
            </p>
          ) : (
            <GroupItemsBody>
              {groups.map((group, index) => (
                <GroupSingleItem
                  key={index}
                  name={group.name}
                  Icon={WorkspacesIcon}
                  ButtonsComponent={ButtonsGroupItemBimLink}
                  buttonsProps={{
                    handleEdit: () => navigateToEditGroup(group),
                    handleDelete: () => deleteGroup(group),
                  }}
                ></GroupSingleItem>
              ))}
            </GroupItemsBody>
          )}
        </GroupItems>
      </div>
      {/* </div> */}
      <div className="new-bimlink-footer-buttons">
        <CustomButton type="secondary" onClick={handleBack}>
          Cancelar
        </CustomButton>
        <CustomButton
          className={'create-bimlink-button'}
          onClick={createBimlink}
          disabled={groups.length == 0 || !nameBimLink}
        >
          {isEditMode ? 'Editar Bimlink' : 'Crear Bimlink'}
        </CustomButton>
        <Tooltip
          anchorSelect=".create-bimlink-button"
          style={{
            fontSize: '9px',
            display: groups.length == 0 || !nameBimLink ? 'block' : 'none',
          }}
        >
          El campo código y grupo no pueden estar vacíos
        </Tooltip>
      </div>
    </div>
  )
}

export default NewBimLink
