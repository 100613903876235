import { createContext, useState } from 'react'

// * TENER CUIDADO CON ESTE VALOR. AL HACER LA PRIMERA QUERY FILTRA POR ESTE VALOR ASÍ QUE TIENE QUE SER SUPERIOR AL PRECIO DE LAS PARTIDAS
export const NavPresupuestoContext = createContext()

export const NavPresupuestoProvider = ({ children }) => {
  const [modulesFilter, setModulesFilter] = useState([])

  const resetModulesFilter = () => {
    setModulesFilter([])
  }

  const data = {
    modulesFilter,
    resetModulesFilter,
  }

  return (
    <NavPresupuestoContext.Provider value={data}>
      {children}
    </NavPresupuestoContext.Provider>
  )
}
