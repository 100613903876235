import { useAuth0 } from '@auth0/auth0-react'
import GoBackProyectos from 'components/Proyectos/EditProyecto.jsx/GoBackProyectos'
import Tab from 'components/ui/Tab/Tab'
import PageTitle from 'components/ui/Title/PageTitle'
import { useGetSingleProjectByUserAdmin } from 'lib/react-query/queriesProject'
import { useMemo } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

const SingleProyectoWrapper = () => {
  const { idProject } = useParams()
  const navigate = useNavigate()
  const { user } = useAuth0()
  const { error, isLoading } = useGetSingleProjectByUserAdmin(
    user.sub,
    idProject
  )

  if (isLoading) return
  if (error) return navigate('/error')

  return <SingleProyectoPage />
}

const SingleProyectoPage = () => {
  const { idProject } = useParams()

  const tabsItems = useMemo(() => {
    return [
      {
        name: 'Configuración',
        to: `/admin/proyectos/${idProject}/config`,
      },
      {
        name: 'Usuarios',
        to: `/admin/proyectos/${idProject}/users`,
      },
    ]
  }, [idProject])
  return (
    <div>
      <GoBackProyectos />
      <PageTitle>Editar Proyecto</PageTitle>
      <div className="container-tabs">
        <Tab items={tabsItems} />
      </div>
      <Outlet />
    </div>
  )
}

export default SingleProyectoWrapper
