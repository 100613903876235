import React, {  useEffect, useState } from 'react'
import {
  useSearchParams,
} from 'react-router-dom'
import Sidebar from '../components/sidebar/Sidebar'
import Viewer from '../components/Presupuestos/Viewer'
import NavViewer from '../components/Presupuestos/NavViewer'
import './ViewerLayout.css'
import ViewerPanel from '../components/ViewerPanel/ViewerPanel'
import { useLaunchViewer } from 'hooks/useLaunchViewer'
import Modal from 'components/ui/Modal/Modal'
import TranslateVersionModel from 'components/Viewer/TranslateVersionModel'
import { useProject } from 'context/ProyectoContext'

const ViewerLayout = () => {
  const { loadViewer } = useLaunchViewer()
  let [searchParams] = useSearchParams()
  const { currentProject, isLoadingCurrentProject } = useProject()
  const [openModalVersionTranslate, setOpenModalVersionTranslate] =
    useState(false)

  useEffect(() => {
    loadViewer(setOpenModalVersionTranslate)
  }, [searchParams.get('version')])

  if (isLoadingCurrentProject || !currentProject) return
  return (
    <>
      {
        <div className="layout-viewer">
          {/* <ViewerLayoutProvider> */}
          <div className="viewer-aside">
            <Sidebar />
          </div>
          <NavViewer />
          <ViewerPanel />
          <Viewer />
          {openModalVersionTranslate && (
            <Modal>
              <TranslateVersionModel
                setOpenModalVersionTranslate={setOpenModalVersionTranslate}
              />
            </Modal>
          )}
        </div>
      }
    </>
  )
}

export default ViewerLayout
