import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useGetSegments = ({ asOfValue }) => {
  const location = useLocation()
  const [allSegments, setAllSegments] = useState([])

  useEffect(() => {
    const pathSegments = location.pathname.split('/')
    const startIndex = pathSegments.findIndex(segment => segment === asOfValue)
    if (startIndex !== -1) {
      const filteredSegments = pathSegments.slice(startIndex)
      setAllSegments(filteredSegments)
    } else {
      setAllSegments([])
    }
  }, [location.pathname, asOfValue])

  return allSegments
}

export default useGetSegments
