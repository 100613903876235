import React from 'react'
import { ArticulosProvider } from '../../context/ArticulosContext'
import ArticulosTableLoader from '../Loaders/ArticulosTableLoader'
import Fabrica from '../Fabrica/Fabrica'
import { useVersionModel } from 'hooks/useVersionModel'

const ArticulosWrapper = () => {
  const { currentVersion } = useVersionModel()

  if (!currentVersion) return <ArticulosTableLoader />
  return (
    <ArticulosProvider>
      <Fabrica />
    </ArticulosProvider>
  )
}

export default ArticulosWrapper
