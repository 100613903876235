/* global Autodesk */
export class ModulosPanel extends Autodesk.Viewing.UI.DockingPanel {
  constructor(extension, id, title, options) {
    super(extension.viewer.container, id, title, options)
    this.extension = extension
    this.container.style.left = (options.x || 0) + 'px'
    this.container.style.top = (options.y || 0) + 'px'
    this.container.style.width = (options.width || 500) + 'px'
    this.container.style.height = (options.height || 400) + 'px'
    this.container.style.resize = 'none'
  }

  initialize() {
    this.title = this.createTitleBar(this.titleLabel || this.container.id)
    this.initializeMoveHandlers(this.title)
    this.container.appendChild(this.title)
    this.content = document.createElement('div')
    this.content.style.height = '350px'
    this.content.style.backgroundColor = 'white'
    this.content.innerHTML = `
            <div class="props-container" style="position: relative; height: 25px; padding: 0.5em;">
                <label><input type="checkbox" id="all"> Datos de PARTIDAS de todo el Modelo.</label><br>
                <select class="props"></select>
                <p class="desc">Selecciona algún MODULO en el desplegable.</p>
                <select class="partidas" hidden></select>
                <p class="unidades" hidden></p>
                <p class="longitud" hidden></p>
                <p class="area" hidden></p>
                <p class="volumen" hidden></p>
            </div>
        `
    this.select = this.content.querySelector('select.props')
    this.desc = this.content.querySelector('p.desc')
    this.partidas = this.content.querySelector('select.partidas')
    this.unidades = this.content.querySelector('p.unidades')
    this.longitud = this.content.querySelector('p.longitud')
    this.area = this.content.querySelector('p.area')
    this.volumen = this.content.querySelector('p.volumen')
    this.container.appendChild(this.content)
  }

  async setModel(model) {
    const dbIds = await this.extension.findLeafNodes(model)
    this.all = document.getElementById('all')
    this.all.onchange = async () => {
      if (this.all.checked) {
        this.select.hidden = true
        this.partidas.hidden = false
        this.desc.hidden = true
        const partidas = await this.extension.getPartidas(model, dbIds)
        this.partidas.innerHTML = Object.keys(partidas)
          .sort()
          .map(key => `<option value="${key}">${key}</option>`)
          .join('\n')
        this.partidas.onchange = async () => {
          const partidaSelection = partidas[this.partidas.value]
          const partidaDbIds = partidaSelection.map(x => x.dbId)
          const qto = await this.extension.getQto(model, partidaDbIds)
          this.unidades.hidden = false
          this.longitud.hidden = false
          this.area.hidden = false
          this.volumen.hidden = false
          this.unidades.innerHTML = `Unidades: ${qto.unidades} u.`
          this.longitud.innerHTML = `Longitud: ${qto.longitud.toFixed(2)} m.`
          this.area.innerHTML = `Área: ${qto.area.toFixed(2)} m2.`
          this.volumen.innerHTML = `Volumen: ${qto.volumen.toFixed(2)} m3.`
          this.extension.viewer.isolate(partidaDbIds)
        }
      } else {
        this.select.hidden = false
        this.partidas.hidden = true
        this.desc.hidden = false
      }
    }
    const moduloNames = await this.extension.getModulos(model)
    this.select.innerHTML = Object.keys(moduloNames)
      .sort()
      .map(key => `<option value="${key}">${key}</option>`)
      .join('\n')

    this.select.onchange = async () => {
      const externalIds = await this.extension.getBulkPropertiesAsync(
        model,
        dbIds,
        ['externalId']
      )

      console.log('externalIds onChange', externalIds)
      const selection = moduloNames[this.select.value]
      const arrayModulos = selection.map(x => x.instanceId)
      let resDbIds = externalIds
        .filter(eId => arrayModulos.some(m => eId.externalId.startsWith(m)))
        .map(x => x.dbId)
      console.log('resDbIds', resDbIds)
      const instancias = selection.length
      this.desc.innerHTML = `${instancias === 1 ? 'Existe' : 'Existen'} ${
        selection.length
      } ${instancias === 1 ? 'instancia' : 'instancias'} del MODULO ${
        this.select.value
      }.`
      this.extension.viewer.isolate(resDbIds)

      // Gestionamos el select de Partidas.
      this.partidas.hidden = false
      const partidas = await this.extension.getPartidas(model, resDbIds)
      this.partidas.innerHTML = Object.keys(partidas)
        .sort()
        .map(key => `<option value="${key}">${key}</option>`)
        .join('\n')
      this.partidas.onchange = async () => {
        const partidaSelection = partidas[this.partidas.value]
        const partidaDbIds = partidaSelection.map(x => x.dbId)
        const qto = await this.extension.getQto(model, partidaDbIds)
        this.unidades.hidden = false
        this.longitud.hidden = false
        this.area.hidden = false
        this.volumen.hidden = false
        this.unidades.innerHTML = `Unidades: ${qto.unidades} u.`
        this.longitud.innerHTML = `Longitud: ${qto.longitud.toFixed(2)} m.`
        this.area.innerHTML = `Área: ${qto.area.toFixed(2)} m2.`
        this.volumen.innerHTML = `Volumen: ${qto.volumen.toFixed(2)} m3.`
        this.extension.viewer.isolate(partidaDbIds)
      }
    }
  }
}
