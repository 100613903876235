import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

const useAxiosInterceptor = () => {
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const requestInterceptor = async config => {
      const token = await getAccessTokenSilently()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    }

    const errorInterceptor = error => {
      return Promise.reject(error)
    }

    const responseInterceptor = response => {
      return response
    }

    const requestInterceptorId = axios.interceptors.request.use(
      requestInterceptor,
      errorInterceptor
    )
    const responseInterceptorId = axios.interceptors.response.use(
      responseInterceptor,
      errorInterceptor
    )

    return () => {
      axios.interceptors.request.eject(requestInterceptorId)
      axios.interceptors.response.eject(responseInterceptorId)
    }
  }, [getAccessTokenSilently])
}

export default useAxiosInterceptor
