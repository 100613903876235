import React from 'react'
import cls from 'classnames'
import { Tooltip } from 'react-tooltip'

export const CustomButtonWithTooltip = ({
  classname,
  onClick,
  Icon,
  title,
  ...props
}) => {
  return (
    <>
      <CustomButton
        type="secondary"
        style={{ padding: '.5rem' }}
        className={classname}
        onClick={onClick}
        {...props}
      >
        {Icon}
      </CustomButton>
      <Tooltip
        anchorSelect={`.${classname}`}
        style={{
          fontSize: '9px',
        }}
      >
        {title}
      </Tooltip>
    </>
  )
}

export const CustomButton = ({
  className,
  type = 'primary',
  disabled = false,
  children,
  ...props
}) => {
  const typeClass = () => {
    switch (type) {
      case 'primary':
        return ''
      case 'secondary':
        return 'btn-secondary'
      case 'light':
        return 'btn-light'
      case 'green':
        return 'btn-green'
      case 'default':
        return
    }
  }

  return (
    <button
      className={cls('btn', className, typeClass())}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}
