import axios from 'axios'
import { getCurrentModelVersion } from '../../utils/launchViewer'

const createBoq = async boq => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/boq', boq)
      .then(() => {
        console.log('Single boq created successfully')
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

const deleteAllBoqByProjectAndVersion = async (proyectoId, versionId) => {
  try {
    await axios.delete(`/api/boq/all/${proyectoId}/${versionId}`)
  } catch (e) {
    throw new Error(e)
  }
}

const getImportByProject = async project => {
  try {
    const currentVersion = getCurrentModelVersion()

    const { data } = await axios.get(
      `/api/boq/import/${project}/${currentVersion}`
    )
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const createMultipleBoq = async boqs => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/boq/multiple', { boqs })
      .then(() => {
        console.log('Multiple boqs created successfully')
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

const getAllExternalIdsByProjectAndVersion = async (proyectoId, version) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/boq/externalIds/${proyectoId}/${version}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export {
  createBoq,
  deleteAllBoqByProjectAndVersion,
  getImportByProject,
  createMultipleBoq,
  getAllExternalIdsByProjectAndVersion,
}
