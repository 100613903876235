import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createActividad,
  getActivity,
  updateActivity,
} from 'api/estructuraCostes/activitiesJitApi'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

export const useCreateActividad = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: body => createActividad(body),
    onSuccess: () => {
      toast.success('Activida creado correctamente')
      navigate('/admin/actividades')
      queryClient.invalidateQueries(['activities'])
    },
    onError: () => {
      toast.error('Error al crear el artículo')
    },
  })
}

export const useGetActividad = id => {
  const navigate = useNavigate()
  return useQuery({
    queryKey: ['activity', id],
    queryFn: () => getActivity(id),
    onError: () => {
      toast.error('Error al obtener la actividad')
      navigate('/admin/actividades')
    },
  })
}

export const useUpdateActividad = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, ...rest }) => updateActivity(id, rest),
    onSuccess: () => {
      toast.success('Actividad actualizada correctamente')
      navigate('/admin/actividades')
      queryClient.invalidateQueries(['activities'])
    },
    onError: () => {
      toast.error('Error al actualizar la actividad')
    },
  })
}
