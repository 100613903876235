import React, { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useHeaderNavigation } from '../../hooks/useHeaderNavigation'
import TitlePageLoader from 'components/Loaders/TitlePageLoader'

const ViewerPanelPageTitle = () => {
  const { getParamName, moduloName, celulaName } = useHeaderNavigation()
  const { idProject } = useParams()

  const location = useLocation()

  const title = useMemo(() => {
    const segments = location.pathname.split('/')
    const index = segments.findIndex(segment => segment === idProject)
    if (index !== -1) {
      return segments[segments.length - 1]
    }
    return ''
  }, [location.pathname, moduloName, celulaName])
  return (
    <h1 className="viewer-page-title">
      {getParamName(title) || <TitlePageLoader />}
    </h1>
  )
}

export default ViewerPanelPageTitle
