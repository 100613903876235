import { InputAdornment, TextField } from '@mui/material'
import TextInput from 'components/ui/Forms/TextInput'
import React from 'react'

const CosteManoDeObra = ({ costeMo, setCosteMo }) => {
  return (
    <div>
      {/* <p>Coste mano de obra</p> */}
      <TextInput
        type="number"
        label="Coste mano de obra"
        value={costeMo}
        onChange={e => setCosteMo(e.target.value)}
        // label="Coste mano de obra"
        id="outlined-start-adornment"
        containerStyles={{ width: '300px' }}
        adornment={<span>€ mes/persona</span>}
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">€ mes/persona</InputAdornment>
        //   ),
        // }}
      />
    </div>
  )
}

export default CosteManoDeObra
