import UserItemImage from '../UserItemProyecto/UserItemImage'
import UserItemEmail from '../UserItemProyecto/UserItemEmail'

const SelectUserItem = ({ user, addUser }) => {
  return (
    <div className="user-item-dropdown" onClick={() => addUser(user)}>
      <UserItemImage user={user} />
      <UserItemEmail user={user} />
    </div>
  )
}

export default SelectUserItem
