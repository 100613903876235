import { CustomButton } from 'components/ui'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatToDecimal } from 'utils/formatUnits'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Dropdown, { DropDownItem } from 'components/ui/Dropdown/Dropdown'
import ModalDeleteArticuloAdmin from './ModalDeleteArticuloAdmin'

const ArticuloRowAdmin = ({ articulo }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setIsOpenModal] = useState(false)

  return (
    <>
      <tr className="fabrica-table-header fabrica-table-header--not-pointer">
        <td>{articulo?.code}</td>
        <td>{articulo?.name}</td>
        <td className="ta-right">
          {formatToDecimal(articulo?.price || 0, { max: 4, min: 0 })}€
        </td>
        <td className="ta-right">{articulo?.unit}</td>
        <td className="container-buttons-row-articulo">
          <Dropdown
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            button={
              <CustomButton
                type="secondary"
                className="button-edit-articulo-price button-not-hover-bg"
                onClick={() => setIsOpen(prevState => !prevState)}
              >
                <MoreVertIcon />
              </CustomButton>
            }
          >
            <DropDownItem
              item="Editar artículo"
              onClick={() =>
                navigate(`/admin/articulos/edit-articulo/${articulo._id}`)
              }
            ></DropDownItem>
            <DropDownItem
              item="Eliminar artículo"
              onClick={() => setIsOpenModal(true)}
            ></DropDownItem>
          </Dropdown>
        </td>
      </tr>
      {openModal && (
        <ModalDeleteArticuloAdmin
          handleOnClose={() => setIsOpenModal(false)}
          articulo={articulo}
        />
      )}
    </>
  )
}

export default ArticuloRowAdmin
