import { useQueryClient } from '@tanstack/react-query'
import { CustomButton } from 'components/ui'
import TextInput from 'components/ui/Forms/TextInput'
import { useFormik } from 'formik'
import {
  useUpdateArticulo,
  useUpdatePriceArticuloProyecto,
} from 'lib/react-query/queries'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

const EditArticuloForm = () => {
  const { idarticulo, idProject } = useParams()
  const { mutateAsync: updateArticulo } = useUpdateArticulo(idarticulo)
  const { mutateAsync: updatePrecioArticuloProyecto } =
    useUpdatePriceArticuloProyecto(idProject, idarticulo, true)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data } = queryClient.getQueryData(['articulo', idProject, idarticulo])
  const { handleChange, values, handleSubmit, errors } = useFormik({
    initialValues: {
      code: data.code || '',
      name: data.name || '',
      precio: data.precioArticuloProyecto.precio,
      unit: data.unit || '',
    },
    onSubmit: values => {
      const { precio, ...rest } = values
      // * Comprobar si el precio es diferente al inicial. Si es diferente, actualizar el precioArticuloProyecto.
      if (precio !== data.precioArticuloProyecto.precio) {
        updatePrecioArticuloProyecto({
          id: data.precioArticuloProyecto._id,
          body: { precio },
        })
      }
      console.log('rest', { ...rest })
      updateArticulo({ id: idarticulo, body: { ...rest } })
    },
    validationSchema: Yup.object({
      code: Yup.string().required('El código es requerido'),
      name: Yup.string().required('El nombre es requerido'),
      precio: Yup.number()
        .min(0, 'El precio debe ser igual o mayor que 0')
        .required('El precio es requerido'),
      unit: Yup.string(),
    }),
  })
  const { code, name, unit, precio } = values

  return (
    <form className="edit-articulo-form" noValidate onSubmit={handleSubmit}>
      <TextInput
        label="Código"
        name="code"
        value={code}
        onChange={handleChange}
      />
      {errors.code && <p className="error-form">{errors.code}</p>}

      <TextInput
        label="Nombre"
        name="name"
        value={name}
        onChange={handleChange}
      />

      {errors.name && <p className="error-form">{errors.name}</p>}
      <TextInput
        label="Precio"
        name="precio"
        value={precio}
        type="number"
        onChange={handleChange}
      />
      {errors.precio && <p className="error-form">{errors.precio}</p>}

      <TextInput
        label="Unidad"
        name="unit"
        value={unit}
        onChange={handleChange}
      />
      {errors.unit && <p className="error-form">{errors.unit}</p>}
      <div className="buttons-container-edit-form ">
        <CustomButton
          type="secondary"
          onClick={() => navigate(`/viewer/${idProject}/articulos`)}
        >
          Cancelar
        </CustomButton>
        <CustomButton type="submit" disabled={Object.keys(errors).length != 0}>
          Actualizar Artículo
        </CustomButton>
      </div>
    </form>
  )
}

export default EditArticuloForm
