import TiposProjectLoader from 'components/Loaders/TiposProjectLoader'
import SearchArticulos from 'components/admin/Search/SearchArticulos'
import { CustomButton } from 'components/ui'
import Pagination from 'components/ui/Pagination/Pagination'
import { useArticulosTablePage } from 'context/ArticulosTablePageContext'
import { useTipos } from 'features/tipos/useTipos'
import { useVersionModel } from 'hooks/useVersionModel'

import { useUpdateAllTiposBimLink } from 'lib/react-query/queriesTipoBimLinkProyectos'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TipoConfigItem from './TipoConfigItem'
import { OptionsItem, SelectInput } from 'components/ui/Forms/SelectInput'

const OPTIONS_FILTER = [
  {
    name: 'Todos',
    value: 'all',
  },
  {
    name: 'Vinculados',
    value: 'vinculados',
  },
  {
    name: 'No Vinculados',
    value: 'no-vinculados',
  },
]

const OPTIONS_VISIBLE = [
  {
    name: 'Todos',
    value: '',
  },
  {
    name: 'Visibles',
    value: 'true',
  },
  {
    name: 'No visibles',
    value: 'false',
  },
]

const ConfigTipos = () => {
  const { idProject } = useParams()
  const { mutateAsync: updateAllTiposBimlink } = useUpdateAllTiposBimLink()
  const { currentVersion } = useVersionModel()
  const { searchedValue } = useArticulosTablePage()
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState(false)
  const [visible, setVisible] = useState('')

  const { isLoading, tipos } = useTipos(idProject, {
    page,
    version: currentVersion,
    search: searchedValue,
    filter,
    visible,
  })

  const handleFilter = e => setFilter(e.target.value)
  const handleVisible = e => setVisible(e.target.value)

  const handlePage = value => {
    setPage(value)
  }

  useEffect(() => {
    setPage(1)
  }, [currentVersion, searchedValue])

  if (isLoading) return <TiposProjectLoader />

  return (
    <>
      <div
        style={{
          marginBottom: '2rem',
          display: 'flex',
          gap: '2rem',
          justifyContent: 'flex-end',
        }}
      >
        <SearchArticulos placeholder="Buscar por nombre de tipo." />
        <SelectInput
          name="filter-visible"
          value={visible}
          onChange={handleVisible}
        >
          {OPTIONS_VISIBLE.map(item => (
            <OptionsItem name="filter-visible-option" value={item.value}>
              {item.name}
            </OptionsItem>
          ))}
        </SelectInput>
        <SelectInput
          name="filter-vinculados"
          value={filter}
          onChange={handleFilter}
        >
          {OPTIONS_FILTER.map(item => (
            <OptionsItem name="filter-vinculado-option" value={item.value}>
              {item.name}
            </OptionsItem>
          ))}
        </SelectInput>
        {/* </div> */}
        <div className="button-create-matriculas-container">
          <CustomButton
            className="button-create-matriculas"
            onClick={updateAllTiposBimlink}
          >
            Actualizar tipos
          </CustomButton>
        </div>
      </div>
      <div className="tipos-container">
        {tipos.data.length === 0 && (
          <p className="not-results-text">
            No hay tipos que coincidan con la búsqueda
          </p>
        )}
        {tipos.data.length !== 0 && (
          <table>
            <thead>
              <tr>
                <th className="ta-left">Nombre</th>
                <th className="ta-left">Categoría</th>
                <th className="ta-left">Linked</th>
                <th className="ta-left"></th>
              </tr>
            </thead>
            <tbody>
              {tipos.data.map(tipo => (
                <TipoConfigItem t={tipo} key={tipo.name} />
              ))}
            </tbody>
          </table>
        )}

        {tipos.totalPages > 1 && (
          <Pagination
            count={2}
            currentPage={page}
            handlePage={handlePage}
            totalPages={tipos.totalPages}
          />
        )}
      </div>
    </>
  )
}

export default ConfigTipos
