import { useQueryClient } from '@tanstack/react-query'
import { compararPorPropiedad } from 'components/Celulas/NewCelula/ActivitiesCheckbox'
import React from 'react'
import TableRowBimLink from './TableRowBimLink'

const TableAllBimLinks = () => {
  const queryClient = useQueryClient()
  const { data: bimlinks } = queryClient.getQueryData(['bimlinks'])
  return (
    <div
      className="fabrica-matriculas-table-container"
      style={{ flex: 1, overflowY: 'auto' }}
    >
      <table>
        <thead>
          <tr>
            <th className="ta-left">Bimlinks</th>
            <th className="ta-right"></th>
          </tr>
        </thead>
        <tbody>
          {bimlinks.sort(compararPorPropiedad('code')).map(bimlink => (
            <TableRowBimLink bimlink={bimlink} key={bimlink._id} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableAllBimLinks
