import React, { createContext, useState, useEffect } from 'react'

export const PaginationProjectsContext = createContext()

export const PaginationProjectsProvider = ({ children }) => {
  const [numPages, setNumPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const data = {
    numPages,
    setNumPages,
    currentPage,
    setCurrentPage,
  }

  return (
    <PaginationProjectsContext.Provider value={data}>
      {children}
    </PaginationProjectsContext.Provider>
  )
}
