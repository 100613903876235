import { Chip } from '@mui/material'
import React from 'react'

const UsersToProject = ({ users, allUsers }) => {
  return (
    <div className="d-flex d-column">
      <p className="gray-text mb-2">Usuarios invitados</p>
      <div className="d-flex d-wrap g-04">
        {users?.map(user =>
          allUsers.map(u => {
            if (u.id == user) {
              return <Chip className="chip-border" label={u.email} />
            }
          })
        )}
      </div>
    </div>
  )
}

export default UsersToProject
