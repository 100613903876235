import React from 'react'
import GroupItems, {
  GroupItemsBody,
  GroupItemsTitle,
  GroupSingleItem,
} from '../GroupItems/GroupItems'
import { CustomButton } from 'components/ui'
import AddIcon from '@mui/icons-material/Add'
import { useGroupBimLink } from 'context/GroupBimLinkContext'
import CategoryIcon from '@mui/icons-material/Category'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from 'hooks/useHandleUrl'
import toast from 'react-hot-toast'
import { ButtonsGroupItemBimLink } from '../ButtonsGroupItemBimLink'

const ArticulosGroupBimLink = () => {
  const { articulos, code, name, deleteArticulo } = useGroupBimLink()
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()

  const createNewArticulo = () => {
    if (!code || !name)
      return toast.error('Debes rellenar el código y el nombre')
    navigate(changeLocation(`${location.pathname}/new-articulo`))
  }

  const editArticulo = uuid => {
    navigate(changeLocation(`${location.pathname}/edit-articulo/${uuid}`))
  }
  return (
    <GroupItems className="overflow-unset">
      <GroupItemsTitle>Artículos</GroupItemsTitle>
      <CustomButton
        type="light"
        className="custom-input-container group-bimlinks-container-button"
        onClick={createNewArticulo}
      >
        <AddIcon />
        <p style={{ fontSize: '14px', marginLeft: '1rem' }}>
          Crear nuevo artículo
        </p>
      </CustomButton>
      {articulos.length > 0 ? (
        <GroupItemsBody>
          {articulos.map((group, index) => (
            <GroupSingleItem
              name={`Artículo ${index + 1}`}
              Icon={CategoryIcon}
              ButtonsComponent={ButtonsGroupItemBimLink}
              buttonsProps={{
                handleEdit: () => editArticulo(group.uuid),
                handleDelete: () => deleteArticulo(group.uuid),
              }}
            >
              {/* <ButtonsGroupItem /> */}
              <div
                style={{
                  marginLeft: '4rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '.5rem',
                  marginTop: '-.7rem',
                  marginBottom: '1rem',
                }}
              >
                <p>
                  Actividad:{' '}
                  {`${group.actividad.code} - ${group.actividad.name}`}
                </p>
                <p>
                  Artículo: {`${group.articulo.code} - ${group.articulo.name}`}
                </p>
                <p>Fórmula: {`${group.formula}`}</p>
              </div>
            </GroupSingleItem>
          ))}
        </GroupItemsBody>
      ) : (
        <p className="not-articles-created-text ">
          No hay ningún artículo creado todavía
        </p>
      )}
    </GroupItems>
  )
}

export default ArticulosGroupBimLink
