import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Auth0ProviderWithConfig } from './auth/auth0-provider-with-config'
import './styles/index.css'

// replace console.* for disable log on production
if (process.env.REACT_APP_ENV !== 'development') {
  console.log = () => {}
  console.debug = () => {}
  console.info = () => {}
  // console.warn = () => {}
  // console.error = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <Auth0ProviderWithConfig>
    <App />
  </Auth0ProviderWithConfig>
  // </React.StrictMode>
)
