import React from 'react'
import { useNavigate } from 'react-router-dom'

const GoBackProyectos = () => {
  const navigate = useNavigate()

  const handleOnClick = () => navigate('/admin/proyectos')
  return (
    <div className="go-back-button-container" onClick={handleOnClick}>
      <ion-icon
        class="icon go-back-button-icon"
        name="arrow-back-outline"
        style={{ width: '20px' }}
      ></ion-icon>
      <p>Volver a proyectos</p>
    </div>
  )
}

export default GoBackProyectos
