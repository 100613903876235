import { CustomButton } from 'components/ui'
import { OptionsItem, SelectInput } from 'components/ui/Forms/SelectInput'
import TextInput from 'components/ui/Forms/TextInput'
import { useFormik } from 'formik'
import DatesInputsCalendar from './DatesInputsCalendar'
import { infoProjectValidation } from 'lib/validation/yupValidation'
import { useUpdateSingleProjectByUserAdmin } from 'lib/react-query/queriesProject'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams } from 'react-router-dom'

const statusS_PROJECT = ['Planificación', 'Oferta', 'Abierto', 'Completado']

const FormEditProyecto = ({ proyecto }) => {
  const { user } = useAuth0()
  const { idProject } = useParams()
  const { mutateAsync } = useUpdateSingleProjectByUserAdmin(user.sub, idProject)
  const { handleChange, values, errors, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        code: proyecto?.code || '',
        name: proyecto?.name || '',
        description: proyecto?.description || '',
        status: proyecto?.status || '',
        startAt: new Date(proyecto?.startAt) || new Date(),
        finishDate: proyecto?.finishDate || '',
        item: proyecto?.item || '',
        hubId: proyecto?.hubId || '',
      },
      onSubmit: values => {
        mutateAsync({
          body: values,
        })
      },
      validationSchema: infoProjectValidation,
    })

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      className="form-main-info-proyecto-container"
    >
      <div>
        <TextInput
          label="Código"
          name="code"
          value={values.code}
          onChange={handleChange}
        />
        {errors.code && <p className="error-form">{errors.code}</p>}
      </div>

      <div>
        <TextInput
          label="Nombre"
          name="name"
          value={values.name}
          onChange={handleChange}
        />

        {errors.name && <p className="error-form">{errors.name}</p>}
      </div>

      <div>
        <TextInput
          label="Descripción"
          name="description"
          value={values.description}
          onChange={handleChange}
        />
        {errors.description && (
          <p className="error-form">{errors.description}</p>
        )}
      </div>
      <div>
        <SelectInput
          value={values.status}
          onChange={handleChange}
          name="status"
        >
          {statusS_PROJECT.map((status, i) => (
            <OptionsItem key={i} name="status" value={status}>
              {status}
            </OptionsItem>
          ))}
        </SelectInput>
        {errors.status && <p className="error-form">{errors.status}</p>}
      </div>
      <div className="calendars-inputs-container">
        <div className="calendar-input-container">
          <DatesInputsCalendar
            label="Fecha inicio"
            name="startAt"
            date={values.startAt}
            handleChange={day => setFieldValue('startAt', day)}
          />
        </div>
        <div className="calendar-input-container">
          <DatesInputsCalendar
            label="Fecha fin"
            name="finishDate"
            date={values.finishDate || null}
            handleChange={day => setFieldValue('finishDate', day)}
            disabled={values.status !== 'Completado'}
          />
          {errors.finishDate && (
            <p className="error-form">{errors.finishDate}</p>
          )}
        </div>
      </div>

      <TextInput
        label="Item Id"
        name="item"
        value={values.item}
        onChange={handleChange}
        disabled
      />
      <TextInput
        label="Hub Id"
        name="hubId"
        value={values.hubId}
        onChange={handleChange}
        disabled
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CustomButton type="submit">
          <p style={{ fontSize: '1.2rem' }}>Guardar</p>
        </CustomButton>
      </div>
    </form>
  )
}

export default FormEditProyecto
