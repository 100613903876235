import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomButton } from 'components/ui'


const Page404 = () => {
  const navigate = useNavigate()
  const volver = () => {
    let path = '/'
    navigate(path)
  }

  return (
    <div className="error-container-page">
      <div className="error-text-container">
        <section class="error-container">
          <span class="four">
            <span class="screen-reader-text">4</span>
          </span>
          <span class="zero">
            <span class="screen-reader-text">0</span>
          </span>
          <span class="four">
            <span class="screen-reader-text">4</span>
          </span>
        </section>
        <h1 className="text-not-found">Ooops... página no encontrada</h1>
        <CustomButton className="button-page-404" type="secondary" onClick={volver}>
          Volver
        </CustomButton>

      </div>
      <div className="error-image-container"></div>
    </div>
  )
}

export default Page404
