import CheckboxInput from 'components/ui/Forms/CheckboxInput'
import { useRulesBimLInk } from 'context/RulesBimLinkContext'
import React from 'react'

const DisplayValueItems = ({ items, resetCustomCheckbox }) => {
  const { setSelectedDisplayValue, selectedDisplayValue } = useRulesBimLInk()
  const handleOnChange = (data, e) => {
    resetCustomCheckbox()
    setSelectedDisplayValue(data)
  }

  if (!items || items.length == 0) return

  return (
    <>
      {items.map((item, index) => (
        <CheckboxInput
          key={index}
          label={<p style={{ marginLeft: '.8rem' }}>{item}</p>}
          name="displayValue"
          type="radio"
          onChange={e => handleOnChange(item, e)}
          checked={selectedDisplayValue === item}
        />
      ))}
    </>
  )
}

export default DisplayValueItems
