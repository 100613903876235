import { useQuery, useQueryClient } from '@tanstack/react-query'
import { DIAS_MES_MO } from '../utils/constants'
import { useParams } from 'react-router-dom'
import { useGetModulosByProject } from 'lib/react-query/queriesModuloType'
import { useMemo, useState } from 'react'

export const useTotalMo = () => {
  const { idProject } = useParams()
  const queryClient = useQueryClient()
  const { data } = useQuery(['project', idProject], null)
  const modulosType = queryClient.getQueryData([
    'matriculas-visibles',
    idProject,
  ])

  const totalMo = useMemo(() => {
    if (!data || !data?.data?.proyecto?.manoDeObraRendimiento || !modulosType)
      return null
    return (
      (data?.data?.proyecto?.manoDeObraCoste / DIAS_MES_MO) *
        (modulosType?.instances /
          data?.data?.proyecto?.manoDeObraRendimiento.rendimiento) *
        data?.data?.proyecto?.manoDeObraRendimiento.personas || 0
    ).toFixed(2)
  }, [data, modulosType])

  const totalMoByModulo = (instances = 1) => {
    if (totalMo === 0 || !totalMo) return null
    return (totalMo / modulosType.instances) * instances
  }

  const totalMoByVinculo = useMemo(() => {
    if (totalMo === 0 || !totalMo || !modulosType) return null
    return totalMo / modulosType.instances
  }, [data, modulosType])

  return {
    totalMo,
    totalMoByModulo,
    totalMoByVinculo,
    instances: modulosType?.instances || null,
    matriculas: modulosType?.data || [],
  }
}
