import ArticulosTableLoader from 'components/Loaders/ArticulosTableLoader'
import { useGetAllMatriculasByProject } from 'lib/react-query/queriesModuloType'
import ConfigMatriculasRow from './ConfigMatriculasRow'
import { useConfigMatriculas } from 'context/ConfigMatriculasContext'

const ConfigMatriculasTable = () => {
  const { data, isLoading, isFetching } = useGetAllMatriculasByProject()
  const { textFieldValue } = useConfigMatriculas()

  if (isLoading || !data) return <ArticulosTableLoader />

  return (
    <div
      className="fabrica-matriculas-table-container"
      style={{ flex: 1, overflowY: 'auto', marginTop: '1rem' }}
    >
      <table>
        <thead>
          <tr>
            <th className="ta-left">Matrícula</th>
            <th className="ta-right visibility-header">Visibilidad</th>
          </tr>
        </thead>
        <tbody>
          {data.data
            .filter(item => item.code.toLowerCase().includes(textFieldValue))
            .sort()
            .map(item => (
              <ConfigMatriculasRow
                matricula={item}
                key={item._id}
                isFetching={isFetching}
              />
            ))}
          {data.data
            .filter(item => item.code.toLowerCase().includes(textFieldValue))
            .sort().length == 0 && (
            <p style={{ fontStyle: 'oblique', margin: '1rem 0 1rem .5rem' }}>
              No hay matrículas para mostrar
            </p>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ConfigMatriculasTable
