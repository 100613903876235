import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import Login from '../pages/Login'
import Page404 from '../pages/Error404'
import { ProtectedRoute } from './ProtectedRoute'
import NewProject from '../pages/NewProject'
import Collaborators from '../pages/Collaborators'
import ArticulosContainer from '../components/sidebar/ArticulosContainer'
import EditProject from '../pages/EditProject'
import DashboardContainer from '../pages/DashboardContainer'
import CelulasWrapper from '../components/Celulas/CelulasWrapper.jsx'
import ArticulosWrapper from '../components/Articulos/ArticulosWrapper.jsx'
import NewCelula from '../components/Celulas/NewCelula/NewCelula.jsx'
import EditCelulaWrapper from '../components/Celulas/EditCelula/EditCelulaWrapper.jsx'
import CreateManoDeObraWrapper from '../components/ManoDeObra/CreateManoDeObraWrapper.jsx'
import {
  NewBimlinkPage,
  NewGroupBimlinkPage,
  OneModuloPage,
  UnderConstructionPage,
} from '../pages'
import Articulos from 'pages/Articulos'
import ViewerLayoutWrapper from 'layouts/ViewerLayoutWrapper'
import { NewBimlinkPageWrapper } from 'pages/NewBimlinkPage'
import NewArticuloPage from 'pages/NewArticuloPage'
import { NewGroupBimLinkWrapper } from 'pages/NewGroupBimlinkPage'
import NewRulePage from 'pages/NewRulePage'
import Tipos from 'pages/Tipos'
import BimLinkPage from 'pages/BimLinkPage'
import EditArticuloPage from 'pages/EditArticuloPage'
import ConfigMatriculas from 'pages/ConfigMatriculas'
import EditBimLinkPage from 'pages/bimlink/edit-bimlink/EditBimLinkPageWrapper'
import EditGroupBimLinkWrapper from 'pages/bimlink/edit-bimlink/_bimlinkId/group/_groupUuid/EditGroupBimLinkWrapper'
import EditArticuloWrapper from 'pages/edit-articulo/EditArticuloWrapper'
import AdminLayout from 'pages/admin/layout'
import ArticulosAdminPage from 'pages/admin/Articulos/ArticulosAdminPage'
import NewArticuloAdminPage from 'pages/admin/Articulos/NewArticuloAdminPage'
import EditArticuloAdminWrapper from 'pages/admin/Articulos/EditArticuloAdminWrapper'
import ActividadesAdminPage from 'pages/admin/Actividad/ActividadesAdminPage'
import NewActividadAdminPage from 'pages/admin/Actividad/NewActividadAdminPage'
import EditActividadAdminWrapper from 'pages/admin/Actividad/EditActividadAdminWrapper'
import ProtectedRouteByRole from './ProtectedRouteByRole'
import { ProyectosPage } from 'pages/admin/Proyectos/ProyectosPage'
import SingleProyectoWrapper from 'pages/admin/Proyectos/SingleProyectoPage'
import ConfigProyecto from 'components/Proyectos/EditProyecto.jsx/ConfigProyecto/ConfigProyecto'
import UserProyecto from 'components/Proyectos/EditProyecto.jsx/UsersProyecto/UserProyecto'
import { ROLES_AUTH0 } from 'utils/constant/constantsRoles'
import ConfigTiposPage from 'pages/tipos/ConfigTiposPage'
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<ProtectedRoute component={<DashboardContainer />} />}
    >
      <Route index element={<Dashboard />} />
      <Route
        path="new"
        element={<ProtectedRoute component={<NewProject />} />}
      />
      <Route
        path=":idProject/collaborators"
        element={<ProtectedRoute component={<Collaborators />} />}
      />
      <Route
        path=":idProject/edit"
        element={<ProtectedRoute component={<EditProject />} />}
      />

      {/* <Route path="login" element={<Login />} /> */}

      <Route path="*" element={<Page404 />} />

      {/* CONTENEDOR DEL SIDEBAR CON EL VISOR */}
      <Route
        path="viewer/:idProject"
        element={<ProtectedRoute component={<ViewerLayoutWrapper />} />}
      >
        <Route index element={<Navigate to="datos" replace />} />
        <Route path="datos">
          <Route index element={<UnderConstructionPage />} />
        </Route>
        <Route path="tipos">
          <Route index element={<Tipos />} />
          <Route path="config-tipos" element={<ConfigTiposPage />} />
        </Route>
        <Route path="fabrica" element={<ArticulosContainer />}>
          <Route index element={<ArticulosWrapper />} />
          <Route path=":moduloId" element={<OneModuloPage />} />
          <Route path="config-matriculas" element={<ConfigMatriculas />} />
          <Route path="gestionar-celulas">
            <Route index element={<CelulasWrapper />} />
            <Route path="nueva-celula" element={<NewCelula />} />
            <Route path=":celulaId" element={<EditCelulaWrapper />} />
          </Route>
          <Route path="config-mo" element={<CreateManoDeObraWrapper />} />
        </Route>
        <Route path="bimlink">
          <Route index element={<BimLinkPage />} />
          <Route path="new-bimlink" element={<NewBimlinkPageWrapper />}>
            <Route index element={<NewBimlinkPage />} />
            <Route path="group" element={<NewGroupBimLinkWrapper />}>
              <Route index element={<NewGroupBimlinkPage />} />
              <Route path="new-articulo" element={<NewArticuloPage />} />
              <Route path="new-rule" element={<NewRulePage />} />
              <Route
                path="edit-articulo/:articuloUuid"
                element={<EditArticuloWrapper />}
              />
            </Route>
            <Route
              path="edit-group/:groupUuid"
              element={<EditGroupBimLinkWrapper />}
            >
              <Route index element={<NewGroupBimlinkPage />} />
              <Route path="new-articulo" element={<NewArticuloPage />} />
              <Route path="new-rule" element={<NewRulePage />} />
              <Route
                path="edit-articulo/:articuloUuid"
                element={<EditArticuloWrapper />}
              />
            </Route>
          </Route>
          <Route path="edit-bimlink/:bimlinkId" element={<EditBimLinkPage />}>
            <Route index element={<NewBimlinkPage />} />
            <Route path="group" element={<NewGroupBimLinkWrapper />}>
              <Route index element={<NewGroupBimlinkPage />} />
              <Route path="new-articulo" element={<NewArticuloPage />} />
              <Route path="new-rule" element={<NewRulePage />} />
              <Route
                path="edit-articulo/:articuloUuid"
                element={<EditArticuloWrapper />}
              />
            </Route>
            <Route
              path="edit-group/:groupUuid"
              element={<EditGroupBimLinkWrapper />}
            >
              <Route index element={<NewGroupBimlinkPage />} />
              <Route path="new-articulo" element={<NewArticuloPage />} />
              <Route path="new-rule" element={<NewRulePage />} />
              <Route
                path="edit-articulo/:articuloUuid"
                element={<EditArticuloWrapper />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="articulos" element={<Articulos />}>
          {/* <Route index element={<Articulos />} /> */}
          <Route path=":idarticulo" element={<EditArticuloPage />} />
        </Route>
        <Route path="transporte">
          <Route index element={<UnderConstructionPage />} />
        </Route>
        <Route path="montaje">
          <Route index element={<UnderConstructionPage />} />
        </Route>
        <Route path="repasos">
          <Route index element={<UnderConstructionPage />} />
        </Route>
      </Route>

      {/* CONTENEDOR PARA EL DASHBOARD DEL ADMIN */}
      <Route
        path="admin"
        element={
          <ProtectedRouteByRole role={ROLES_AUTH0.admin}>
            <AdminLayout />
          </ProtectedRouteByRole>
        }
      >
        <Route index element={<Navigate to="articulos" replace />} />
        <Route path="articulos" element={<ArticulosAdminPage />}>
          <Route path="new-articulo" element={<NewArticuloAdminPage />} />
          <Route
            path="edit-articulo/:idArticulo"
            element={<EditArticuloAdminWrapper />}
          />
        </Route>
        <Route path="actividades" element={<ActividadesAdminPage />}>
          <Route path="new-actividad" element={<NewActividadAdminPage />} />
          <Route
            path="edit-actividad/:idActividad"
            element={<EditActividadAdminWrapper />}
          />
        </Route>
        <Route path="proyectos">
          <Route
            index
            element={
              <ProtectedRouteByRole role={ROLES_AUTH0.master}>
                <ProyectosPage />
              </ProtectedRouteByRole>
            }
          />
          <Route path=":idProject" element={<SingleProyectoWrapper />}>
            <Route index element={<Navigate to="config" replace />} />

            <Route index path="config" element={<ConfigProyecto />} />
            <Route index path="users" element={<UserProyecto />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
)
