import axios from 'axios'
function base64ToBlob(base64Image) {
  // Divide la cadena Base64 en partes
  var parts = base64Image.split(';base64,')
  var contentType = parts[0].split(':')[1]
  var raw = window.atob(parts[1])
  var rawLength = raw.length
  var uInt8Array = new Uint8Array(rawLength)

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  // Devuelve el objeto Blob
  return new Blob([uInt8Array], { type: contentType })
}
const getAllProjectsByUserAdmin = async options => {
  try {
    const { data } = await axios.get('/api/v1/proyectos', {
      params: options,
    })
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const getSingleProjectByUserAdmin = async idProject => {
  try {
    const { data } = await axios.get(`/api/v1/proyectos/admin/${idProject}`)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const updateSingleProjectByUserAdmin = async (idProject, body) => {
  try {
    const { data } = await axios.patch(
      `/api/v1/proyectos/admin/${idProject}`,
      body
    )
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const updateProject = async (project, body) => {
  try {
    const { data } = await axios.patch(`/api/v1/proyectos/${project}`, body)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const checkProjectVersion = async (projectId, version) => {
  return axios.get(`/api/v1/projects/checkitem/${projectId}`, {
    params: {
      autodeskProjectId: process.env.REACT_APP_AUTODESK_HUB,
      version,
    },
  })
}

const getVersionsImportByProject = async idProject => {
  try {
    const { data } = await axios.get(
      `/api/v1/proyectos/${idProject}/importsbyversion`
    )
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const updateImageProject = async (idProject, dataUrl) => {
  const blob = base64ToBlob(dataUrl)
  var file = new File([blob], 'imagen.jpg', { type: 'image/jpeg' })

  var formData = new FormData()
  formData.append('image', file)

  try {
    const res = await axios.post(
      `/api/v1/proyectos/${idProject}/update-image`,
      formData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    return res
  } catch (e) {
    throw new Error(e)
  }
}
export {
  updateProject,
  getVersionsImportByProject,
  checkProjectVersion,
  getAllProjectsByUserAdmin,
  getSingleProjectByUserAdmin,
  updateSingleProjectByUserAdmin,
  updateImageProject,
}
