import { InputAdornment, TextField } from '@mui/material'
import React, { useMemo } from 'react'
import { DIAS_MES_MO } from '../../../utils/constants'
import TextInput from 'components/ui/Forms/TextInput'

const TotalCosteManoDeObra = ({ totalModulos, mo, costeMo }) => {
  const dias = useMemo(() => {
    return totalModulos / mo?.rendimiento || 0
  }, [totalModulos, mo])

  const totalMO = useMemo(() => {
    return ((costeMo / DIAS_MES_MO) * dias * mo?.personas || 0).toFixed(2)
  }, [dias, mo, costeMo])

  return (
    <div>
      <TextInput
        type="number"
        value={totalMO}
        label="Total coste de mano de obra"
        disabled
        containerStyles={{ width: '300px' }}
        adornment={<span>€</span>}
      />
    </div>
  )
}

export default TotalCosteManoDeObra
