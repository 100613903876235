import { CustomButton } from 'components/ui'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import { useNavigate } from 'react-router-dom'
const ButtonPanelAdmin = () => {
  const navigate = useNavigate()
  return (
    <CustomButton
      type="green"
      className="btn-panel-admin"
      onClick={() => navigate('/admin')}
    >
      <LocalPoliceIcon />
      Panel de administración
    </CustomButton>
  )
}

export default ButtonPanelAdmin
