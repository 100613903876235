import MoreVertIcon from '@mui/icons-material/MoreVert'

export const TABLE_HEADER_ARTICULOS = [
  {
    name: 'Código',
    className: 'ta-left',
  },
  {
    name: 'Nombre',
    className: 'ta-left',
  },
  {
    name: 'Precio/u',
    className: 'ta-right',
  },
  {
    name: 'Cantidad',
    className: 'ta-right',
  },
  {
    name: 'Importe',
    className: 'ta-right',
  },
  {
    name: <MoreVertIcon />,
    className: '',
  },
]

export const TABLE_HEADER_ARTICULOS_ADMIN = [
  {
    name: 'Código',
    className: 'ta-left',
  },
  {
    name: 'Nombre',
    className: 'ta-left',
  },
  {
    name: 'Precio',
    className: 'ta-right',
  },
  {
    name: 'Unidad',
    className: 'ta-right',
  },
  {
    name: '',
    className: 'w-80',
  },
]

export const TABLE_HEADER_ACTIVIDADES_ADMIN = [
  {
    name: 'Código',
    className: 'ta-left',
  },
  {
    name: 'Nombre',
    className: 'ta-left',
  },
  {
    name: '',
    className: 'w-50',
  },
]
