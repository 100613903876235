import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      light: '#f9f9f9',
      main: '#fefefe',
      contrastText: '#000',
    },
    secondary: {
      main: '#000000',
      contrastText: '#fefefe',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Helvetica, Arial, Lucida, sans-serif',
  },
})
export default theme
