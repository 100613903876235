import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useHandleUrl } from '../../hooks/useHandleUrl'
import { useLocation, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { CustomButton } from '../ui'

const ButtonNewConfigMO = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()

  const handleOnClick = () => {
    navigate(changeLocation(`${location.pathname}/config-mo`))
  }
  return (
    <div style={{ display: 'grid' }}>
      <div className="new-congif-mo-container">
        <div className="new-config-mo-text-container">
          <WarningAmberIcon fontSize="small" style={{ color: '#967132' }} />
          <p>
            No hay información de la mano de obra. Configura el coste de la mano
            de obra.
          </p>
        </div>
        <div>
          <CustomButton
            className="button-new-partida"
            color="none"
            style={{
              margin: '0',
              fontSize: '.8rem',
              display: 'flex',
              alignItems: 'center',
              gap: '.3rem',
            }}
            onClick={handleOnClick}
          >
            <AddIcon sx={{ color: 'white' }} fontSize="small" />
            Crear coste MO
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default ButtonNewConfigMO
