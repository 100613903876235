import { CustomButton } from 'components/ui'
import React from 'react'
import { Tooltip } from 'react-tooltip'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const DeleteUserProyectoButton = ({ user = null, handleDeleteUser = null }) => {

  const handleOnClick = () => {
    if (!user || !handleDeleteUser) return
    handleDeleteUser(user)
  }
  return (
    <>
      <div className="delete-user-button-proyecto-container">
        <CustomButton
          type="light"
          className="btn-sxm delete-user-project"
          onClick={handleOnClick}
        >
          <DeleteOutlineIcon sx={{ fontSize: '2rem' }} />
        </CustomButton>
      </div>
      <Tooltip
        anchorSelect=".delete-user-project"
        style={{
          fontSize: '9px',
        }}
      >
        Eliminar usuario
      </Tooltip>
    </>
  )
}

export default DeleteUserProyectoButton
