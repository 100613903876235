import useSelectedUsers from 'hooks/useSelectedUsers'
import { createContext, useContext, useState } from 'react'

export const AddUsersToProjectContext = createContext()

export const AddUsersToProjectProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState('')

  const {
    userSelected,
    addUser,
    handleDeleteUser,
    users,
    handleUpdateUser,
    handleAddUsersToProject,
  } = useSelectedUsers(inputValue)

  const handleInputValue = text => {
    setInputValue(text)
  }

  const data = {
    inputValue,
    setInputValue,
    userSelected,
    addUser,
    handleDeleteUser,
    users,
    handleInputValue,
    handleAddUsersToProject,
    handleUpdateUser,
  }

  return (
    <AddUsersToProjectContext.Provider value={data}>
      {children}
    </AddUsersToProjectContext.Provider>
  )
}

export const useAddUsersToProject = () => {
  const context = useContext(AddUsersToProjectContext)
  if (context === undefined) {
    throw new Error(
      'useAddUsersToProject must be used within a AddUsersToProjectProvider'
    )
  }
  return context
}
