import { ViewerContext } from '../context/ViewerContext'
import {
  checkIfUrnHasGeometryInModelDerivatives,
  createVersion,
  getVersionsByItem,
  getVersionsByProject,
} from '../api/jitApi'
import { getCurrentModelVersion } from '../utils/launchViewer'
import { toast } from 'react-hot-toast'
import { useHandleUrl } from './useHandleUrl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useProject } from 'context/ProyectoContext'

// * Custom hook para gestionar la creación de versiones.

const useVersions = () => {
  const { idProject } = useParams()
  const { currentProject: data, isLoadingCurrentProject: isFetching } =
    useProject()
  // const { data, isFetching } = useQuery(['project', idProject], null)
  const queryClient = useQueryClient()
  const { version } = useHandleUrl()
  const location = useLocation()
  const navigate = useNavigate()

  if (!ViewerContext) {
    throw new Error('No se puede acceder al ViewerContext')
  }

  const getAllVersionsByProject = (filterByCurrentVersion = false) => {
    const currentVersion = getCurrentModelVersion()

    return new Promise((resolve, reject) => {
      getVersionsByProject(
        idProject,
        filterByCurrentVersion ? { version: parseInt(currentVersion) } : null
      )
        .then(res => {
          console.log('res getAllVersionsByProject', res)
          resolve(res)
        })
        .catch(e => reject(e))
    })
  }

  const getAllVersionsByModel = () => {
    if (isFetching) throw new Error('CurrentProyect no está definido')
    return new Promise((resolve, reject) => {
      getVersionsByItem(data.data.proyecto.item)
        .then(res => resolve(res))
        .catch(e => reject(e))
    })
  }

  // Hay que ejecutarlo cada vez que cambiar el projectId o la version
  const checkIfUrnHasGeometry = async () => {
    try {
      // Traer todas las versiones del modelo
      const { data: allVersionsModel } = await getAllVersionsByModel()

      console.log('allVErsionsModel', allVersionsModel)
      // Si existe el parámetro versión, comprobar que el número pasado se encuentra en allVersionsModel
      if (version) {
        const versionExists = allVersionsModel.find(
          v => v.attributes.versionNumber == parseInt(version)
        )

        if (!versionExists) {
          // Si no existe, redirigir a la versión más reciente
          toast.error(
            'La versión a la que estás intentando acceder no existe en el modelo. Redirigiendo a la última versión...'
          )
          setTimeout(() => {
            return navigate({
              pathname: location.pathname,
            })
          }, 3500)
          return
        }
      }

      // * Comprobar si existe la versión por proyecto y versión en BD
      const currentVersionInMongo = await getVersionsByProject(idProject, {
        version: version || allVersionsModel[0].attributes.versionNumber,
      })

      // * Si existe, comprobar si tiene en false la propiedad existsGeometry. Si es así, redirigir a la última versión
      if (currentVersionInMongo.results > 0) {
        const currentVersion = currentVersionInMongo.data.data[0]
        if (!currentVersion.existsGeometry) {
          toast.error(
            'La versión a la que estás intentando acceder no tiene geometría. Redirigiendo a la última versión...'
          )
          setTimeout(() => {
            return navigate({
              pathname: location.pathname,
            })
          }, 3500)
          return
        } else {
          return
        }
      }

      // * Si no existe, llamar al endpoint de designdata, comprobar que tiene objetos y guardarlo en mongo.
      let urn
      if (!version) {
        urn = allVersionsModel[0]
      } else {
        urn = allVersionsModel.find(
          item => item.attributes.versionNumber == version
        )
      }

      // * Si el modelo tiene esa versión, ir a ver si ese modelo esta vacio y no contiene ninguna geometría
      // * Puede ser que tenga id pero no haya geometría. Por eso volvemos a comprobar luego la geometría en el checkIfUrnHasGeometryInModelDerivatives.
      if (!urn?.relationships?.derivatives?.data?.id) {
        await createVersion({
          version: version || allVersionsModel[0].attributes.versionNumber,
          proyecto: idProject,
          existsGeometry: false,
        })
        // ivalidamos la query que trae las versiones
        queryClient.invalidateQueries(['versions-project', idProject])
        toast.error(
          'Este modelo no tiene ninguna geometría que pueda mostrar el visor. Redirigiendo a la última versión...'
        )
        setTimeout(() => {
          return navigate({
            pathname: location.pathname,
          })
        }, 3500)
        return
      }

      // Si el modelo tiene urn en sus derivatives, ir a comprobar si tiene geometría o no
      console.log('Comprobando derivatives....')
      const toastId = toast.loading(
        'Comprobando información del modelo en la versión actual. Esta operación puede tardar unos segundos.',
        {
          duration: Infinity,
        }
      )

      const res = await checkIfUrnHasGeometryInModelDerivatives(
        urn.relationships.derivatives.data.id
      )

      if (res.status == 200) {
        const versionRes = await createVersion({
          version: version || allVersionsModel[0].attributes.versionNumber,
          proyecto: idProject,
          existsGeometry: true,
        })
        queryClient.invalidateQueries(['versions-project', idProject])
        toast.dismiss(toastId)
        toast.success(`Versión ${versionRes?.data?.version} creada`)
      }

      // Status(204) el modelo no tiene elementos
      if (res.status == 204) {
        await createVersion({
          version: version || allVersionsModel[0].attributes.versionNumber,
          proyecto: idProject,
          existsGeometry: false,
        })
        queryClient.invalidateQueries(['versions-project', idProject])

        toast.dismiss(toastId)
        // toast.success(`Versión ${versionRes?.data?.version} creada`);
        toast.error(
          'Este modelo no tiene ninguna geometría que pueda mostrar el visor. Redirigiendo a la última versión...'
        )

        setTimeout(() => {
          return navigate({
            pathname: location.pathname,
          })
        }, 3500)
        return
      }
    } catch (e) {}
  }

  const handleCreateVersion = () => {
    const currentVersion = getCurrentModelVersion()

    // * Comprobar si existe la versión por proyecto y versión en BD
    getVersionsByProject(idProject, {
      version: parseInt(currentVersion),
    }).then(res => {
      // * Si no existe, crearla.
      if (res.results == 0) {
        createVersion({
          version: currentVersion,
          proyecto: idProject,
        }).then(res => {
          queryClient.invalidateQueries(['versions-project', idProject])
          toast.success(`Versión ${res?.data?.version}creada`)
        })
      }
    })
    // }
  }

  return {
    handleCreateVersion,
    getAllVersionsByProject,
    getAllVersionsByModel,
    checkIfUrnHasGeometry,
  }
}

export default useVersions
