import React, { useMemo } from 'react'
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from 'react-router-dom'
import { useProject } from 'context/ProyectoContext'
import { useQueryClient } from '@tanstack/react-query'

const SelectVersion = props => {
  const queryClient = useQueryClient()
  const { idProject } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  let [searchParams] = useSearchParams()
  const { versions } = useProject()
  const data = queryClient.getQueryData(['project', idProject])

  const currentVersion = useMemo(() => {
    return (
      searchParams.get('version') ||
      Math.max(...versions.map(item => Number(item.attributes.versionNumber)))
    )
  }, [searchParams.get('version'), versions])

  const handleChange = async event => {
    navigate({
      pathname: location.pathname,
      search: `?version=${event.target.value}`,
    })
  }

  return (
    <div className="select-version-container">
      <div className="select-version-options-wrapper">
        <p style={{ fontSize: '1.2rem' }}>Versión:</p>
        <div className="select-version-options-container">
          <select
            style={{ cursor: 'pointer' }}
            value={currentVersion}
            onChange={handleChange}
          >
            {versions.map(item => (
              <option key={item.attributes.versionNumber}>
                {item.attributes.versionNumber}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

export default SelectVersion
