export const checkIformulaIsValid = formula => {
    // Expresión regular para encontrar parámetros entre "%".
    const regex = /%([^%]+)%/g
  
    // Almacena los parámetros encontrados en la fórmula.
    let match
    const parametros = []
  
    // Buscar todos los parámetros entre "%".
    while ((match = regex.exec(formula)) !== null) {
      parametros.push(match[0])
    }
  
    // Crear una nueva fórmula reemplazando los parámetros encontrados con un espacio en blanco.
    let formulaSinParametros = formula
    parametros.forEach(param => {
      formulaSinParametros = formulaSinParametros.replace(param, ' ')
    })
  
    // Verificar si quedan "%" no balanceados en la fórmula sin parámetros.
    const quedanPorcentajesSinBalancear = formulaSinParametros.includes('%')
  
    // Si quedan "%" sin balancear, la fórmula es inválida.
    if (quedanPorcentajesSinBalancear) {
      return false
    }
  
    // Si no hay "%" sin balancear, la fórmula es válida.
    return true
  }


  
  