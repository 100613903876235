import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ModuloContext } from '../../context/ModuloContext'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { capitalizarPalabras } from '../../utils/formatText'
import { redondearDecimalesToLocaleString } from '../../utils/roundNumber'
import ArticuloContainerTableRow from './ArticuloContainerTableRow'

const ActivityTableRow = ({ activity, showArticulos }) => {
  const { addDbIdSelected, removeDbIdSelected } = useContext(ModuloContext)
  const [articulosAreOpened, setArticulosAreOpened] = useState(false)

  const handleOnClick = () => {
    setArticulosAreOpened(prevState => !prevState)
  }

  const handleOnFilter = e => {
    e.stopPropagation()
    if (activity.isSelected) {
      removeDbIdSelected(activity._id)
    } else {
      addDbIdSelected(activity._id)
    }
  }

  const totalCelula = useMemo(() => {
    return (
      activity.articulos
        ?.reduce((total, item) => total + item.quantity * item.precio, 0)
        .toFixed(2) || 0
    )
  }, [activity])
  return (
    <>
      <tr
        style={{ background: articulosAreOpened && '#f9f9f9' }}
        onClick={
          showArticulos && activity.articulos.length > 0 && handleOnClick
        }
      >
        <td className="activity-table-row-container">
          {activity.articulos.length > 0 ? (
            articulosAreOpened ? (
              <>
                <KeyboardArrowDownIcon fontSize="small" />
                <div className="celula-box activity-color" />
              </>
            ) : (
              <>
                <KeyboardArrowRightIcon fontSize="small" />
                <div className="celula-box activity-color" />
              </>
            )
          ) : null}

          <p
            style={{
              marginLeft: activity.articulos.length > 0 ? '0' : '1.7rem',
            }}
          >
            {activity.code} - {capitalizarPalabras(activity?.name)}
          </p>
          <VisibilityIcon
            style={{ color: activity.isSelected ? '#1976D2' : '#84898e' }}
            size="small"
            onClick={handleOnFilter}
          />
        </td>
        <td></td>
        <td></td>
        {showArticulos && (
          <td>
            <p className="activity-amount">
              {redondearDecimalesToLocaleString(totalCelula)}€
            </p>
          </td>
        )}
      </tr>
      {articulosAreOpened && (
        <ArticuloContainerTableRow articulos={activity.articulos} />
      )}
    </>
  )
}

export default ActivityTableRow
