import { IconButton, Popover, Tooltip } from '@mui/material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React from 'react'
import { Button } from 'reactstrap'
import DeleteIcon from '@mui/icons-material/Delete'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteCelula } from '../../api/jitApi'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

const ButtonDeleteCelula = ({ celula }) => {
  const { idProject } = useParams()
  const queryClient = useQueryClient()
  const onSuccess = async () => {
    await queryClient.invalidateQueries(['celulas', idProject])
    toast.success('Célula eliminada correctamente')
  }

  const onError = () => {
    toast.error('Error al eliminar la célula')
  }

  const deleteCelulaMutation = useMutation({
    mutationFn: deleteCelula,
    onSuccess,
    onError,
  })

  return (
    <PopupState variant="popover" popupId="pop-over-delete">
      {popupState => (
        <div>
          <Tooltip
            title={<p className="tooltip-text">Eliminar célula</p>}
            placement="right-end"
          >
            <IconButton onClick={e => e.stopPropagation()}>
              <DeleteIcon {...bindTrigger(popupState)} fontSize="large" />
            </IconButton>
          </Tooltip>
          <Popover
            {...bindPopover(popupState)}
            onClick={e => e.stopPropagation()}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <div style={{ padding: '1rem' }}>
              <p style={{ fontSize: '.75rem' }}>
                {`¿Estás seguro que quieres eliminar la célula ${celula.name}?`}
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '1rem',
                  marginTop: '.5rem',
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    fontSize: '.7rem',
                    padding: '5px 5px',
                    minWidth: '0',
                  }}
                  onClick={() => {
                    popupState.setOpen(false)
                  }}
                >
                  No
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    fontSize: '.75rem',
                    padding: '5px 5px',
                    minWidth: '0',
                    background: '#898989',
                  }}
                  onClick={() => {
                    deleteCelulaMutation.mutate(celula._id)
                    popupState.setOpen(false)
                  }}
                >
                  Sí
                </Button>
              </div>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

export default ButtonDeleteCelula
