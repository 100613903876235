export const areSameExternalIds = (externalIdsInBd, externalIdsInModel) => {
  // * Comparar los elementos usando Set para ignorar el orden
  let set1 = new Set(externalIdsInBd)
  let set2 = new Set(externalIdsInModel)

  for (let item of set1) {
    if (!set2.has(item)) {
      return false
    }
  }

  return true
}

export const expandTipos = array => {
  const result = []
  array.forEach(item => {
    // Comparamos por string por si hay data vieja en BD cuando el tipoExternalId era un string y no un array.
    if (typeof item.tipoExternalId === 'string')
      return result.push({
        bimlink: item.bimlink,
        name: item.name,
        tipoExternalId: item.tipoExternalId,
      })
    item.tipoExternalId.forEach(id => {
      result.push({
        bimlink: item.bimlink,
        name: item.name,
        tipoExternalId: id,
      })
    })
  })
  return result
}
