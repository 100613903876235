import React from 'react'
import { CustomButton } from 'components/ui'
import { useUpdateMatriculaByProject } from 'lib/react-query/queriesModuloType'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
const ConfigMatriculasRow = ({ matricula, isFetching }) => {
  const { mutate } = useUpdateMatriculaByProject()

  const handleVisibility = () => {
    mutate({
      id: matricula._id,
      body: {
        visible: !matricula.visible,
      },
    })
  }

  return (
    <tr style={{ background: !matricula.visible && '#ededed' }} className='row-align-config-matriculas'>
      <td className="name-row-config-matriculas-table">
        {matricula.visible ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
        {matricula.code}
      </td>
      <td className="ta-right">
        <CustomButton
          className="button-config-matriculas-table"
          type="secondary"
          onClick={handleVisibility}
          disabled={isFetching}
        >
          {matricula.visible ? 'Ocultar' : 'Mostrar'}
        </CustomButton>
      </td>
    </tr>
  )
}

export default ConfigMatriculasRow
