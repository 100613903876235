import * as yup from 'yup'

export const infoProjectValidation = yup.object({
  code: yup.string().required('El código es obligatorio'),
  name: yup.string().required('El nombre es obligatorio'),
  description: yup.string(),
  status: yup.string().required(),
  startAt: yup.date().required(),
  finishDate: yup
    .date()
    .nullable() // permite que el campo sea nulo
    .when('state', {
      is: 'Completado', // condición que debe cumplir el campo state
      then: yup
        .date()
        .required(
          'La fecha de finalización es obligatoria cuando el proyecto está en estado "Completado"'
        ),
      otherwise: yup.date().nullable(), // no obligatorio si la condición no se cumple
    })
    .test(
      'is-greater',
      'La fecha de finalización no puede ser mayor que la fecha de inicio',
      function (value) {
        const { startAt } = this.parent
        return !value || !startAt || value >= startAt
      }
    ),
  item: yup.string(),
  hub: yup.string(),
})
