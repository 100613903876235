import Modal from 'components/ui/Modal/Modal'
import ListUserSelected from './ListUserSelected'
import SelectDropdownUsers from './SelectDropdownUsers'
import ButtonsAddUser from './ButtonsAddUser'

const ModalAddUsers = ({ handleOnClose }) => {

  
  return (
    <Modal
      modalClass="modal modal-new-articulo modal-add-user"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div className="container-select-dropdown-add-user">
        <h2 className="title-modal-add-user">Añadir usuarios</h2>

        <SelectDropdownUsers />
        <ListUserSelected />
        <ButtonsAddUser handleOnClose={handleOnClose}/>
      </div>
    </Modal>
  )
}

export default ModalAddUsers
