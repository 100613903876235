import CheckboxInput from 'components/ui/Forms/CheckboxInput'
import { useRulesBimLInk } from 'context/RulesBimLinkContext'
import { useGetDisplayValuesFromDisplayName } from 'lib/react-query/queries'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DisplayValueItems from './DisplayValueItems'
import TextInput from 'components/ui/Forms/TextInput'

const DisplayValueInput = () => {
  const { idProject } = useParams()
  const { selectedDisplayName, setSelectedDisplayValue } = useRulesBimLInk()
  const { data, isFetching } = useGetDisplayValuesFromDisplayName(
    idProject,
    selectedDisplayName
  )
  const [textInput, setTextInput] = useState('')
  const [checkboxIsCheck, setCheckboxIsCheck] = useState(false)
  useEffect(() => {
    console.log('data', data)
    console.log('isFetching', isFetching)
  }, [data, isFetching])

  const handleOnChange = data => {
    console.log('estoy entrando aquí')
    setTextInput(data)
    setSelectedDisplayValue(data)
    if (data) {
      setCheckboxIsCheck(true)
    }
  }

  const resetCustomCheckbox = () => {
    setCheckboxIsCheck(false)
    setTextInput('')
  }

  if (!selectedDisplayName) return
  return (
    <>
      {isFetching && <p>Cargando valores...</p>}
      {!isFetching && (
        <div style={{ height: '100%', overflowY: 'auto' }}>
          <p
            style={{
              fontWeight: '600',
              fontSize: '14px',
              marginBottom: '1rem',
            }}
          >
            Display Values
          </p>
          <div style={{ marginLeft: '.5rem' }}>
            {data.length == 0 ? (
              <p>No hay valores asociados</p>
            ) : (
              <div>
                <DisplayValueItems
                  items={data.data}
                  resetCustomCheckbox={resetCustomCheckbox}
                />
              </div>
            )}
            <CheckboxInput
              name="displayValue"
              type="radio"
              checked={checkboxIsCheck}
              onClick={() => {
                setCheckboxIsCheck(true)
                setSelectedDisplayValue(textInput)
              }}
            >
              <TextInput
                placeholder="Escribe tu value personalizado"
                containerStyles={{ width: '400px', marginLeft: '.5rem' }}
                value={textInput}
                onChange={e => handleOnChange(e.target.value)}
              />
            </CheckboxInput>
          </div>
        </div>
      )}
    </>
  )
}

export default DisplayValueInput
