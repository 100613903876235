import React, { useState } from 'react'
import { capitalizarPalabras } from '../../../utils/formatText'
import { TextField } from '@mui/material'
import CountActivitiesChecked from './CountActivitiesChecked'
import TextInput from 'components/ui/Forms/TextInput'
import CheckboxInput from 'components/ui/Forms/CheckboxInput'

export const compararPorPropiedad = propiedad => {
  return function (a, b) {
    if (a[propiedad] < b[propiedad]) {
      return -1
    }
    if (a[propiedad] > b[propiedad]) {
      return 1
    }
    return 0
  }
}

const ActivitiesCheckbox = ({
  activities,
  checkedActivities,
  handleCheckboxChange,
}) => {
  const [textFieldValue, setTextFiledValue] = useState('')
  console.log('activities', activities)
  const onChange = e => {
    setTextFiledValue(e.target.value)
  }

  const filterValues = array => {
    if (textFieldValue.length >= 2) {
      return array.filter(
        activity =>
          activity.code.toLowerCase().includes(textFieldValue.toLowerCase()) ||
          activity.name.toLowerCase().includes(textFieldValue.toLowerCase())
      )
    }

    return array
  }

  return (
    <div className="activities-checkbox-container">
      <CountActivitiesChecked
        checkedActivities={checkedActivities.map(
          check =>
            `${activities.find(act => act._id == check).code} - ${
              activities.find(act => act._id == check).name
            }`
        )}
      />

      <TextInput
        label="Actividades"
        name="activities"
        onChange={onChange}
        placeholder="Buscar por código o nombre de actividad. Escribe, al menos, dos caracteres"
        value={textFieldValue}
        // style={{ marginBottom: '1rem' }}
      />
      <div className="activities-checkbox-container">
        <div className="activities-checkbox-items-container">
          {filterValues(activities)
            .sort(compararPorPropiedad('code'))
            .map(activity => (
              <CheckboxInput
                key={activity.id}
                name="activity"
                value={activity._id}
                onChange={handleCheckboxChange}
                label={`${activity.code} - ${capitalizarPalabras(
                  activity.name
                )}`}
                checked={checkedActivities.includes(activity._id)}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ActivitiesCheckbox
