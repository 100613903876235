// export function redondearDecimales(numero, decimales) {

//   console.log('VEAMOS EL NUMERIIITO: ', numero)

//   let numeroRegexp = new RegExp("\\d\\.(\\d){" + decimales + ",}"); // Expresion regular para numeros con un cierto numero de decimales o mas
//   if (numeroRegexp.test(numero)) {
//     // Ya que el numero tiene el numero de decimales requeridos o mas, se realiza el redondeo
//     return Number(numero.toFixed(decimales));
//   } else {
//     return Number(numero.toFixed(decimales)) === 0 ? 0 : numero; // En valores muy bajos, se comprueba si el numero es 0 (con el redondeo deseado), si no lo es se devuelve el numero otra vez.
//   }
// }

// export function redondearDecimales(numero, decimales) {
//   const factor = Math.pow(10, decimales);
//   const roundedNumber = Math.round(numero * factor) / factor;

//   // Convertir el número a cadena para verificar si tiene solo un decimal
//   const numeroComoCadena = roundedNumber.toString();
//   const tieneUnDecimal = numeroComoCadena.indexOf('.') !== -1 && numeroComoCadena.split('.')[1].length === 1;

//   if (tieneUnDecimal) {
//     // Si tiene solo un decimal, aumentamos en uno el número de decimales a redondear
//     return redondearDecimales(numero, decimales + 1);
//   }

//   return roundedNumber;
// }

export function redondearDecimales(numero, decimales = 2) {
  // Redondear el número al número de decimales especificado
  const numeroRedondeado = parseFloat(numero).toFixed(decimales)

  // Convertir el número redondeado a una cadena para trabajar con el formato
  let numeroFormateado = numeroRedondeado.toString()

  // Separar la parte entera de la parte decimal
  const [parteEntera, parteDecimal] = numeroFormateado.split('.')

  // Si no hay parte decimal y se solicitaron decimales, agregar ceros
  if (!parteDecimal && decimales > 0) {
    numeroFormateado += '.' + '0'.repeat(decimales)
  }

  // Reemplazar el punto decimal por la coma decimal (formato español)
  numeroFormateado = numeroFormateado.replace('.', ',')

  // Si el número tiene signo negativo, mover la coma decimal después del signo
  if (numeroFormateado.startsWith('-')) {
    numeroFormateado = '- ' + numeroFormateado.slice(1)
  }

  // Agregar puntos de millar a la parte entera
  const parteEnteraConPuntos = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // Combinar la parte entera formateada con la parte decimal
  numeroFormateado =
    parteEnteraConPuntos + (parteDecimal ? ',' + parteDecimal : '')

  return numeroFormateado
}

export function redondearDecimalesToLocaleString(numero, decimales = 2) {
  // Redondear el número al número de decimales especificado
  const numeroRedondeado = parseFloat(numero).toFixed(decimales)

  // Convertir el número redondeado a una cadena para trabajar con el formato
  let numeroFormateado = numeroRedondeado.toString()

  // Separar la parte entera de la parte decimal
  const [parteEntera, parteDecimal] = numeroFormateado.split('.')

  // Si no hay parte decimal y se solicitaron decimales, agregar ceros
  if (!parteDecimal && decimales > 0) {
    numeroFormateado += '.' + '0'.repeat(decimales)
  }

  // Reemplazar el punto decimal por la coma decimal (formato español)
  numeroFormateado = numeroFormateado.replace('.', ',')

  // Si el número tiene signo negativo, mover la coma decimal después del signo
  if (numeroFormateado.startsWith('-')) {
    numeroFormateado = '- ' + numeroFormateado.slice(1)
  }

  // Agregar puntos de millar a la parte entera
  const parteEnteraConPuntos = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // Combinar la parte entera formateada con la parte decimal
  numeroFormateado =
    parteEnteraConPuntos + (parteDecimal ? ',' + parteDecimal : '')

  return numeroFormateado.toLocaleString('de-DE')
}
// Redondear el número al número de decimales especific

export function numberWithCommas(x) {
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  console.log('parts', parts.join('.'))

  return parts.join('.')
}
