import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Tooltip } from '@mui/material'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { useLocation, useNavigate } from 'react-router-dom'

const EditBimLinkButton = ({ bimlink }) => {
  const { changeLocation } = useHandleUrl()
  const location = useLocation()
  const navigate = useNavigate()

  const handleOnClick = e => {
    e.stopPropagation()
    navigate(changeLocation(`${location.pathname}/edit-bimlink/${bimlink._id}`))
  }

  return (
    <>
      <Tooltip
        title={<p className="tooltip-text">Editar bimlink</p>}
        placement="top"
      >
        <IconButton onClick={handleOnClick}>
          <EditIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default EditBimLinkButton
