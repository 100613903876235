import { OptionsItem, SelectInput } from 'components/ui/Forms/SelectInput'
import { useGroupBimLink } from 'context/GroupBimLinkContext'
import React from 'react'

const TypeBoqBimLink = () => {
  const { typeBoq, handleTypeBoq } = useGroupBimLink()

  return (
    <SelectInput
      label="Tipo de boq"
      name="type-boq"
      value={typeBoq || null}
      onChange={handleTypeBoq}
    >
      <OptionsItem name="type-boq" value="1">
        1
      </OptionsItem>
      <OptionsItem name="type-boq" value="2">
        2
      </OptionsItem>
      <OptionsItem name="type-boq" value="3">
        3
      </OptionsItem>
    </SelectInput>
  )
}

export default TypeBoqBimLink
