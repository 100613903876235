import {  useState } from 'react'
import {  useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import {
  useGetSingleProjectByUserAdmin,
  useUpdateSingleProjectByUserAdmin,
} from 'lib/react-query/queriesProject'

const useSelectedUsers = inputValue => {
  const [userSelected, setSelectedUsers] = useState([])

  const { idProject } = useParams()
  const { user } = useAuth0()
  const { mutateAsync } = useUpdateSingleProjectByUserAdmin(user.sub, idProject)
  const queryClient = useQueryClient()
  const { data: users } = queryClient.getQueryData(['users-auth0'])
  const { data: usersProject } = useGetSingleProjectByUserAdmin(
    user.sub,
    idProject
  )

  const filterUserNotSelected = allUsers => {
    const {data: currentProject} = usersProject
    const allUsersProject = [...currentProject.user, ...currentProject.users]

    const userNotAlreadyAssignedToProject = allUsers.filter(
      user => !allUsersProject.some(userProject => userProject === user.user_id)
    )

    if (inputValue.length >= 3) {
      return userNotAlreadyAssignedToProject.filter(
        user =>
          (!userSelected.some(
            selectedUser => selectedUser.user_id === user.user_id
          ) &&
            user.name.toLowerCase().includes(inputValue.toLowerCase())) ||
          user.email.toLowerCase().includes(inputValue.toLowerCase())
      )
    } else {
      return userNotAlreadyAssignedToProject.filter(
        user =>
          !userSelected.some(
            selectedUser => selectedUser.user_id === user.user_id
          )
      )
    }
  }

  const addUser = user => {
    setSelectedUsers(prevState => [...prevState, { ...user, rol: 'Usuario' }])
  }

  const handleDeleteUser = user => {
    setSelectedUsers(prevState =>
      prevState.filter(selectedUser => selectedUser.user_id !== user.user_id)
    )
  }

  const handleUpdateUser = (user, newValue) => {
    setSelectedUsers(prevState =>
      prevState.map(selectedUser =>
        selectedUser.user_id === user.user_id
          ? { ...selectedUser, ...newValue }
          : selectedUser
      )
    )
  }

  const handleAddUsersToProject = () => {
    const {data: currentProject} = usersProject

    let usuarios = [...currentProject?.users] || []
    let administradores = [...currentProject?.user] || []

    const usersToAdd = userSelected
      .filter(user => user.rol === 'Usuario')
      .map(user => user.user_id)
    const usersToAddAdmin = userSelected
      .filter(user => user.rol === 'Administrador')
      .map(user => user.user_id)

    usuarios = [...usuarios, ...usersToAdd]
    administradores = [...administradores, ...usersToAddAdmin]

    mutateAsync({ body: { user: administradores, users: usuarios } })
  }

  return {
    userSelected,
    addUser,
    handleDeleteUser,
    handleUpdateUser,
    handleAddUsersToProject: handleAddUsersToProject,
    users: filterUserNotSelected(users || []),
  }
}

export default useSelectedUsers
