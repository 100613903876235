import { TextField } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { createCelula, getAllActivitiesNotInProject } from '../../../api/jitApi'
import ActivitiesList from './ActivitiesList'
import ActivitiesCheckbox from './ActivitiesCheckbox'
import { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import toast from 'react-hot-toast'
import { useHandleUrl } from '../../../hooks/useHandleUrl'
import TextInput from 'components/ui/Forms/TextInput'

const NewCelula = () => {
  const queryClient = useQueryClient()
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const navigate = useNavigate()
  const { changeLocation } = useHandleUrl()
  const [checkedActivities, setCheckedActivities] = useState([])
  const { idProject } = useParams()
  // TODO: Get all activities not assigned to a project
  const { data, isLoading, error } = useQuery(
    ['activities-not-project', idProject],
    () => getAllActivitiesNotInProject(idProject)
  )

  const handleCheckboxChange = event => {
    const { value } = event.target

    const isSelected = checkedActivities.includes(value)

    if (isSelected) {
      setCheckedActivities(prev => prev.filter(id => id !== value))
    } else {
      setCheckedActivities(prev => [...prev, value])
    }
  }

  const handleOnClick = async () => {
    if (!code || !name || !checkedActivities.length) {
      toast.error('Todos los campos tienen que estar rellenos')
      return
    }
    const body = {
      code,
      name,
      activities: checkedActivities,
    }

    try {
      await createCelula(idProject, body)
      setCode('')
      setName('')
      setCheckedActivities([])
      const queryKeysToInvalidate = queryClient
        .getQueryCache()
        .findAll('celulas-import')

      const queryToInvalidate = []

      queryKeysToInvalidate.forEach(async key => {
        if (key.queryKey[0] == 'celulas-import') {
          queryToInvalidate.push(
            queryClient.invalidateQueries([key.queryKey[0]])
          )
        }
      })

      await Promise.all(queryToInvalidate)
      toast.success('Nueva célula creada')
      navigate(changeLocation(`/viewer/${idProject}/fabrica/gestionar-celulas`))
    } catch (e) {
      toast.error('Hubo un error al crear la célula. Vuelva a intentarlo')
    }
  }

  if (!data || isLoading) return <p>Cargando...</p>
  return (
    <div className="new-celula-wrapper">
      <div className="new-celula-form">
        <TextInput
          label="Código"
          name="code"
          placeholder="Escribe aquí el código"
          onChange={e => setCode(e.target.value)}
          value={code}
          containerStyles={{ width: '400px' }}
        />
        <TextInput
          label="Nombre"
          name="name"
          placeholder="Escribe aquí el nombre"
          onChange={e => setName(e.target.value)}
          value={name}
          containerStyles={{ width: '400px' }}
        />

        <ActivitiesCheckbox
          activities={data.data}
          checkedActivities={checkedActivities}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>

      <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
        <div>
          <Button
            className="button-new-partida button-new-partida--white"
            color="none"
            onClick={() =>
              navigate(
                changeLocation(`/viewer/${idProject}/fabrica/gestionar-celulas`)
              )
            }
          >
            Cancelar
          </Button>
        </div>
        <div>
          <Button
            className="button-new-partida"
            color="none"
            onClick={handleOnClick}
          >
            Crear nueva célula
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewCelula
