import EditBimLinkButton from './EditBimLinkButton'
import DeleteBimLinkButton from './DeleteBimLinkButton'

const TableRowBimLink = ({ bimlink }) => {
  return (
    <tr className="fabrica-table-header">
      <td>{bimlink.code}</td>
      <td className="ta-right bimlink-table-row-container">
        <div className="bimlink-table-row-buttons-container">
          <EditBimLinkButton bimlink={bimlink} />
          <DeleteBimLinkButton />
        </div>
      </td>
    </tr>
  )
}

export default TableRowBimLink
