import { useContext, useEffect } from 'react'
import ButtonsElementContainer from './MainLayout/Partidas/ButtonsElementContainer'
import { ViewerContext } from '../../context/ViewerContext'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

const Viewer = () => {
  const queryClient = useQueryClient()
  const { externalIdsMapping } = useContext(ViewerContext)
  const { idProject } = useParams()

  // const data = queryClient.getQueryData(['project', idProject])

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          padding: '1rem 2rem',
          position: 'absolute',
          zIndex: '1000',
          gap: '3rem',
        }}
      ></div>

      <div id="forgeViewer" className="forge-viewer" />
    </div>
  )
}

export default Viewer
