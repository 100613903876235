import { useProject } from 'context/ProyectoContext'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useVersionModel = () => {
  let [searchParams] = useSearchParams()
  const { versions } = useProject()
  const currentVersion = useMemo(() => {
    return (
      searchParams.get('version') ||
      Math.max(...versions.map(item => Number(item.attributes.versionNumber)))
    )
  }, [searchParams.get('version'), versions])

  return { currentVersion, versions }
}
