import { ActividadesInput } from './ArticuloBimLink/ActividadesInput'
import ArticulosInput from './ArticuloBimLink/ArticulosInput'
import FormulaInput from './ArticuloBimLink/FormulaInput'
import { CustomButton } from 'components/ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { useArticuloBimLink } from 'context/ArticuloBimLinkContext'
import { Tooltip } from 'react-tooltip'

const NewArticuloBimlink = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const {
    createArticulo,
    selectedActividad,
    selectedArticulo,
    formula,
    isEditArticuloMode,
  } = useArticuloBimLink()

  const handleBack = () => {
    if (isEditArticuloMode) {
      navigate(changeLocation(location.pathname.split('/edit-articulo')[0]))
    } else {
      navigate(changeLocation(location.pathname.split('/new-articulo')[0]))
    }
  }

  const handleCreateArticulo = () => {
    createArticulo()
    handleBack()
  }
  return (
    <div className="new-bimlink-body-container">
      <h1 className="new-bimlink-title">
        {isEditArticuloMode ? 'Editar artículo' : 'Crear artículo'}
      </h1>
      <div className="new-bimlink-form">
        <ActividadesInput />
        <ArticulosInput />
        <FormulaInput />
      </div>
      <div className="new-bimlink-footer-buttons">
        <CustomButton type="secondary" onClick={handleBack}>
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={handleCreateArticulo}
          disabled={!selectedActividad || !selectedArticulo || !formula}
          className="create-articulo-button"
        >
          {isEditArticuloMode ? 'Editar artículo' : 'Crear artículo'}
        </CustomButton>
        <Tooltip
          anchorSelect=".create-articulo-button"
          style={{
            fontSize: '9px',
            display:
              !selectedActividad || !selectedArticulo || !formula
                ? 'block'
                : 'none',
          }}
        >
          No puede haber ningún campo vacio.
        </Tooltip>
      </div>
    </div>
  )
}

export default NewArticuloBimlink
