import Skeleton from 'react-loading-skeleton'

const ProyectosArraySkeleton = ({ length = 8 }) => {
  return (
    <div className="proyectos-container">
      {[
        ...new Array(length)
          .fill(0)
          .map(() => <Skeleton count={1} height="300px" />),
      ]}
    </div>
  )
}

export default ProyectosArraySkeleton
