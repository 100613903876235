import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useGetSegments from '../hooks/useGetSegments'
import { useProject } from './ProyectoContext'

export const SidebarContext = createContext()

export const SidebarProvider = ({ children }) => {
  const [currentSidebar, setCurrentSidebar] = useState(null)
  const getSegments = useGetSegments({ asOfValue: currentSidebar })
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const [openSidebar, setOpenSidebar] = useState(false)
  const [allSegments, setAllSegments] = useState([])
  const [moduloName, setModuloName] = useState(null)
  const [currentProject, setCurrentProject] = useState(null)
  const [partidasStatus, setPartidasStatus] = useState(null)
  const { currentProject: project } = useProject()
  


  useEffect(() => {
    // setLoading(true)
    const pathSegments = location.pathname.split('/')
    if (pathSegments.length > 2) {
      setCurrentSidebar(pathSegments[3])
    }
  }, [location.pathname])

  useEffect(() => {
    setAllSegments(getSegments)
  }, [getSegments])

  useEffect(() => {
    setLoading(true)

    setCurrentProject(project?.proyecto)
    setLoading(false)
  }, [project])

  useEffect(() => {
    if (currentSidebar) {
      setOpenSidebar(true)
    }
  }, [currentSidebar])

  const data = {
    openSidebar, // se usa
    setOpenSidebar, // se usa
    allSegments, // se usa
    setAllSegments, // se usa
    loading, // se usa
    currentProject, // se usa
    currentSidebar, // se usa
    moduloName, // se usa
    setModuloName, // se usa
    partidasStatus, // se usa
  }

  return (
    <SidebarContext.Provider value={data}>{children}</SidebarContext.Provider>
  )
}
