export const UnderConstructionPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '80%',
        gap: '3rem',
      }}
    >
      <>
        <div class="animation">
          <div class="one spin-one"></div>
          <div class="two spin-two"></div>
          <div class="three spin-one"></div>
        </div>
        <h1 class="uppercase" style={{ fontSize: '3rem' }}>
          En construcción...
        </h1>
      </>
    </div>
  )
}
