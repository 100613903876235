import { NavLink } from 'react-router-dom'

const Tab = ({ items }) => {
  return (
    <>
      <div className="tabs-container">
        {items.map(({ name, to }) => (
          <NavLink className="tabs-item" to={to}>
            {name}
          </NavLink>
        ))}
      </div>
      <div className="tabs-line-end" />
    </>
  )
}

export default Tab
