import { useGroupBimLink } from 'context/GroupBimLinkContext'
import { useHandleUrl } from 'hooks/useHandleUrl'
import NewArticuloPage from 'pages/NewArticuloPage'
import React from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

const EditArticuloWrapper = () => {
  const { articuloUuid } = useParams()
  const { articulos } = useGroupBimLink()
  const articuloExists = articulos.find(
    articulo => articulo.uuid == articuloUuid
  )

  const { changeLocation } = useHandleUrl()
  const location = useLocation()

  if (!articuloExists)
    <Navigate
      to={changeLocation(location.pathname.split('/edit-articulo')[0])}
    />

  return <NewArticuloPage articulo={articuloExists} />
}

export default EditArticuloWrapper
