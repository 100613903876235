import React, { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTotalMo } from '../../../hooks/useTotalMo'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getModuloByProjectAndVersion } from '../../../api/estructuraCostes/modulosJitApi'
import { ordenarPorPropiedad } from '../../../utils/formatText'
import FabricaRow from './FabricaRow'
import { redondearDecimalesToLocaleString } from '../../../utils/roundNumber'
import { ArticulosContext } from 'context/ArticulosContext'
import { useVersionModel } from 'hooks/useVersionModel'

const FabricaMatriculasTable = () => {
  console.log('estoy entrando en FabricaMatriculasTable')
  const { idProject } = useParams()
  const { currentVersion } = useVersionModel()
  const queryClient = useQueryClient()

  const { totalMoByVinculo, totalMo } = useTotalMo()
  const modulos = queryClient.getQueryData([
    'modulos-articulos',
    idProject,
    currentVersion,
  ])

  const totalImportProject = useMemo(() => {
    if (!modulos) return 0
    return modulos.modulos.reduce((acc, modulo) => {
      return acc + Number(modulo.totalImport)
    }, 0)
  }, [modulos])

  if (!modulos) return
  return (
    <div
      className="fabrica-matriculas-table-container"
      style={{ flex: 1, overflowY: 'auto' }}
    >
      <table>
        <thead>
          <tr>
            <th className="ta-left">Matrícula</th>
            <th className="ta-right">Importe</th>
          </tr>
        </thead>
        <tbody>
          {modulos.modulos
            .sort(ordenarPorPropiedad('code'))
            .map((modulo, i) => (
              <FabricaRow
                modulo={modulo}
                key={`${modulo.externalid}-${i}`}
                mo={totalMoByVinculo}
              />
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td className="ta-right">
              {redondearDecimalesToLocaleString(
                totalImportProject + Number(totalMo)
              )}
              €
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default FabricaMatriculasTable
