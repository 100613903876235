import { CustomButton } from 'components/ui'
import TextInput from 'components/ui/Forms/TextInput'
import { useFormik } from 'formik'
import {
  useCreateActividad,
  useUpdateActividad,
} from 'lib/react-query/queriesActividad'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

const FormNewActividad = ({ data = {} }) => {
  const { mutateAsync: createActividad } = useCreateActividad()
  const { mutateAsync: updateActividad } = useUpdateActividad()
  // const { mutateAsync: createArticulo } = useCreateArticulo()
  // const { mutateAsync: updateArticulo } = useUpdateSingleArticulo()
  const { idActividad } = useParams()
  const isEditMode = idActividad ? true : false
  const navigate = useNavigate()
  const { handleChange, values, handleSubmit, errors } = useFormik({
    initialValues: {
      code: data.code || '',
      name: data.name || '',
    },
    onSubmit: values => {
      isEditMode
        ? updateActividad({ id: idActividad, ...values })
        : createActividad(values)
    },

    validationSchema: Yup.object({
      code: Yup.string().required('El código es requerido'),
      name: Yup.string().required('El nombre es requerido'),
    }),
  })
  const { code, name } = values
  return (
    <form
      className="edit-articulo-form edit-articulo-form--admin"
      noValidate
      onSubmit={handleSubmit}
    >
      <TextInput
        label="Código"
        name="code"
        value={code}
        onChange={handleChange}
      />
      {errors.code && (
        <p className="error-form error-form-articulo">{errors.code}</p>
      )}

      <TextInput
        label="Nombre"
        name="name"
        value={name}
        onChange={handleChange}
      />

      {errors.name && (
        <p className="error-form error-form-articulo">{errors.name}</p>
      )}

      <div className="buttons-container-edit-form ">
        <CustomButton type="secondary" onClick={() => navigate('/admin/articulos')}>
          Cancelar
        </CustomButton>
        <CustomButton type="submit" disabled={Object.keys(errors).length !== 0}>
          {isEditMode ? 'Actualizar' : 'Crear'}
        </CustomButton>
      </div>
    </form>
  )
}

export default FormNewActividad
