export const useCustomPagination = () => {
  const obtenerNumeros = (sort, valor, cantidad, lastPage) => {
    let array = []
    let incremento = sort === 'anteriores' ? -1 : 1

    if (sort == 'anteriores') {
      for (
        var i = valor + incremento;
        Math.abs(valor - i) <= cantidad;
        i += incremento
      ) {
        if (i >= 1) {
          array.push(i)
        }
      }
    } else {
      for (
        var i = valor + incremento;
        Math.abs(valor - i) <= cantidad;
        i += incremento
      ) {
        if (i <= lastPage) {
          array.push(i)
        }
      }
    }

    return array.sort(function (a, b) {
      return a - b
    })
  }

  return { obtenerNumeros }
}
