import axios from 'axios'

const getAllTiposByProyecto = async (id, params = {}) => {
  try {
    const { data } = await axios.get(`/api/v1/tipobimlink/proyecto/${id}`, {
      params,
    })
    return data?.data
  } catch (e) {
    console.error(e)
  }
}

const updateTipo = async (id, body) => {
  try {
    const { data } = await axios.patch(`/api/v1/tipobimlink/${id}`, body)
    return data?.data
  } catch (e) {
    console.error(e)
  }
}

export { getAllTiposByProyecto, updateTipo }
