import NotProjects from 'assets/svg/NotProjects'
import React from 'react'

const NotProjectsAvailable = () => {
  return (
    <div className="not-projects-container">
      <NotProjects height="300px" />
      <h1 className="not-projects-text">No hay proyectos disponibles</h1>
    </div>
  )
}

export default NotProjectsAvailable
