import { CustomButton } from 'components/ui'
import { useUpdateMatriculas } from 'lib/react-query/queriesRvtLinks'
import React from 'react'
import toast from 'react-hot-toast'
import { getCurrentModelVersion } from 'utils/launchViewer'

const ButtonUpdateMatriculas = () => {
  const { mutateAsync: mutateUpdateMatriculas } = useUpdateMatriculas()
  const handleOnClick = () => {
    try {
      const version = getCurrentModelVersion()
      mutateUpdateMatriculas(version)
    } catch (e) {
      toast.error(
        'El modelo aún no ha terminado de cargarse. Por favor, espere y vuelva a intentarlo.'
      )
    }
  }
  return (
    <div className="button-create-matriculas-container">
      <CustomButton
        className="button-create-matriculas"
        onClick={handleOnClick}
      >
        Crear / Actualizar matrículas
      </CustomButton>
    </div>
  )
}

export default ButtonUpdateMatriculas
