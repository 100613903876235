import React, { createContext, useEffect } from 'react'

export const FiltersPartidasContext = createContext()

export const FiltersPartidaProvider = ({ children }) => {
  const [filtersPartidas, setFiltersPartidas] = React.useState({
    search: null,
    sortBy: ' ',
    limit: 6,
    minPrice: null,
    maxPrice: null,
    version: null,
    page: 1,
    capitulos: null,
    version: ' ',
  })

  useEffect(() => {
    setFiltersPartidas(prevState => ({
      ...prevState,
      page: 1,
    }))
  }, [
    filtersPartidas.capitulos,
    filtersPartidas.search,
    filtersPartidas.sortBy,
    filtersPartidas.limit,
    filtersPartidas.minPrice,
    filtersPartidas.maxPrice,
    filtersPartidas.version,
  ])

  return (
    <FiltersPartidasContext.Provider
      value={{ filtersPartidas, setFiltersPartidas }}
    >
      {children}
    </FiltersPartidasContext.Provider>
  )
}
