import { CustomButton } from 'components/ui'
import Modal from 'components/ui/Modal/Modal'

const ModalUpdateTipoBimlink = ({ handleOnClose, handleOnClick }) => {
  return (
    <Modal
      modalClass="modal modal-update-tipos"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div className="modal-link-bimlink-to-tipo-container">
        <h2>Actualizar tipo</h2>
        <p className="modal-update-tipo-text">
          Los elementos guardados para este tipo son diferentes de los de la
          <b> última versión</b> del modelo. ¿Deseas actualizarlos?
        </p>
        <div className="modal-link-bimlink-to-tipo-buttons">
          <CustomButton type="secondary" onClick={handleOnClose}>
            Cancelar
          </CustomButton>
          <CustomButton onClick={handleOnClick}>Actualizar</CustomButton>
        </div>
      </div>
    </Modal>
  )
}

export default ModalUpdateTipoBimlink
