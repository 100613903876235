import React, { useRef } from 'react'
import TextInput from './TextInput'
import SearchOptionsGroup from 'components/Bimlink/ArticuloBimLink/SearchOptionsGroup'
import { cls } from 'lib/classnames/cn'

const SelectDropdown = ({
  showOptions,
  setShowOptions,
  selectValue,
  label,
  name,
  onChangeValue,
  children,
  containerClassName,
  ...props
}) => {
  const handleMenuOpen = () => {
    setShowOptions(!showOptions)
  }

  const rootEl = useRef()
  return (
    <div
      style={{ position: 'relative' }}
      ref={rootEl}
      className={cls(containerClassName)}
    >
      <TextInput
        label={label || 'Ejemplo'}
        name={name || 'ejemplo'}
        value={selectValue}
        onClick={handleMenuOpen}
        onChange={e => onChangeValue && onChangeValue(e.target.value)}
        autoComplete="off"
        readonly
        {...props}
      />
      {showOptions && (
        <SearchOptionsGroup width={rootEl?.current}>
          {children}
        </SearchOptionsGroup>
      )}
    </div>
  )
}

export default SelectDropdown
