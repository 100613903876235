import NewRuleBimlink from 'components/Bimlink/NewRuleBimlink'
import { useGroupBimLink } from 'context/GroupBimLinkContext'
import { RulesBimLinkProvider } from 'context/RulesBimLinkContext'
import { useHandleUrl } from 'hooks/useHandleUrl'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const NewRulePage = () => {
  const { code, name } = useGroupBimLink()
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  useEffect(() => {
    if (!code || !name) {
      return navigate(
        changeLocation(location.pathname.replace('/new-rule', ''))
      )
    }
  }, [])
  return (
    <RulesBimLinkProvider>
      <NewRuleBimlink />
    </RulesBimLinkProvider>
  )
}

export default NewRulePage
