import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ButtonNewConfigMO from './ButtonNewConfigMO'
import ButtonConfigMO from './ButtonConfigMO'

const TotalMo = () => {
  const { idProject } = useParams()
  const { data, isLoading } = useQuery(['project', idProject], null)

  if (!data || isLoading) return null
  if (!data?.data?.proyecto?.manoDeObraRendimiento) return <ButtonNewConfigMO />
  return <ButtonConfigMO />
}

export default TotalMo
