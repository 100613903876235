import React from 'react'
import { useParams } from 'react-router-dom'
import WrapperPage from '../components/Wrapper/WrapperPage'
import Header from '../components/Header'
import { useAuth0 } from '@auth0/auth0-react'
import InviteUser from '../components/project/InviteUser'

const Collaborators = () => {
  const { user } = useAuth0()
  const { idProject } = useParams()

  return (
    <WrapperPage>
      <InviteUser project={idProject} />
    </WrapperPage>
  )
}

export default Collaborators
