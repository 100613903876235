import { CustomButton } from 'components/ui'
import SelectDropdown from 'components/ui/Forms/SelectDropdown'
import TextInput from 'components/ui/Forms/TextInput'
import { useFormik } from 'formik'
import {
  useCreateArticulo,
  useUpdateSingleArticulo,
} from 'lib/react-query/queriesArticulos'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

const UNITS = ['u', 'm', 'm2', 'm3', 'kg']

const FormNewArticulo = ({ data = {} }) => {
  const { mutateAsync: createArticulo } = useCreateArticulo()
  const { mutateAsync: updateArticulo } = useUpdateSingleArticulo()
  const [showOptions, setShowOptions] = useState()
  const { idArticulo } = useParams()
  const isEditMode = idArticulo ? true : false
  const navigate = useNavigate()
  const onClickItemUnit = value => {
    setFieldValue('unit', value)
    setShowOptions(false)
  }
  const { handleChange, values, handleSubmit, errors, setFieldValue } =
    useFormik({
      initialValues: {
        code: data.code || '',
        name: data.name || '',
        price: data.price || 0,
        unit: data.unit || UNITS[0],
      },
      onSubmit: values => {
        isEditMode
          ? updateArticulo({ id: idArticulo, ...values })
          : createArticulo(values)
      },

      validationSchema: Yup.object({
        code: Yup.string().required('El código es requerido'),
        name: Yup.string().required('El nombre es requerido'),
        price: Yup.number()
          .min(0, 'El precio debe ser igual o mayor que 0')
          .required('El precio es requerido'),
        unit: Yup.string().required('La unidad es requerida'),
      }),
    })
  const { code, name, unit, price } = values
  return (
    <form
      className="edit-articulo-form edit-articulo-form--admin"
      noValidate
      onSubmit={handleSubmit}
    >
      <TextInput
        label="Código"
        name="code"
        value={code}
        onChange={handleChange}
      />
      {errors.code && (
        <p className="error-form error-form-articulo">{errors.code}</p>
      )}

      <TextInput
        label="Nombre"
        name="name"
        value={name}
        onChange={handleChange}
      />

      {errors.name && (
        <p className="error-form error-form-articulo">{errors.name}</p>
      )}
      <TextInput
        label="Precio"
        name="price"
        value={price}
        type="number"
        onChange={handleChange}
      />
      {errors.price && (
        <p className="error-form error-form-articulo">{errors.price}</p>
      )}

      <SelectDropdown
        selectValue={unit}
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        label="Unidad"
        name="unit"
      >
        {UNITS.map(item => (
          <div
            onClick={() => onClickItemUnit(item)}
            className="custom-dropdown-list-item"
          >
            {item}
          </div>
        ))}
      </SelectDropdown>
      {errors.unit && (
        <p className="error-form error-form-articulo">{errors.unit}</p>
      )}
      <div className="buttons-container-edit-form ">
        <CustomButton type="secondary" onClick={() => navigate('/admin/articulos')}>
          Cancelar
        </CustomButton>
        <CustomButton type="submit" disabled={Object.keys(errors).length !== 0}>
          {isEditMode ? 'Actualizar' : 'Crear'}
        </CustomButton>
      </div>
    </form>
  )
}

export default FormNewArticulo
