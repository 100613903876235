import { useQueryClient } from '@tanstack/react-query'
import { getAllExternalIdsByProjectAndVersion } from 'api/estructuraCostes/boqJitApi'
import { NavPresupuestoContext } from 'context'
import { ViewerContext } from 'context/ViewerContext'
import { useContext } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { baseColors } from 'utils/colors'
import { PARAM_BIMLINK } from 'utils/constants'
import {
  findLeafNodes,
  getBulkPropertiesAsync,
} from 'utils/getPropertiesViewer'
import { getCurrentModelVersion, viewer } from 'utils/launchViewer'

export const useFilterElementsInModel = () => {
  const { resetModulesFilter, setCurrentLevel, modulesFilter } = useContext(
    NavPresupuestoContext
  )
  const queryClient = useQueryClient()
  const { idProject } = useParams()
  const { externalIdsMapping } = useContext(ViewerContext)

  const getElementsWithParamBimLink = async () => {
    const dbIds = await findLeafNodes(viewer)
    return await getBulkPropertiesAsync(viewer, dbIds, [PARAM_BIMLINK])
  }

  const handleReset = async () => {
    if (modulesFilter.length === 0) {
      viewer.clearThemingColors()
      return
    }
    resetModulesFilter()
    setCurrentLevel(null)
    viewer.clearThemingColors()
  }

  const showCodeElements = async (showToast = false, filterViewer = false) => {
    await handleReset()
    const values = await getElementsWithParamBimLink()
    const filterValues = values
      .filter(value => value.properties[0].displayValue !== '')
      .map(({ dbId }) => dbId)
    filterViewer && viewer.isolate(filterValues)
    showToast && toast.success(`${filterValues.length} elementos codificados`)
    return filterValues.length
  }

  const showNoCodeElements = async (
    showToast = false,
    filterViewer = false
  ) => {
    await handleReset()
    const values = await getElementsWithParamBimLink()
    const filterValues = values
      .filter(value => value.properties[0].displayValue === '')
      .map(({ dbId }) => dbId)
    filterViewer && viewer.isolate(filterValues)
    showToast &&
      toast.success(`${filterValues.length} elementos no codificados`)
    return filterValues.length
  }

  const showMeasuredElements = async (
    showToast = false,
    filterViewer = false
  ) => {
    await handleReset()
    // * Sacar los elementos medidos por idProject y version
    const version = getCurrentModelVersion()

    const { data, length } = await queryClient.fetchQuery({
      queryKey: ['externalids-medidos', idProject, version],
      queryFn: () => getAllExternalIdsByProjectAndVersion(idProject, version),
    })

    if (length == 0) return toast.success('0 elementos medidos')
    const dbIds = data.map(externalId => externalIdsMapping[externalId])
    filterViewer && viewer.isolate(dbIds)
    showToast && toast.success(`${length} elementos medidos`)
    return length
  }

  const showNoMeasuredElements = async (
    showToast = false,
    filterViewer = false
  ) => {
    await handleReset()
    // * Los elementos no medidos se sacan con los que han sido codificados. Elementos no medidos = codificados - medidos
    // TODO: Sacar los GUIDS de los que están codificados
    const values = await getElementsWithParamBimLink()
    const filterValues = values
      .filter(value => value.properties[0].displayValue !== '')
      .map(({ dbId }) => dbId)
    // TODO: Compararlos con los que no están medidos
    const version = getCurrentModelVersion()

    const { data } = await queryClient.fetchQuery({
      queryKey: ['externalids-medidos', idProject, version],
      queryFn: () => getAllExternalIdsByProjectAndVersion(idProject, version),
    })

    //Guids medidos
    const dbIdsArray = data.map(guid => externalIdsMapping[guid])
    console.log('dbIdsArray', dbIdsArray)

    //Guids no medidos
    const noMeasuredDbIds = filterValues.filter(
      dbId => !dbIdsArray.includes(dbId)
    )

    if (filterViewer) {
      noMeasuredDbIds.forEach(dbId =>
        viewer.setThemingColor(dbId, baseColors.red)
      )
      filterViewer && viewer.isolate(noMeasuredDbIds)
    }

    if (showToast) {
      if (noMeasuredDbIds.length == 0)
        return toast.success('Todos los elementos codificados han sido medidos')
      toast.success(
        `${noMeasuredDbIds.length} elementos codificados han sido no medidos`
      )
    }
    return noMeasuredDbIds.length
  }

  return {
    showCodeElements,
    showNoCodeElements,
    showMeasuredElements,
    showNoMeasuredElements,
    handleReset,
  }
}
