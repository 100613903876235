import { createContext, useState } from 'react'

export const CapitulosTreeDashboardContext = createContext()

export const CapitulosTreeDashboardProvider = ({ children }) => {
  const [header, setHeader] = useState([])
  const [capituloSelectedQuery, setCapituloSelectedQuery] = useState({})

  const data = {
    header, // se usa
    setHeader, // se usa
    capituloSelectedQuery, // se usa
    setCapituloSelectedQuery, // se usa
  }

  return (
    <CapitulosTreeDashboardContext.Provider value={data}>
      {children}
    </CapitulosTreeDashboardContext.Provider>
  )
}
