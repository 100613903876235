import { useQuery } from '@tanstack/react-query'
import { getTipos } from '../../services/api/apiTipos'

export const useTipos = (idProject, params = {}) => {
  const {
    isLoading,
    data: tipos,
    error,
  } = useQuery({
    queryKey: [
      'tipos',
      idProject,
      {
        version: params?.version || '',
        page: params?.page || 1,
        search: params?.search || '',
        filter: params?.filter || '',
        visible: params?.visible,
        dbIds: params?.dbIds || null,
      },
    ],
    queryFn: () => getTipos(idProject, params),
  })

  return { isLoading, tipos, error }
}
