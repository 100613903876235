import React from 'react'
import { ordenarPorPropiedad } from '../../utils/formatText'
import ActivityTableRow from './ActivityTableRow'

const ActivityContainerTableRow = ({ activities, showArticulos = true }) => {
  return (
    <>
      {activities.sort(ordenarPorPropiedad('code')).map(activity => (
        <ActivityTableRow
          activity={activity}
          key={activity?._id}
          showArticulos={showArticulos}
        />
      ))}
    </>
  )
}

export default ActivityContainerTableRow
