import React from 'react'
import cls from 'classnames'

export const OptionsItem = ({ containerclass, children, ...props }) => {
  return (
    <option
      {...props}
      style={{ height: '1rem' }}
      className={cls('option-input-custom', containerclass)}
    >
      {children}
    </option>
  )
}

export const SelectInput = ({
  label,
  containerStyles,
  containerclass,
  adornment,
  children,
  ...props
}) => {
  return (
    <div className={cls('select-input-custom-container', containerclass)}>
      <label>{label}</label>
      <select {...props} className="select-input-custom">
        {children}
      </select>
    </div>
  )
}
