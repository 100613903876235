import React, { useContext } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import ButtonsElementContainer from './MainLayout/Partidas/ButtonsElementContainer'
import { useParams } from 'react-router-dom'
import { ViewerContext } from 'context/ViewerContext'
import SelectVersion from 'components/SelectVersion/SelectVersion'

const NavViewer = () => {
  const { externalIdsMapping } = useContext(ViewerContext)

  return (
    <div className="viewer-nav-container">
      <SelectVersion />
      {externalIdsMapping && <ButtonsElementContainer />}
    </div>
  )
}

export default NavViewer
