import React, { useEffect, useRef, useState } from 'react'
import UserItemBadgeRole from '../UserItemProyecto/UserItemBadgeRole'
import SearchOptionsGroup from 'components/Bimlink/ArticuloBimLink/SearchOptionsGroup'
import { useAddUsersToProject } from 'context/AddUsersToProjectContext'

const ROL_OPTIONS = [
  {
    value: 'Administrador',
  },
  {
    value: 'Usuario',
  },
]

const ModifyRolUser = ({ user, role }) => {
  const [showOptions, setShowOptions] = useState(false)
  const rootEl = useRef()
  const { handleUpdateUser } = useAddUsersToProject()

  const handleOnClick = () => {
    setShowOptions(prevState => !prevState)
  }

  const handleChangeRole = value => {
    handleUpdateUser(user, { rol: value })
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (rootEl.current && !rootEl.current.contains(event.target)) {
        setShowOptions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      className="badge-rol-user-add-container"
      ref={rootEl}
      onClick={handleOnClick}
    >
      <div>
        <UserItemBadgeRole rol={role} icon={true} />
      </div>
      {showOptions && (
        <SearchOptionsGroup width={rootEl?.current}>
          {ROL_OPTIONS.map((option, index) => (
            <div
              key={index}
              className="badge-rol-user-item"
              onClick={() => handleChangeRole(option.value)}
            >
              {option.value}
            </div>
          ))}
        </SearchOptionsGroup>
      )}
    </div>
  )
}

export default ModifyRolUser
