import axios from 'axios'

const getAllActivities = async query => {
  try {
    const { data } = await axios.get(`/api/actividades`, {
      params: query || {},
    })
    return data
  } catch (e) {}
}

const getAllActivitiesNotInProject = async idProject => {
  try {
    const { data } = await axios.get(`/api/actividades/project/${idProject}`)
    return data
  } catch (e) {}
}

const createActividad = async body => {
  try {
    const { data } = await axios.post(`/api/actividades`, body)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const getActivity = async id => {
  try {
    const { data } = await axios.get(`/api/actividades/${id}`)
    return data
  } catch (e) {}
}

const updateActivity = async (id, body) => {
  try {
    const { data } = await axios.patch(`/api/actividades/${id}`, body)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export {
  getAllActivitiesNotInProject,
  getAllActivities,
  createActividad,
  getActivity,
  updateActivity,
}
