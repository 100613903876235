import { CustomButton } from 'components/ui'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from 'hooks/useHandleUrl'

const HeaderBimLinksButtons = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const handleOnClick = () => {
    navigate(changeLocation(`${location.pathname}/new-bimlink`))
  }
  return (
    <>
      <div>
        <CustomButton
          onClick={handleOnClick}
          type="secondary"
          //   disabled={!modulosAreLoaded}
          className="btn-center"
        >
          <AddIcon />
          Crear BimLink
        </CustomButton>
      </div>
    </>
  )
}

export default HeaderBimLinksButtons
