import { CustomButton } from 'components/ui'
import { useAddUsersToProject } from 'context/AddUsersToProjectContext'
import React from 'react'

const ButtonsAddUser = ({ handleOnClose }) => {
  const { userSelected, handleAddUsersToProject } = useAddUsersToProject()

  const onClickAddUsers = () => {
    handleAddUsersToProject()
    handleOnClose()
  }
  return (
    <div className="buttons-container-add-user">
      <CustomButton className="btn-l" type="light" onClick={handleOnClose}>
        Cancelar
      </CustomButton>
      <CustomButton
        className="btn-l"
        disabled={userSelected.length === 0}
        onClick={onClickAddUsers}
      >
        Añadir usuarios
      </CustomButton>
    </div>
  )
}

export default ButtonsAddUser
