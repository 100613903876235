import { cls } from 'lib/classnames/cn'
import React from 'react'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { es } from 'date-fns/locale'

const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      locale={es}
      className={`calendar-container ${className}`}
      classNames={{
        months: 'calendar-months',
        month: 'calendar-month',
        caption: 'calendar-caption',
        caption_label: 'calendar-caption_label',
        nav: 'calendar-nav',
        nav_button: 'btn btn-secondary btn-secondary--calendar',
        nav_button_previous: 'calendar-nav_button_previous',
        nav_button_next: 'calendar-nav_button_next',
        table: 'calendar-table',
        head_row: 'calendar-head_row',
        head_cell: 'calendar-head_cell',
        row: 'calendar-row',
        cell: 'calendar-cell',
        day: 'calendar-cell',
        day_range_end: 'day-range-end',
        day_selected: 'day-selected',
        day_today: 'day-today',
        day_outside: 'day-outside',
        day_disabled: 'day-disabled',
        day_range_middle: 'day-range-middle',
        day_hidden: 'day-hidden',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => (
          <ion-icon
            name="chevron-back-outline"
            style={{ width: '1.5rem', height: '1.5rem', color: 'black' }}
          ></ion-icon>
        ),
        IconRight: ({ ...props }) => (
          <ion-icon
            name="chevron-forward-outline"
            style={{ width: '1.5rem', height: '1.5rem', color: 'black' }}
          ></ion-icon>
        ),
      }}
      {...props}
    />
  )
}

export default Calendar
