import { createContext, useContext, useEffect, useState } from 'react'
import { ViewerContext } from './ViewerContext'
import { viewer } from '../utils/launchViewer'

export const ModuloContext = createContext()

// A todas las células y actividades le añado la propiedad isSelected para luego filtrar en el visor
const handleInitialStateCelulas = celulas => {
  const newCelulas = celulas.map(celula => {
    return {
      ...celula,
      isSelected: false,
      activities: celula.activities.map(activity => {
        return { ...activity, isSelected: false }
      }),
    }
  })
  return newCelulas
}

export const ModuloProvider = ({ modulo, children }) => {
  const { externalIdsMapping } = useContext(ViewerContext)
  const [modulosSelected, setModulosSelected] = useState(
    handleInitialStateCelulas(handleInitialStateCelulas(modulo))
  )

  const removeDbIdSelected = (id, isCelula) => {
    if (isCelula) {
      const newModulosSelected = modulosSelected.map(modulo => {
        if (modulo._id === id) {
          return {
            ...modulo,
            isSelected: false,
            activities: modulo.activities.map(activity => {
              return { ...activity, isSelected: false }
            }),
          }
        }
        return modulo
      })
      setModulosSelected(newModulosSelected)
    } else {
      // Si es actividad, habrá que marcar esa actividad.
      // Habrá que comprobar si la célula está seleccionada para desmarcarla también. Si ya estaba desmarcada, no hacer nada
      const newModulosSelected = modulosSelected.map(modulo => {
        return {
          ...modulo,
          activities: modulo.activities.map(activity => {
            if (activity._id === id) {
              return { ...activity, isSelected: false }
            }
            return activity
          }),
        }
      })
      let newModulosSelected2 = newModulosSelected.map(modulo => {
        if (!modulo.activities.every(activity => activity.isSelected)) {
          return { ...modulo, isSelected: false }
        }
        return modulo
      })
      setModulosSelected(newModulosSelected2)
    }
  }

  const addDbIdSelected = (id, isCelula = false) => {
    // Si es célula, habrá que marcar esa célula y todas sus actividades
    if (isCelula) {
      const newModulosSelected = modulosSelected.map(modulo => {
        if (modulo._id === id) {
          return {
            ...modulo,
            isSelected: true,
            activities: modulo.activities.map(activity => {
              return { ...activity, isSelected: true }
            }),
          }
        }
        return modulo
      })

      setModulosSelected(newModulosSelected)
    } else {
      // Si es actividad, habrá que marcar esa actividad.
      // Habrá que comprobar si todas las actividades de esa célula están seleccionadas para marcarla también. Si ya estaba marcada, no hacer nada
      const newModulosSelected = modulosSelected.map(modulo => {
        return {
          ...modulo,
          activities: modulo.activities.map(activity => {
            if (activity._id === id) {
              return { ...activity, isSelected: true }
            }
            return activity
          }),
        }
      })

      let newModulosSelected2 = newModulosSelected.map(modulo => {
        if (modulo.activities.every(activity => activity.isSelected)) {
          return { ...modulo, isSelected: true }
        }
        return modulo
      })

      setModulosSelected(newModulosSelected2)
    }
  }

  const clearDbIdsSelected = () => {}

  useEffect(() => {
    // Cada vez que cambie modulosSelected, habrá que filtrar en el modelo
    // Si la célula está seleccionada, habrá que coger todos los dbIds de las artículos dentro de las actividades
    // Si la actividad está seleccionada, habrá que coger todos los dbIds de las artículos
    let externalIds = []
    modulosSelected.forEach(modulo => {
      if (modulo.isSelected) {
        modulo.activities.forEach(activity => {
          externalIds = externalIds.concat(
            activity.articulos.flatMap(articulo => articulo.externalids)
          )
        })
      } else {
        modulo.activities.forEach(activity => {
          if (activity.isSelected) {
            externalIds = externalIds.concat(
              activity.articulos.flatMap(articulo => articulo.externalids)
            )
          }
        })
      }
    })
    const externalIdsWithoutDuplicates = [...new Set(externalIds)]
    const dbIds = externalIdsWithoutDuplicates.map(
      externalId => externalIdsMapping[externalId]
    )

    viewer.isolate(dbIds)
  }, [modulosSelected])

  const data = {
    celulasInfo: modulosSelected,
    addDbIdSelected,
    removeDbIdSelected,
    clearDbIdsSelected,
  }

  return (
    <ModuloContext.Provider value={data}>{children}</ModuloContext.Provider>
  )
}

export const useModulo = () => {
  const context = useContext(ModuloContext)
  if (context === undefined) {
    throw new Error('useModulo must be used within a ModuloProvider')
  }
  return context
}
