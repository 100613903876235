export const calculateCenteredCrop = (width, height, aspect_ratio) => {
  let crop
  if (width / aspect_ratio > height) {
    const cropHeightInPercent = 100
    const cropWidthInPercent = ((height * aspect_ratio) / width) * 100
    crop = {
      unit: '%',
      width: cropWidthInPercent,
      height: cropHeightInPercent,
      x: (100 - cropWidthInPercent) / 2,
      y: 0,
    }
  } else {
    const cropWidthInPercent = 100
    const cropHeightInPercent = (width / aspect_ratio / height) * 100
    crop = {
      unit: '%',
      width: cropWidthInPercent,
      height: cropHeightInPercent,
      x: 0,
      y: (100 - cropHeightInPercent) / 2,
    }
  }
  return crop
}
