import { CustomButton } from 'components/ui'
import { Tooltip } from 'react-tooltip'

const ButtonsCell = ({ buttonsArray }) => {
  return (
    <div className="group-btn">
      {buttonsArray.map(button => (
        <>
          <CustomButton
            type="secondary"
            style={{ padding: '.5rem' }}
            className={button.class}
            onClick={button.onClick}
          >
            {button.Icon}
          </CustomButton>
          <Tooltip
            anchorSelect={`.${button.class}`}
            style={{
              fontSize: '9px',
            }}
          >
            {button.name}
          </Tooltip>
        </>
      ))}
    </div>
  )
}

export default ButtonsCell
