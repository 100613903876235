import { ordenarPorPropiedad } from '../../utils/formatText'
import CelulaModuloRow from './CelulaModuloRow'
import { useContext } from 'react'
import { ModuloContext, useModulo } from '../../context/ModuloContext'
import CelulaTableRow from './CelulaTableRow'

const ModuloTable = () => {
  const { celulasInfo } = useModulo()

  if (!celulasInfo) return

  return (
    <div style={{ flex: '1', overflowY: 'auto' }}>
      <table className="modulo-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Cantidad</th>
            <th>€/unit</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody>
          {celulasInfo.sort(ordenarPorPropiedad('name')).map(celula => (
            <CelulaTableRow celula={celula} key={celula._id} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ModuloTable
