import { useQuery } from '@tanstack/react-query'
import { checkProjectVersion } from 'api/projectApi'
import React, { useEffect } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useParams, useSearchParams } from 'react-router-dom'

const TranslateVersionModel = ({ setOpenModalVersionTranslate }) => {
  const { idProject } = useParams()
  let [searchParams] = useSearchParams()
  const versionParam = searchParams.get('version') || 0

  const { data: dataVersion, isLoading } = useQuery(
    ['checkProjectVersion', idProject, parseInt(versionParam)],
    () => checkProjectVersion(idProject, parseInt(versionParam)),
    {
      refetchInterval: 20000, // Ejecutar la función cada 20 segundos
    }
  )

  useEffect(() => {
    if (!dataVersion) return
    if (dataVersion.data.manifest.status === 'success') {
      // * Cerrar el modal
      setOpenModalVersionTranslate(false)
    }
  }, [dataVersion])

  if (isLoading) return <FaSpinner size={36} />
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '3rem',
        alignItems: 'center',
      }}
    >
      <h1 style={{ textAlign: 'center' }}>
        La versión seleccionada está siendo todavía traducida. Esto puede llevar
        unos minutos..
      </h1>
      <h3>Espere, por favor.</h3>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          width: '120px',
          height: '120px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontSize: '5rem' }}>
          {dataVersion.data.manifest.progress.substring(0, 3) || '50%'}
        </h1>
        <div className="ring-percentage"></div>
      </div>
    </div>
  )
}

export default TranslateVersionModel
