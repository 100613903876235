import React from 'react'
import CelulaRow from './CelulaRow'
import { ordenarPorPropiedad } from '../../utils/formatText'
import AddIcon from '@mui/icons-material/Add'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { CustomButton } from 'components/ui'

const CelulasTable = ({ celulas }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  return (
    <div className="celulas-table-wrapper">
      <table className="modulo-table">
        <thead>
          <tr>
            <th
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <p>Células</p>
              <CustomButton
                style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
                onClick={() =>
                  navigate(changeLocation(`${location.pathname}/nueva-celula`))
                }
              >
                <AddIcon /> Nueva célula
              </CustomButton>
            </th>
          </tr>
        </thead>
        <tbody>
          {celulas.sort(ordenarPorPropiedad('name')).map(celula => (
            <CelulaRow celula={celula} key={celula._id} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CelulasTable
