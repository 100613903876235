import SearchArticulos from 'components/admin/Search/SearchArticulos'
import { CustomButton } from 'components/ui'
import PageTitle from 'components/ui/Title/PageTitle'
import { ArticulosTablePageProvider } from 'context/ArticulosTablePageContext'
import { Outlet, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import ActividadAdminTable from 'components/admin/Actividades/ActividadAdminTable'

const ActividadesAdminPage = () => {
  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate('/admin/actividades/new-actividad')
  }

  return (
    <>
      <PageTitle>Actividades</PageTitle>
      <ArticulosTablePageProvider>
        <div className="articulos-admin-wrapper">
          <SearchArticulos
            placeholder={'Buscar por código o nombre de la actividad.'}
          />

          <CustomButton
            className="button-create-actividad"
            onClick={handleOnClick}
          >
            <AddIcon /> <p style={{ fontSize: '1.2rem' }}>Crear actividades</p>
          </CustomButton>
        </div>
        <ActividadAdminTable />
      </ArticulosTablePageProvider>
      <Outlet />
    </>
  )
}

export default ActividadesAdminPage
