import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { store } from './app/store'
import theme from './theme'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ViewerProvider } from './context/ViewerContext'
import { router } from './routing'
import { RoleProvider } from './context/RoleContext'
import 'react-loading-skeleton/dist/skeleton.css'

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
})

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Toaster
            toastOptions={{
              style: {
                backgroundColor: 'black',
                color: 'white',
              },
            }}
            duration={4000}
            position="top-center"
          />
          <RoleProvider>
            <ViewerProvider>
              <div className="body-app">
                <RouterProvider router={router} />
              </div>
            </ViewerProvider>
          </RoleProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  )
}

export default App
