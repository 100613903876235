import React from 'react'

const ProyectoCardItem = ({ item, handleOnClick }) => {
  return (
    <figure
      className="project-item project-item--admin"
      key={item?.code}
      onClick={() => handleOnClick(item._id)}
    >
      <img
        alt="Proyecto"
        src={
          item.image ||
          'https://jit.fra1.digitaloceanspaces.com/Test_building.jpg'
        }
      />
      <div className="project-body project-body--admin">
        <div className="project-body-icons">
          <p>{item?.code}</p>
        </div>
        <div className="project-body-name project-body-name--admin">
          <p>{item?.name}</p>
        </div>
      </div>
    </figure>
  )
}

export default ProyectoCardItem
