/* global Autodesk */
import { BaseExtension } from './BaseExtension.js'
import { ModulosPanel } from './ModulosPanel'

class ModulosExtension extends BaseExtension {
  constructor(viewer, options) {
    super(viewer, options)
    this._modulosButton = null
    this._modulosPanel = null
  }

  async load() {
    super.load()
    console.log('ModulosExtension loaded.')
    return true
  }

  unload() {
    super.unload()
    this.removeToolbarButton(this._modulosButton)
    this._modulosButton = null
    this._modulosPanel.setVisible(false)
    this._modulosPanel.uninitialize()
    this._modulosPanel = null
    console.log('ModulosExtension unloaded.')
    return true
  }

  onToolbarCreated() {
    this._modulosPanel = new ModulosPanel(
      this,
      'dashboard-modulos-panel',
      'Gestión Modulos',
      { x: 100, y: 100 }
    )
    this._modulosButton = this.createToolbarButton(
      'dashboard-piechart-button',
      'https://img.icons8.com/small/32/pie-chart.png',
      'Gestión Modulos'
    )
    this._modulosButton.onClick = async () => {
      this._modulosPanel.setVisible(!this._modulosPanel.isVisible())
      this._modulosButton.setState(
        this._modulosPanel.isVisible()
          ? Autodesk.Viewing.UI.Button.State.ACTIVE
          : Autodesk.Viewing.UI.Button.State.INACTIVE
      )
      if (this._modulosPanel.isVisible() && this.viewer.model) {
        this._modulosPanel.setModel(this.viewer.model)
      }
    }
  }

  groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  async getModulos(model) {
    const data = await this.getData(model)
    const externalIdMapping = await this.getExternalIdMappingAsync(
      this.viewer.model
    )
    const linkedDocuments = data.linkedDocuments.map(x => ({
      dbId: externalIdMapping[x.instanceId],
      instanceId: x.instanceId,
    }))
    const test = await Promise.all(
      linkedDocuments.map(async x => ({
        dbId: x.dbId,
        instanceId: x.instanceId,
        name: await this.getName(x.dbId),
      }))
    )
    const grouped = this.groupBy(test, 'name')
    console.log('test', test)
    return grouped
  }

  async getPartidas(model, dbIds) {
    const partidas = await this.getBulkPropertiesAsync(model, dbIds, [
      'Código de montaje',
    ])
    const modifiedPartidas = partidas.map(item => ({
      dbId: item.dbId,
      partida: item.properties[0]?.displayValue,
    }))
    const groupedPartidas = this.groupBy(modifiedPartidas, 'partida')
    return groupedPartidas
  }

  async getQto(model, dbIds) {
    const gbp = await this.getBulkPropertiesAsync(model, dbIds, [
      'Longitud',
      'Área',
      'Volumen',
    ])
    let qto = {
      unidades: 0,
      longitud: 0.0,
      area: 0.0,
      volumen: 0.0,
    }

    if (gbp.length === 0) {
      qto.unidades = dbIds.length
    } else {
      gbp.forEach(item => {
        qto.unidades++
        const longitud = item.properties.find(
          x => x.displayName === 'Longitud'
        )?.displayValue
        qto.longitud += longitud
        const area = item.properties.find(
          x => x.displayName === 'Área'
        )?.displayValue
        qto.area += area
        const volumen = item.properties.find(
          x => x.displayName === 'Volumen'
        )?.displayValue
        qto.volumen += volumen
      })
    }

    return qto
  }

  async getBulkPropertiesAsync(model, dbIds, props = ['name']) {
    return new Promise((resolve, reject) => {
      model.getBulkProperties(
        dbIds,
        props,
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  async getName(dbId) {
    return new Promise((resolve, reject) => {
      this.viewer.getProperties(
        dbId,
        res => {
          const name = res.properties
            .find(
              prop =>
                prop.displayName === 'Nombre de tipo' ||
                prop.attributeName === 'Type Name'
            )
            .displayValue.split('001-')[1]
            .replace('.rvt', '')
          resolve(name)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  async getExternalIdMappingAsync(model) {
    return new Promise((resolve, reject) => {
      model.getExternalIdMapping(
        map => resolve(map),
        error => reject(error)
      )
    })
  }

  async getData(model) {
    const data = await model
      .getDocumentNode()
      .getDocument()
      .downloadAecModelData()
    return data
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  'ModulosExtension',
  ModulosExtension
)
