import React from 'react'

const UserItemEmail = ({ user }) => {
  return (
    <div className="item-user-proyecto-info-container">
      <p className="item-user-proyecto-info-name">{user.name}</p>
      <p className="users-project-text">{user.email}</p>
    </div>
  )
}

export default UserItemEmail
