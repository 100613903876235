import { CustomButton } from 'components/ui'
import Modal from 'components/ui/Modal/Modal'
import { useUpdateSingleArticulo } from 'lib/react-query/queriesArticulos'
import React from 'react'

const ModalDeleteArticuloAdmin = ({ handleOnClose, articulo }) => {
  const { mutateAsync: updateArticulo } = useUpdateSingleArticulo()

  const handleOnClick = () => {
    updateArticulo({ id: articulo._id, active: false })
    handleOnClose()
  }
  return (
    <Modal
      modalClass="modal modal-delete-articulo"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div className="modal-link-bimlink-to-tipo-container--width">
        <h2>{`Eliminar ${articulo.name}`}</h2>
        <p
          style={{ marginTop: '2rem', fontSize: '1.5rem', lineHeight: '2rem' }}
        >
          ¿Estás seguro de que desea eliminar este artículo? Una vez eliminado,
          no podrás recuperarlo.
        </p>
        <div className="buttons-container-edit-form buttons-container-delete-articulo">
          <CustomButton
            type="secondary"
            onClick={handleOnClose}
            className="button-delete-articulo"
          >
            Cancelar
          </CustomButton>
          <CustomButton
            type="submit"
            className="button-delete-articulo"
            onClick={handleOnClick}
          >
            Eliminar
          </CustomButton>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDeleteArticuloAdmin
