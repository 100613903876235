import EditArticuloForm from 'components/EditArticulo/EditArticuloForm'
import ArticulosTableLoader from 'components/Loaders/ArticulosTableLoader'
import { useGetArticuloProyecto } from 'lib/react-query/queries'
import { Navigate, useParams } from 'react-router-dom'

const EditArticuloPage = () => {
  const { idarticulo, idProject } = useParams()
  const { data, isLoading, isError } = useGetArticuloProyecto(idarticulo, idProject)

  if (isLoading) return <ArticulosTableLoader />
  if (isError) return <Navigate to={`/articulos`} />
  return <EditArticuloForm />
}

export default EditArticuloPage
