const formatToCurrency = value =>
  value.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'EUR',
  })

const formatToDecimal = (value, decimals = { max: 2, min: 0 }) => {
  return value.toLocaleString('es-ES', {
    minimumFractionDigits: decimals.min,
    maximumFractionDigits: decimals.max,
  })
}

export { formatToCurrency, formatToDecimal }
