import BoxSkeleton from 'components/admin/BoxSkeleton'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

const TiposProjectLoader = () => {
  return (
    <div>
      <Skeleton wrapper={BoxSkeleton} count={1} height={60} />
      <Skeleton wrapper={BoxSkeleton} count={25} height={30} />
    </div>
  )
}

export default TiposProjectLoader
