import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import InputThemeProvider from '../components/Common/InputThemeProvider'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { DashboardContext } from '../context/DashboardContext'
import { Colxx } from '../components/Common/CustomBootStrap'
import { Row } from 'reactstrap'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined'
import { getProjectById } from '../features/proyecto/proyectoSlice'
import { useAuth0 } from '@auth0/auth0-react'
import ImageIcon from '@mui/icons-material/Image'
import {
  deleteFile,
  patchProyecto,
  uploadFileDigitalOcean,
} from '../api/jitApi'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import ButtonUploadImage from '../components/Common/ButtonUploadImage'

const EditProject = () => {
  const { idProject } = useParams()
  const navigate = useNavigate()
  const { user } = useAuth0()
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [previewImg, setPreviewImg] = useState(null)
  const [loadingGetProject, setLoadingGetProject] = useState(true)
  const [doImage, setDoImage] = useState(null)
  const { setCurrentProject, currentProject } = useContext(DashboardContext)

  useEffect(() => {
    const getProject = async () => {
      setLoadingGetProject(true)
      const res = await getProjectById(idProject)
      setCurrentProject(res)
      setLoadingGetProject(false)
    }
    if (!currentProject) {
      getProject()
    } else {
      setLoadingGetProject(false)
    }
  }, [idProject])

  useEffect(() => {
    if (currentProject) {
      formik.setValues({
        name: currentProject.name,
        code: currentProject.code,
        description: currentProject.description,
        urn: currentProject.urn,
        status: currentProject.status,
        img: currentProject.img,
      })
    }
  }, [currentProject])

  // const validationSchema = yup.object({
  //   name: yup.string().required("El nombre es requerido"),
  //   code: yup.string().required("El código es requerido"),
  //   status: yup.string().required("El estado es requerido"),
  //   urn: yup.string().required("El urn es requerido"),
  // });

  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
      description: '',
      urn: '',
      status: '',
      img: '',
    },
    // validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmitUpdateProject(values)
    },
  })

  const handleSubmitUpdateProject = async values => {
    setLoadingAdd(true)
    try {
      if (previewImg) {
        if (currentProject?.image) {
          await deleteFile(currentProject?.image.key)
        }
        const nameThumb =
          'thumbproject_' + currentProject?.code + '-' + currentProject?.name
        const uploadThumb = await uploadFileDigitalOcean(previewImg, nameThumb)
        values.image = {
          key: uploadThumb.key,
          url: uploadThumb.url,
        }
      }
      await patchProyecto(idProject, values)
      toast.success('Proyecto actualizado correctamente')
      navigate('/')
    } catch (error) {
      console.log('Error al actualizar el proyecto', error)
    }
    setLoadingAdd(false)
  }

  const status = [
    {
      value: 'Planificación',
    },
    {
      value: 'Oferta',
    },
    {
      value: 'Abierto',
    },
    {
      value: 'Completado',
    },
  ]

  const handleChangeImage = e => {
    setPreviewImg(e.target.files[0])
  }

  return loadingGetProject ? (
    <div className="loader-container-center">
      <CircularProgress />
    </div>
  ) : (
    <Row
      style={{
        justifyContent: 'space-around',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <Colxx
        style={{ display: 'flex', alignItems: 'center' }}
        xxs="6"
        className="mb-0"
      >
        <form className="container-edit" onSubmit={formik.handleSubmit}>
          <h1 className="montserrat title-form">Editar proyecto</h1>
          <InputThemeProvider>
            <div className="pt-4">
              <div className="d-flex d-column pb-4">
                <p>Nombre *</p>
                <TextField
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  fullWidth
                  name="name"
                  required
                  // error={formik.touched.name && Boolean(formik.errors.name)}
                  // helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{ shrink: false }}
                />
              </div>

              <div className="d-flex d-column pb-4">
                <p>Descripcion</p>
                <TextField
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  name="description"
                  InputLabelProps={{ shrink: false }}
                  multiline
                  rows={4}
                />
              </div>
              <div className="d-flex d-column pb-4">
                <p>URN *</p>
                <TextField
                  onChange={formik.handleChange}
                  value={formik.values.urn}
                  name="urn"
                  // error={formik.touched.urn && Boolean(formik.errors.urn)}
                  // helperText={formik.touched.urn && formik.errors.urn}
                  required
                  InputLabelProps={{ shrink: false }}
                />
              </div>
              <div className="d-flex d-column pb-4">
                <p>Status *</p>
                <TextField
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                  required
                  InputLabelProps={{ shrink: false }}
                  name="status"
                  select
                  // error={formik.touched.status && Boolean(formik.errors.status)}
                  // helperText={formik.touched.status && formik.errors.status}
                  value={formik.values.status}
                >
                  {status.map(item => (
                    <MenuItem value={item.value}>{item.value}</MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="d-flex d-column pb-4">
                <p>Código *</p>
                <TextField
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                  name="code"
                  // label
                  InputLabelProps={{ shrink: false }}
                  required
                  // error={formik.touched.code && Boolean(formik.errors.code)}
                  // helperText={formik.touched.code && formik.errors.code}
                  value={formik.values.code}
                />
              </div>
            </div>
          </InputThemeProvider>

          {/* BOTONES  */}
          <div className="d-flex a-center j-flex-end g-15 ">
            <Link to="/">
              <Button
                type="button"
                color="primary"
                variant="contained"
                className="button-white t-transform-none"
                style={{ borderRadius: '0' }}
              >
                Cancelar
              </Button>
            </Link>
            <Button
              endIcon={loadingAdd && <CircularProgress size={20} />}
              type="submit"
              variant="contained"
              className={`t-transform-none btn-submit`}
            >
              Guardar
            </Button>
          </div>
        </form>
      </Colxx>
      <Colxx xxs="4" className="mb-0 box-edit-project">
        <div className="project-card-review-title">
          <RemoveRedEyeIcon style={{ color: '#CDCDCDCD' }} />
          <p style={{ color: '#282E2A' }} className="mb-0">
            Project card preview
          </p>
        </div>
        <Box style={{ width: '377.5px' }} className="card-project">
          <img
            src={
              previewImg
                ? URL.createObjectURL(previewImg)
                : currentProject?.img
                  ? `data:image/png;base64,${currentProject.img}`
                  : '/Image_not_available.png'
            }
            className="img-project"
          />
          <div
            style={{ height: '20%' }}
            className="d-flex a-center p-4 j-between"
          >
            <div className="d-flex a-center g-04">
              {!currentProject.user.includes(user.sub) ? (
                <HomeWorkOutlinedIcon fontSize="small" />
              ) : (
                <CottageOutlinedIcon fontSize="small" />
              )}
              <p
                className="p-0 m-0 roboto-condensed"
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                }}
              >
                {currentProject?.code}
              </p>
              <p
                className="p-0 m-0 roboto-condensed"
                style={{ fontSize: '16px', whiteSpace: 'nowrap' }}
              >
                - {currentProject?.name}
              </p>
            </div>
            <i className="simple-icon-options-vertical" />
          </div>
        </Box>
        <div className="project-card-review-footer">
          <Button
            style={{ width: '5%' }}
            className={`t-transform-none btn-submit btn-icon`}
          >
            <ImageIcon style={{ color: '#CDCDCD' }} />
          </Button>
          <ButtonUploadImage
            handleOnChangeImage={e => handleChangeImage(e)}
            description="Cambiar imagen"
            classNameButton={`t-transform-none btn-submit w-80 p-0 br-4`}
          />
        </div>
      </Colxx>
    </Row>
  )
}

export default EditProject
