import React, { useContext } from 'react'
import { viewer } from '../../utils/launchViewer'
import { ViewerContext } from '../../context/ViewerContext'
import { redondearDecimalesToLocaleString } from '../../utils/roundNumber'

const CelulaArticulo = ({ articulos }) => {
  const { externalIdsMapping } = useContext(ViewerContext)
  const handleIsolate = articulo => {
    console.log('articulo', articulo)
    const dbIds = articulo.externalids.map(id => externalIdsMapping[id])
    console.log('dbIds', dbIds)
    viewer.isolate(dbIds)
  }
  return (
    <div
      style={{
        // paddingLeft: '2rem',
        // paddingRight: '1rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {articulos.map(articulo => (
        <div
          key={articulo._id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #eee',
            paddingTop: '.5rem',
            paddingBottom: '.5rem',
            paddingLeft: '2.5rem',
            paddingRight: '1rem',
            fontSize: '.875rem',
          }}
          onClick={() => {
            handleIsolate(articulos.find(art => art._id === articulo._id))
          }}
        >
          <p>
            {articulo.codeArticulo} - {articulo.nombreArticulo}
          </p>
          <div style={{ display: 'flex', width: '400px' }}>
            <p style={{ flex: 1, textAlign: 'right' }}>
              {redondearDecimalesToLocaleString(articulo.quantity)}
              {articulo.unit || 'not-unit'}
            </p>
            <p style={{ flex: 1, textAlign: 'right' }}>
              {redondearDecimalesToLocaleString(articulo.precio || 1)}€/
              {articulo.unit || 'not-unit'}
            </p>
            <p style={{ flex: 1, textAlign: 'right' }}>
              {redondearDecimalesToLocaleString(
                articulo.quantity * articulo.precio
              )}
              €
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CelulaArticulo
