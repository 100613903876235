import TextInput from 'components/ui/Forms/TextInput'
import { useClickOutside } from 'hooks/useClickOutside'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import SearchOptionsGroup, {
  SearchOptionsGroupItem,
} from '../ArticuloBimLink/SearchOptionsGroup'
import ClearIcon from '@mui/icons-material/Clear'

import { compararPorPropiedad } from 'components/Celulas/NewCelula/ActivitiesCheckbox'
import { useRulesBimLInk } from 'context/RulesBimLinkContext'

const DisplayNameInput = ({ data }) => {
  const rootEl = useRef(null)
  const [textInput, setTextInput] = useState('')
  const { selectedDisplayName, setSelectedDisplayName } = useRulesBimLInk()
  const [showOptions, setShowOptions] = useState(false)

  const resetInfo = () => {
    setShowOptions(false)
    if (!showOptions) return
    if (selectedDisplayName) {
      setTextInput(
        `${selectedDisplayName.category} - ${selectedDisplayName.name}`
      )
    } else {
      setTextInput('')
    }
  }

  const nameInputSelectedDisplayName = useMemo(() => {
    if (selectedDisplayName) {
      return `${selectedDisplayName.category} - ${selectedDisplayName.name}`
    } else {
      return null
    }
  }, [selectedDisplayName])

  useClickOutside(rootEl, resetInfo)

  const handleSelectedDisplayName = actividad => {
    setSelectedDisplayName(actividad)
    setTextInput(`${actividad.category} - ${actividad.name}`)
    setShowOptions(false)
  }

  const filterData = options => {
    if (selectedDisplayName) {
      if (
        textInput ==
        `${selectedDisplayName.category} - ${selectedDisplayName.name}`
      ) {
        return options
      }
    }

    if (textInput.length >= 2) {
      console.log(
        'length',
        options.filter(
          articulo =>
            articulo.category.toLowerCase().includes(textInput.toLowerCase()) ||
            articulo.name.toLowerCase().includes(textInput.toLowerCase())
        ).length
      )
      return options.filter(
        articulo =>
          articulo.category.toLowerCase().includes(textInput.toLowerCase()) ||
          articulo.name.toLowerCase().includes(textInput.toLowerCase())
      )
    }

    return data
  }

  const handleMenuOpen = () => {
    setShowOptions(true)
  }

  useEffect(() => {
    if (!textInput) {
      setSelectedDisplayName(null)
    }
  }, [textInput])
  return (
    <div style={{ position: 'relative' }} ref={rootEl}>
      <TextInput
        label="Display names"
        name="displaynames"
        onChange={e => setTextInput(e.target.value)}
        placeholder="Buscar por código o nombre del display name. Escribe, al menos, dos caracteres"
        value={textInput || nameInputSelectedDisplayName || ''}
        onClick={handleMenuOpen}
        autoComplete="off"
      />
      {selectedDisplayName && (
        <div
          className="icon-text-input-reset"
          onClick={() => {
            setSelectedDisplayName(null)
            setTextInput('')
          }}
        >
          <ClearIcon />
        </div>
      )}
      {showOptions && (
        <SearchOptionsGroup width={rootEl?.current}>
          {data ? (
            filterData(data).length == 0 ? (
              <div style={{ padding: '1rem', fontStyle: 'oblique' }}>
                No hay ningún elemento que coincida con la búsqueda
              </div>
            ) : (
              filterData(data)
                .sort(compararPorPropiedad('category'))
                .map(articulo => (
                  <SearchOptionsGroupItem
                    title={`${articulo.category} - ${articulo.name}`}
                    item={articulo}
                    handleSelectedItem={handleSelectedDisplayName}
                    isSelected={selectedDisplayName?.key === articulo.key}
                  />
                ))
            )
          ) : null}
        </SearchOptionsGroup>
      )}
    </div>
  )
}

export default DisplayNameInput
