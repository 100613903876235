import { getBulkPropertiesAsync } from './getPropertiesViewer'
import { viewer } from './launchViewer'

const getExternalIdMapping = () => {
  return new Promise((resolve, reject) => {
    viewer.model.getExternalIdMapping(
      map => resolve(map),
      error => reject(error)
    )
  })
}

export const getDataAecModelData = async () => {
  const data = await viewer.model
    .getDocumentNode()
    .getDocument()
    .downloadAecModelData()

  return data
}

export const getDataAecModelDataDemo = async () => {
  return new Promise((resolve, reject) => {
    viewer.model
      .getDocumentNode()
      .getDocument()
      .downloadAecModelData()
      .then(data => {
        console.log('data')
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

const getModuleName = dbId => {
  return new Promise((resolve, reject) => {
    viewer.getProperties(
      dbId,
      res => {
        const name = res.properties
          .find(
            prop =>
              prop.displayName === 'Nombre de tipo' ||
              prop.attributeName === 'Type Name'
          )
          .displayValue.split('001-')[1]
          .replace('.rvt', '')
        resolve(name)
      },
      err => {
        reject(err)
      }
    )
  })
}

export const findLeafNodes = viewer => {
  return new Promise(function (resolve, reject) {
    viewer.model.getObjectTree(function (tree) {
      let leaves = []
      tree.enumNodeChildren(
        tree.getRootId(),
        function (dbId) {
          if (tree.getChildCount(dbId) === 0) {
            leaves.push(dbId)
          }
        },
        true
      )
      resolve(leaves)
    }, reject)
  })
}

export const getBulkPropertiesAllDbIds = async () => {
  const dbIds = await findLeafNodes(viewer)
  const externalIds = await getBulkPropertiesAsync(viewer, dbIds, [
    'externalId',
  ])
  return externalIds
}

export const getBulkPropertiesAllDbIdsPromise = () => {
  return new Promise((resolve, reject) => {
    findLeafNodes(viewer)
      .then(dbIds => {
        getBulkPropertiesAsync(viewer, dbIds, ['externalId'])
          .then(externalIds => {
            resolve(externalIds)
          })
          .catch(err => {
            reject(err)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getModulosInfo = async () => {
  const dataAecModelData = await getDataAecModelData()
  console.log('dataAecModelData', dataAecModelData)

  const externalIdMapping = await getExternalIdMapping()
  console.log('externalIdsMapping', externalIdMapping)
  const linkedDocuments = dataAecModelData.linkedDocuments.map(x => ({
    dbId: externalIdMapping[x.instanceId],
    instanceId: x.instanceId,
  }))

  const modulos = await Promise.all(
    linkedDocuments.map(async x => ({
      dbId: x.dbId,
      instanceId: x.instanceId,
      name: await getModuleName(x.dbId),
    }))
  )

  console.log('modulosInfo', modulos)
  return modulos
}

export function getModulos(linkedDocuments) {
  const promises = linkedDocuments.map(x => {
    return new Promise((resolve, reject) => {
      getModuleName(x.dbId)
        .then(moduleName => {
          resolve({
            dbId: x.dbId,
            instanceId: x.instanceId,
            name: moduleName,
          })
        })
        .catch(err => {
          reject(err)
        })
    })
  })

  return Promise.all(promises)
}
