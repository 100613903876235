import { useGetArticulo } from 'lib/react-query/queriesArticulos'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import EditArticuloAdminPage from './EditArticuloAdminPage'

const EditArticuloAdminWrapper = () => {

  return <EditArticuloAdminPage  />
}

export default EditArticuloAdminWrapper
