import axios from 'axios'

export const getTipos = async (idProject, options) => {
  const { data } = await axios.get(`/api/v1/tipos/${idProject}`, {
    params: {
      ...options,
    },
  })

  return data
}

export const getTipo = async (proyectoId, id) => {
  const res = await fetch(`/api/v1/tipobimlink/${proyectoId}/${id}`)
  const data = await res.json()

  return data.data
}

export const createTipo = async (idProject, body) => {
  try {
    const { data } = axios.post(`/api/v1/tipos/${idProject}`, {
      ...body,
    })

    return data
  } catch (e) {
    console.error(e)
  }
}

export const updateTipo = async (proyectoId, id, body) => {
  try {
    await axios.patch(`/api/v1/tipos/${proyectoId}/${id}`, { ...body })
  } catch (e) {
    console.log('error', e)
  }
}

export const hideTipo = async b => {
  const res = await fetch('/api/v1/tipobimlink', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      proyecto: b.proyecto,
      tipoExternalId: b.tipoExternalId,
      name: b.name,
      visible: b.visible || false,
    }),
  })
  const data = await res.json()

  return data.data
}
