import SidebarAdmin from 'components/admin/Sidebar/SidebarAdmin'
import React from 'react'
import { Outlet } from 'react-router-dom'

const layout = () => {
  return (
    <div className="admin-layout">
      <SidebarAdmin />
      <div className="admin-container-wrapper">
        {/* <div
          style={{
            padding: '5rem',
            background: 'white',
            borderRadius: '2rem',
            boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.08)',
          }}
        > */}
          <Outlet />
        {/* </div> */}
      </div>
    </div>
  )
}

export default layout
