import axios from 'axios'

const getAllFields = async projectId => {
  const { data } = await axios.post(
    `/api/v1/forge/modelproperties/createindex/${projectId}`,
    {}
  )

  return data
}

const getDisplayValues = async body => {
  const { data } = await axios.post(`/api/v1/forge/modelproperties/query`, body)

  return data
}
export { getAllFields, getDisplayValues }
