import React, { useEffect, useRef } from 'react'
export const DropDownSubItem = ({ item }) => (
  <div className="custom-dropdown-list-subitem">{item}</div>
)

export const DropDownItem = ({ item, onClick, icon, children }) => (
  <li onClick={onClick} className="custom-dropdown-list-item">
    <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
      {icon && icon}
      {item}
    </div>
    {children}
  </li>
)

const Dropdown = ({ isOpen, setIsOpen, button, children }) => {
  const dropdownRef = useRef(null)

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={dropdownRef} className="custom-dropdown ">
      {button}
      {isOpen && (
        <div className="custom-dropdown-container">
          <ul className="custom-dropdown-list">{children}</ul>
        </div>
      )}
    </div>
  )
}

export default Dropdown
