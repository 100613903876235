import SearchOptionsGroup, {
  SearchOptionsGroupItem,
} from 'components/Bimlink/ArticuloBimLink/SearchOptionsGroup'
import { compararPorPropiedad } from 'components/Celulas/NewCelula/ActivitiesCheckbox'
import TextInput from 'components/ui/Forms/TextInput'
import { useGetAllBimlinks } from 'lib/react-query/queries'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { useAddBimLinkToTipo } from 'context/AddBimLinkToTipoContext'

const BimLinkInput = () => {
  const { data } = useGetAllBimlinks()
  const { selectedBimlink, setSelectedBimlink } = useAddBimLinkToTipo()
  const rootEl = useRef(null)
  const [textInput, setTextInput] = useState('')
  const [showOptions, setShowOptions] = useState(false)

  const nameInputSelectedBimlink = useMemo(() => {
    if (selectedBimlink) {
      return `${selectedBimlink.code}`
    } else {
      return null
    }
  }, [selectedBimlink])

  const handleSelectedBimlink = bimlink => {
    setSelectedBimlink(bimlink)
    setTextInput(`${bimlink.code}`)
    setShowOptions(false)
  }

  const filterData = options => {
    if (selectedBimlink) {
      if (textInput == `${selectedBimlink.code}`) {
        return options
      }
    }

    if (textInput.length >= 2) {
      return options.filter(articulo =>
        articulo.code.toLowerCase().includes(textInput.toLowerCase())
      )
    }

    return data?.data
  }

  const handleMenuOpen = () => {
    setShowOptions(true)
  }

  useEffect(() => {
    if (!textInput) {
      setSelectedBimlink(null)
    }
  }, [textInput])

  if (!data) return <div>Loading...</div>

  return (
    <div style={{ position: 'relative' }} ref={rootEl}>
      <TextInput
        label="BimLinks"
        name="bimlinks"
        onChange={e => setTextInput(e.target.value)}
        placeholder="Buscar por código del bimlink. Escribe, al menos, dos caracteres"
        value={textInput || nameInputSelectedBimlink || ''}
        inputclass="input-bimlink-to-tipo"
        onClick={handleMenuOpen}
        autoComplete="off"
      />
      {selectedBimlink && (
        <div
          className="icon-text-input-reset"
          onClick={() => {
            setSelectedBimlink(null)
            setTextInput('')
          }}
        >
          <ClearIcon />
        </div>
      )}
      {showOptions && (
        <SearchOptionsGroup width={rootEl?.current}>
          {data ? (
            filterData(data?.data).length == 0 ? (
              <div className="not-match-input-search">
                No hay ningún elemento que coincida con la búsqueda
              </div>
            ) : (
              filterData(data?.data)
                .sort(compararPorPropiedad('code'))
                .map(bimlink => (
                  <SearchOptionsGroupItem
                    title={`${bimlink.code}`}
                    item={bimlink}
                    handleSelectedItem={handleSelectedBimlink}
                    isSelected={selectedBimlink?._id === bimlink?._id}
                  />
                ))
            )
          ) : null}
        </SearchOptionsGroup>
      )}
    </div>
  )
}

export default BimLinkInput
