import VisibilityIcon from '@mui/icons-material/Visibility'
import ClearIcon from '@mui/icons-material/Clear'
import { useParams } from 'react-router-dom'
import {
  useCreateTipoBimLinkProyecto,
  useUpdateTipoBimLink,
} from 'lib/react-query/queriesTipoBimLinkProyectos'
import { CustomButtonWithTooltip } from 'components/ui/CustomButton'
const ButtonsTipoConfigItem = ({ tipoBimlink, isHide, name, externalIds }) => {
  const { idProject } = useParams()
  const { mutateAsync } = useCreateTipoBimLinkProyecto()
  const { mutateAsync: updateTipo } = useUpdateTipoBimLink()

  const changeVisibilityTipo = visible => {
    // * Si no hay tipobimlink quiere decir que el tipobimlink no está en BD así que hay que crearlo. Si hay, actualizarlo
    if (!tipoBimlink) {
      const b = {
        name: name,
        proyecto: idProject,
        tipoExternalId: externalIds,
        visible,
      }

      mutateAsync(b)
    } else {
      const body = { visible, tipoExternalId: externalIds }
      updateTipo({ body, id: tipoBimlink._id })
    }
  }

  const removeBimlink = () => {
    updateTipo({ body: { bimlink: null }, id: tipoBimlink._id })
  }

  return (
    <div
      className="group-btn"
      style={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      {tipoBimlink?.bimlink && (
        <CustomButtonWithTooltip
          classname="remove-bimlink-by-tipo"
          onClick={removeBimlink}
          Icon={<ClearIcon />}
          title="Eliminar bimlink"
        />
      )}
      {isHide && (
        <CustomButtonWithTooltip
          classname="show-tipo"
          onClick={() => changeVisibilityTipo(true)}
          Icon={<VisibilityIcon />}
          title="Mostrar tipo"
        />
      )}
      {!isHide && (
        <CustomButtonWithTooltip
          classname="hide-tipo"
          onClick={() => changeVisibilityTipo(false)}
          Icon={<VisibilityIcon />}
          title="Ocultar tipo"
        />
      )}
    </div>
  )
}

export default ButtonsTipoConfigItem
