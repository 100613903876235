import React from 'react'

const SelectTypeUser = ({ radioValue, setRadioValue }) => {
  const handleRadioChange = e => {
    setRadioValue(e.target.value)
  }

  return (
    <div className="d-flex d-column">
      <p className="gray-text mb-2">Añadir Como:</p>

      <div className="d-flex">
        <div className="custom-radio">
          <input
            checked={radioValue === 'invitado'}
            onChange={handleRadioChange}
            type="radio"
            id="radio1"
            name="radio-group"
            value="invitado"
          />
          <label className="mb-0 pointer roboto-condensed-600" htmlFor="radio1">
            Invitado
          </label>
        </div>

        <div className="custom-radio">
          <input
            type="radio"
            id="radio2"
            name="radio-group"
            value="admin"
            checked={radioValue === 'admin'}
            onChange={handleRadioChange}
          />
          <label className="mb-0 pointer roboto-condensed-600" htmlFor="radio2">
            Administrador
          </label>
        </div>
      </div>
    </div>
  )
}

export default SelectTypeUser
