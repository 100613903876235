import { createContext, useContext, useState } from 'react'

export const ConfigMatriculasContext = createContext()

export const ConfigMatriculasProvider = ({ children }) => {
  const [textFieldValue, setTextFieldValue] = useState('')

  const onChangeTextFieldValue = value => {
    setTextFieldValue(value.target.value)
  }

  const data = {
    onChangeTextFieldValue,
    textFieldValue,
  }

  return (
    <ConfigMatriculasContext.Provider value={data}>
      {children}
    </ConfigMatriculasContext.Provider>
  )
}

export const useConfigMatriculas = () => {
  const context = useContext(ConfigMatriculasContext)
  if (context === undefined) {
    throw new Error(
      'useConfigMatriculas must be used within a ConfigMatriculasProvider'
    )
  }
  return context
}
