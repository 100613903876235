import { useAuth0 } from '@auth0/auth0-react'
import LoadingPage from '../pages/LoadingPage'

export const ProtectedRoute = ({ component }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  if (isLoading) return <LoadingPage />
  if (!isAuthenticated) {
    loginWithRedirect()
    return null
  }
  if (isAuthenticated) return component
}
