import { useAuth0 } from '@auth0/auth0-react'
import { useGetAllProjectsByUserAdmin } from 'lib/react-query/queriesProject'
import { useState } from 'react'
import ProyectoCardItem from './ProyectoCardItem'
import Pagination from 'components/ui/Pagination/Pagination'
import ProyectosArraySkeleton from 'components/Loaders/ProyectosArraySkeleton'
import { useNavigate } from 'react-router-dom'

const LIMIT_PAGINATION = 8
const ProyectosAdminList = () => {
  const { user } = useAuth0()
  const [page, setPage] = useState(1)
  const { data, isLoading } = useGetAllProjectsByUserAdmin(user.sub, {
    page,
    limit: LIMIT_PAGINATION,
  })

  const navigate = useNavigate()

  const handleOnClickProject = id => {
    navigate(`/admin/proyectos/${id}`)
  }

  if (isLoading) return <ProyectosArraySkeleton length={8} />
  return (
    <div className="proyectos-container-layout">
      <div className="proyectos-container">
        {data.data.data.map(item => (
          <ProyectoCardItem
            item={item}
            handleOnClick={(id) => handleOnClickProject(id)}
          />
        ))}
      </div>
      {Math.ceil(data.total / LIMIT_PAGINATION) !== 1 && (
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(data.total / LIMIT_PAGINATION)}
          handlePage={value => setPage(value)}
        />
      )}
    </div>
  )
}

export default ProyectosAdminList
