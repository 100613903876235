// export const defaultNameProperty = 'Nivel de tabla de planificación'
export const defaultNameProperty = 'Código de montaje'
export const VALUE_UNIT = '** Unidad'
export const MODULE_NAME = ['Comentarios']
export const PARAM_BIMLINK = 'BimLink'

export const EXAMPLE_CHART = [
  {
    _id: 1,
    quantity: 7002.607547999999,
  },
  {
    _id: 3,
    quantity: 500,
  },
  {
    _id: 9,
    quantity: 7002.607547999999,
  },
  {
    _id: 7,
    quantity: 6998.607547999999,
  },
  {
    _id: 8,
    quantity: 6998.607547999999,
  },
  {
    _id: 6,
    quantity: 6891.082463999999,
  },
  {
    _id: 4,
    quantity: 6891.082463999999,
  },
]

export const DIAS_MES_MO = 20
