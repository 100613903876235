import ArticulosAdminTable from 'components/admin/Articulos/ArticulosAdminTable'
import SearchArticulos from 'components/admin/Search/SearchArticulos'
import { CustomButton } from 'components/ui'
import PageTitle from 'components/ui/Title/PageTitle'
import AddIcon from '@mui/icons-material/Add'
import { ArticulosTablePageProvider } from 'context/ArticulosTablePageContext'
import { Outlet, useNavigate } from 'react-router-dom'
const ArticulosAdminPage = () => {
  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate('/admin/articulos/new-articulo')
  }

  return (
    <>
      <PageTitle>Artículos</PageTitle>
      <ArticulosTablePageProvider>
        <div className="articulos-admin-wrapper">
          <SearchArticulos placeholder="Buscar por código o nombre del artículo." />
          <CustomButton className="button-create-articulos" onClick={handleOnClick}>
            <AddIcon /> <p style={{ fontSize: '1.2rem' }}>Crear artículo</p>
          </CustomButton>
        </div>
        <ArticulosAdminTable />
      </ArticulosTablePageProvider>
      <Outlet />
    </>
  )
}

export default ArticulosAdminPage
