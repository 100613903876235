import {
  useCreateTipoBimLinkProyecto,
  useUpdateTipoBimLink,
} from 'lib/react-query/queriesTipoBimLinkProyectos'
import { createContext, useContext, useMemo, useState } from 'react'

export const AddBimLinkToTipoContext = createContext()

export const AddBimLinkToTipoProvider = ({ tipo, children }) => {
  const [selectedBimlink, setSelectedBimlink] = useState(null)
  const mutationCreateTipo = useCreateTipoBimLinkProyecto()
  const { mutateAsync } = useUpdateTipoBimLink()

  const nameInputSelectedBimlink = useMemo(() => {
    if (selectedBimlink) {
      return `${selectedBimlink.code}`
    } else {
      return null
    }
  }, [selectedBimlink])

  const createTipo = () => {
    if (tipo?._id) {
      const body = {
        tipoExternalId: tipo.externalIds,
        bimlink: selectedBimlink._id,
      }

      mutateAsync({ id: tipo._id, body })
    } else {
      const body = {
        name: tipo.name,
        tipoExternalId: tipo.externalIds,
        bimlink: selectedBimlink._id,
      }
      mutationCreateTipo.mutate(body)
    }
  }

  const data = {
    tipo,
    selectedBimlink,
    setSelectedBimlink,
    nameInputSelectedBimlink,
    createTipo,
  }
  return (
    <AddBimLinkToTipoContext.Provider value={data}>
      {children}
    </AddBimLinkToTipoContext.Provider>
  )
}

export const useAddBimLinkToTipo = () => {
  const context = useContext(AddBimLinkToTipoContext)
  if (context === undefined) {
    throw new Error(
      'useAddBimLinkToTipo must be used within a AddBimLinkToTipoProvider'
    )
  }
  return context
}
