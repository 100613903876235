import React from 'react'
import ArticuloTableRow from './ArticuloTableRow'

const ArticuloContainerTableRow = ({ articulos }) => {
  return (
    <>
      {articulos.length == 0 ? (
        <tr>
          <td
            style={{
              fontStyle: 'oblique',
              textAlign: 'center',
              columnSpan: '4',
            }}
          >
            No hay artículos
          </td>
        </tr>
      ) : (
        articulos.map(articulo => (
          <ArticuloTableRow articulos={articulo} articulo={articulo} />
        ))
      )}
    </>
  )
}

export default ArticuloContainerTableRow
