import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ModalImageSelected from './ModalImageSelected'
import UploadImgProyecto from './UploadImgProyecto'
import { useGetSingleProjectByUserAdmin } from 'lib/react-query/queriesProject'

const EditImageProyecto = () => {
  const [previewImage, setPreviewImage] = useState(null)

  const { idProject } = useParams()
  const { user } = useAuth0()
  const { data } = useGetSingleProjectByUserAdmin(user.sub, idProject)

  const handleImageChange = event => {
    const file = event.target.files[0]
    event.target.value = null // Limpiar el valor del input de tipo file

    // Aquí puedes realizar validaciones adicionales si lo deseas
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleOnClosePreview = () => {
    setPreviewImage(null)
  }

  return (
    <div className="container-upload-image">
      {previewImage && (
        <ModalImageSelected
          preview={previewImage}
          handleOnClose={handleOnClosePreview}
        />
      )}
      <div className="image-project-container">
        {data.data.image ? (
          <>
            <img
              alt="proyecto"
              className="image-project"
              src={data.data.image || null}
            ></img>
            <div className="upload-image-hover">
              <UploadImgProyecto handleImageChange={handleImageChange} />
            </div>
          </>
        ) : (
          <UploadImgProyecto handleImageChange={handleImageChange} />
        )}
      </div>
    </div>
  )
}

export default EditImageProyecto
