import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  getAllCelulasByProjectId,
  getImportCelulasByModuloId,
} from '../../api/jitApi'
import CelulasTable from './CelulasTable'

import ArticulosTableLoader from '../Loaders/ArticulosTableLoader'

const Celulas = () => {
  const { idProject } = useParams()

  const { data, isLoading } = useQuery(['celulas', idProject], () =>
    getAllCelulasByProjectId(idProject)
  )

  if (isLoading) return <ArticulosTableLoader />
  return (
    // <div
    //   style={{
    //     flex: 1,
    //     padding: '1rem',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     overflowY: 'hidden',
    //   }}
    // >
    <CelulasTable celulas={data.data} />
    // </div>
  )
}

export default Celulas
