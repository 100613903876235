import TextInput from 'components/ui/Forms/TextInput'
import { useArticuloBimLink } from 'context/ArticuloBimLinkContext'
import React from 'react'

const FormulaInput = () => {
  const { formula, handleFormula } = useArticuloBimLink()
  return (
    <TextInput
      label="Fórmula"
      name="formula"
      placeholder="0.17*%Área%"
      value={formula}
      onChange={handleFormula}
    />
  )
}

export default FormulaInput
