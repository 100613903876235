import { useQuery } from '@tanstack/react-query'
import { createContext, useEffect, useState } from 'react'
import { getPartidaProyecto } from '../api/jitApi'
import { useParams } from 'react-router-dom'

export const PresupuestoContext = createContext()

export const PresupuestoProvider = ({ children }) => {
  const { idPartidaProyecto, idProject } = useParams()
  const [isModuloPanelOpen, setIsModuloPanelOpen] = useState(false)
  const [currentPartida, setCurrentPartida] = useState(null)
  const [isPresupuestoVisible, setIsPresupuestoVisible] = useState(true)
  const [precioPartida, setPrecioPartida] = useState(null)
  const [showDescompuestos, setShowDescompuestos] = useState(false)
  const [showMediciones, setShowMediciones] = useState(false)
  const [loadingAddMedicion, setLoadingAddMedicion] = useState(false)
  const [isActiveDescompuestosDelete, setIsActiveDescompuestosDelete] =
    useState(false)
  const [descompuestosSelectedToDelete, setDescompuestosSelectedToDelete] =
    useState([])

  const { data: partidaProyecto, isLoading } = useQuery(
    ['partida', idPartidaProyecto],
    () => getPartidaProyecto(idProject, idPartidaProyecto),
    { enabled: !!idPartidaProyecto && !!idProject }
  )

  const handleDescompuestosToDelete = (isChecked, descompuestoId) => {
    // Descompuesto seleccionado, añadir al array
    if (isChecked) {
      setDescompuestosSelectedToDelete(prevState => [
        ...prevState,
        descompuestoId,
      ])
    } else {
      setDescompuestosSelectedToDelete(prevState =>
        prevState.filter(descompuesto => descompuesto !== descompuestoId)
      )
    }
  }

  useEffect(() => {
    if (!partidaProyecto) return
    console.log('partidaProyecto presupuestoContext', partidaProyecto)
    setCurrentPartida(partidaProyecto[0])
  }, [partidaProyecto])

  useEffect(() => {
    setShowDescompuestos(false)
    setShowMediciones(true)
    setIsActiveDescompuestosDelete(false)
    setDescompuestosSelectedToDelete([])
  }, [idPartidaProyecto, idProject])

  useEffect(() => {
    if (showDescompuestos) setShowMediciones(false)
    if (!showDescompuestos) {
      setDescompuestosSelectedToDelete([])
      setIsActiveDescompuestosDelete(false)
    }
  }, [showDescompuestos])

  useEffect(() => {
    if (showMediciones) setShowDescompuestos(false)
  }, [showMediciones])

  const value = {
    currentPartida, // se usa
    setCurrentPartida, // se usa
    isPresupuestoVisible, // se usa
    setIsPresupuestoVisible, // se usa
    isModuloPanelOpen, // se usa
    setIsModuloPanelOpen, // se usa
    showDescompuestos, // se usa
    setShowDescompuestos, // se usa
    showMediciones, // se usa
    setShowMediciones, // se usa
    loadingAddMedicion,
    setLoadingAddMedicion,
    isActiveDescompuestosDelete,
    setIsActiveDescompuestosDelete,
    handleDescompuestosToDelete,
    descompuestosSelectedToDelete,
    setDescompuestosSelectedToDelete,
    partidaProyectoLoading: isLoading, // se usa
  }

  // TODO: Quitar setPrecio donde se usa

  return (
    <PresupuestoContext.Provider value={value}>
      {children}
    </PresupuestoContext.Provider>
  )
}
