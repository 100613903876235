import axios from 'axios'

const handleCapitulos = async capitulos => {
  const allCapitulos = capitulos.map(part => part.capitulos).flat()

  const occurrences = allCapitulos.reduce(function (acc, curr) {
    return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc
  }, {})
  const keyCap = Object.keys(occurrences)
  const arrayObjects = []

  keyCap.forEach(key => {
    if (key.length == 2) {
      arrayObjects.push({ code: key, count: occurrences[key], children: [] })
    }
  })

  keyCap.forEach(key => {
    if (key.length == 3) {
      const index = arrayObjects.findIndex(el => key.includes(el.code))

      arrayObjects[index].children.push({
        code: key,
        count: occurrences[key],
        children: [],
      })
    }
  })

  keyCap.forEach(key => {
    if (key.length == 4) {
      const indexFirstLevel = arrayObjects.findIndex(el =>
        key.includes(el.code)
      )
      const indexSecondLevel = arrayObjects[indexFirstLevel].children.findIndex(
        el => key.includes(el.code)
      )

      arrayObjects[indexFirstLevel].children[indexSecondLevel].children.push({
        code: key,
        count: occurrences[key],
        children: [],
      })
    }
  })

  // setFunction(arrayObjects);

  return arrayObjects
}

export default handleCapitulos
