import { CustomButton } from 'components/ui'
import TextInput from 'components/ui/Forms/TextInput'
import { useArticulosTablePage } from 'context/ArticulosTablePageContext'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
const BuscadorArticulos = () => {
  const {
    handleOnChange,
    handleOnSubmit,
    inputValue,
    searchedValue,
    resetInfo,
  } = useArticulosTablePage()

  return (
    <div className="buscador-articulos-container">
      <form className="buscador-articulos-form" onSubmit={handleOnSubmit}>
        <TextInput
          placeholder="Buscar por código o nombre del artículo."
          value={inputValue}
          onChange={handleOnChange}
          containerStyles={{ width: '100%' }}
        />
        {searchedValue === '' && (
          <CustomButton
            type="submit"
            className="btn-secondary search-button-submit"
          >
            <SearchIcon className="search-icon-button" />
          </CustomButton>
        )}
      </form>
      {searchedValue !== '' && (
        <CustomButton
          className="btn-secondary search-button-submit"
          onClick={resetInfo}
        >
          <ClearIcon className="search-icon-button" />
        </CustomButton>
      )}
    </div>
  )
}

export default BuscadorArticulos
