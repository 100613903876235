import React from 'react'
import SidebarItemAdmin from './SidebarItemAdmin'
import { useAuth0 } from '@auth0/auth0-react'
import { ROLES_AUTH0 } from 'utils/constant/constantsRoles'

const routes = [
  {
    label: 'Artículos',
    href: '/articulos',
    icon: <ion-icon class="icon icon-admin" name="build-outline"></ion-icon>,
    value: 'articulos',
    role: ROLES_AUTH0.admin,
  },
  {
    label: 'Actividades',
    href: '/actividades',
    icon: <ion-icon class="icon icon-admin" name="server-outline"></ion-icon>,
    value: 'actividades',
    role: ROLES_AUTH0.admin,
  },
  {
    label: 'Proyectos',
    href: '/proyectos',
    icon: <ion-icon class="icon icon-admin" name="home-outline"></ion-icon>,
    value: 'proyectos',
    role: ROLES_AUTH0.master,
  },
]

const SidebarAdmin = () => {
  const { user } = useAuth0()

  return (
    <div style={{ width: '20rem', height: 'revert' }}>
      <nav className="sidebar sidebar-admin">
        <div className="sidebar-items sidebar-items-admin">
          {routes.map(
            (item, index) =>
              user.roleType.includes(item.role) && (
                <SidebarItemAdmin item={item} index={index} />
              )
          )}
        </div>
      </nav>
    </div>
  )
}

export default SidebarAdmin
