import React, { useState } from 'react'
import Popover from '@mui/material/Popover'

const CountActivitiesChecked = ({ checkedActivities }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  if (checkedActivities?.length == 0 || checkedActivities == undefined) return
  return (
    <>
      <div
        className="activities-count-title"
        aria-owns={open ? 'mouse-over-popover-configuration-length' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {checkedActivities.length}
      </div>
      <Popover
        id="mouse-over-popover-configuration-length"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="segmentation-configuration-title-counter__properties ">
          {checkedActivities.sort().map(selProp => (
            <div className="segmentation-configuration-title-counter__properties-item">
              <p style={{ fontWeight: '800' }}>{`${selProp}`}</p>
            </div>
          ))}
        </div>
      </Popover>
    </>
  )
}

export default CountActivitiesChecked
