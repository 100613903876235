import React, { useContext, useState } from 'react'
import { ViewerContext } from '../../context/ViewerContext'
import { redondearDecimalesToLocaleString } from '../../utils/roundNumber'
import { viewer } from '../../utils/launchViewer'
import ArticuloTableRowPrice from './ArticuloTableRowPrice'
const ArticuloTableRow = ({ articulos, articulo }) => {
  const { externalIdsMapping } = useContext(ViewerContext)
  const handleIsolate = articulo => {
    const dbIds = articulo.externalids.map(id => externalIdsMapping[id])
    viewer.isolate(dbIds)
  }

  return (
    <tr
      onClick={() => {
        handleIsolate(articulos?.find(art => art._id === articulo._id))
      }}
    >
      <td style={{ paddingLeft: '3rem' }}>
        {articulo.codeArticulo} - {articulo.nombreArticulo}
      </td>
      <td>
        {redondearDecimalesToLocaleString(articulo.quantity)}
        {articulo.unit || 'not-unit'}
      </td>
      <ArticuloTableRowPrice articulo={articulo} />
      <td>
        {redondearDecimalesToLocaleString(articulo.quantity * articulo.precio)}€
      </td>
    </tr>
  )
}

export default ArticuloTableRow
