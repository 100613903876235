import React from 'react'
import { NavLink } from 'react-router-dom'

const SidebarItemAdmin = ({ item, index }) => {
  return (
    <NavLink
      className="sidebar-item sidebar-item-horizontal"
      key={index}
      to={`/admin${item.href}`}
    >
      {item.icon}
      <span className="sidebar-item-label">{item.label}</span>
    </NavLink>
  )
}

export default SidebarItemAdmin
