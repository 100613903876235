const capitalizarPrimeraLetra = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

const capitalizarPalabras = str => {
  if (typeof str !== 'string') return str

  // Dividir el string en palabras
  var palabras = str.split(' ')

  // Iterar sobre cada palabra y capitalizar la primera letra
  for (var i = 0; i < palabras.length; i++) {
    palabras[i] =
      palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1).toLowerCase()
  }

  // Unir las palabras de nuevo en un solo string
  var resultado = palabras.join(' ')

  return resultado
}

const ordenarPorPropiedad = propiedad => {
  return function (a, b) {
    const valorA =
      typeof a[propiedad] === 'string'
        ? a[propiedad].toLowerCase()
        : a[propiedad]
    const valorB =
      typeof b[propiedad] === 'string'
        ? b[propiedad].toLowerCase()
        : b[propiedad]

    if (valorA < valorB) {
      return -1
    }
    if (valorA > valorB) {
      return 1
    }
    return 0
  }
}

const eliminarDuplicadosPorPropiedad = (array, propiedad) => {
  // Creamos un objeto temporal para almacenar los elementos únicos
  let temporal = {}

  // Iteramos sobre el array
  for (let elemento of array) {
    // Usamos el valor de la propiedad como clave del objeto temporal
    // Si ya existe esa clave, el elemento se considera duplicado y no lo agregamos
    temporal[elemento[propiedad]] = elemento
  }

  // Convertimos el objeto temporal de nuevo en un array
  let resultado = []
  for (let clave in temporal) {
    resultado.push(temporal[clave])
  }

  return resultado
}

function ordenarPorBoolean(array, ordenPorActiveAscendente) {
  // Función de comparación para ordenar por active y luego por code
  function comparar(a, b) {
    // Ordenar por active: true primero
    if (ordenPorActiveAscendente) {
      return a.visible ? -1 : 1
    } else {
      return a.visible ? 1 : -1
    }
  }

  // Usar la función de comparación para ordenar el array
  // return array.sort(comparar)
  return array.sort(comparar)
}

export {
  capitalizarPrimeraLetra,
  capitalizarPalabras,
  ordenarPorPropiedad,
  eliminarDuplicadosPorPropiedad,
  ordenarPorBoolean,
}
