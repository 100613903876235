import { CustomButton } from 'components/ui'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import { formatToCurrency, formatToDecimal } from 'utils/formatUnits'

const ArticulosRow = ({ articulo }) => {
  const totalPrice = Number(articulo.precio || 0) * articulo.quantity
  const navigate = useNavigate()
  return (
    <tr className="fabrica-table-header">
      <td>{articulo?.articulo?.code}</td>
      <td>{articulo?.articulo?.name}</td>
      <td className="ta-right">
        {formatToDecimal(articulo?.precio, { max: 4, min: 0 })}€
      </td>
      <td className="ta-right">
        {formatToDecimal(articulo?.quantity)}
        {articulo?.articulo?.unit}
      </td>
      <td className="ta-right">{formatToCurrency(totalPrice)}</td>
      <td className="container-buttons-row-articulo">
        <CustomButton
          type="secondary"
          className="button-edit-articulo-price"
          onClick={() => navigate(`${articulo.articulo._id}`)}
        >
          <EditIcon />
        </CustomButton>
      </td>
      {/* <td>Botones</td> */}
    </tr>
  )
}

export default ArticulosRow
