import ArticulosTableLoader from 'components/Loaders/ArticulosTableLoader'
import { ArticulosTablePageProvider } from 'context/ArticulosTablePageContext'
import { TiposProvider } from 'context/TiposContext'
import TiposTable from 'features/tipos/TiposTable'
import { useVersionModel } from 'hooks/useVersionModel'

function Tipos() {
  const { currentVersion } = useVersionModel()

  if (!currentVersion) return <ArticulosTableLoader />
  return (
    <ArticulosTablePageProvider>
      <TiposProvider>
        <TiposTable />
      </TiposProvider>
    </ArticulosTablePageProvider>
  )
}

export default Tipos
