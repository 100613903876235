import { getUUID } from 'lib/uuid/get-id.lib'
import { useBimLink } from './BimLinkContext'

import { createContext, useContext, useEffect, useMemo, useState } from 'react'

export const GroupBimLinkContext = createContext()

export const GroupBimLinkProvider = ({ group, children }) => {
  const { addGroup, updateGroup } = useBimLink()
  const [code, setCode] = useState(group?.code || null)
  const [name, setName] = useState(group?.name || null)
  const [typeBoq, setTypeBoq] = useState(group?.typeBoq || 1)
  const [rules, setRules] = useState(
    group?.rules?.map(rule => ({ uuid: getUUID(), ...rule })) || []
  )
  const [articulos, setArticulos] = useState(
    group?.articulos?.map(articulo => ({ uuid: getUUID(), ...articulo })) || []
  )
  const isEditGroupMode = useMemo(() => {
    return group ? true : false
  }, [group])

  const resetData = () => {
    setCode(null)
    setName(null)
    setTypeBoq(null)
    setRules([])
    setArticulos([])
  }

  const updateSingleGroup = () => {
    let updatedGroup = {}
    if (typeBoq == 2) {
      updatedGroup = { code, name, typeBoq, rules, articulos }
    } else {
      updatedGroup = { code, name, typeBoq, articulos }
    }

    updateGroup({ ...updatedGroup, uuid: group.uuid })
  }

  const createGroup = () => {
    // TODO: Aquí habrá que comprobar que todos los campos están rellenos
    // y que el código no está repetido
    if (typeBoq == 2) {
      addGroup({ code, name, typeBoq, rules, articulos })
    } else {
      addGroup({ code, name, typeBoq, articulos })
    }
    resetData()
  }

  const updateSingleArticulo = articulo => {
    setArticulos(prevState => {
      const index = prevState.findIndex(a => a.uuid === articulo.uuid)
      prevState[index] = articulo
      return [...prevState]
    })
  }

  const addArticulo = articulo => {
    setArticulos(prevState => [...prevState, { uuid: getUUID(), ...articulo }])
  }

  const addRule = rule => {
    setRules(prevState => [...prevState, { uuid: getUUID(), ...rule }])
  }

  const deleteArticulo = uuid => {
    setArticulos(prevState =>
      prevState.filter(articulo => articulo.uuid !== uuid)
    )
  }

  const deleteRule = uuid => {
    setRules(prevState => prevState.filter(rule => rule.uuid !== uuid))
  }

  useEffect(() => {
    setRules([])
  }, [typeBoq])

  const data = {
    code,
    handleCode: value => setCode(value.target.value),
    name,
    isEditGroupMode,
    handleName: value => setName(value.target.value),
    typeBoq,
    handleTypeBoq: value => setTypeBoq(value.target.value),
    updateSingleArticulo,
    createGroup,
    addArticulo,
    articulos,
    updateSingleGroup,
    rules,
    setRules,
    addRule,
    deleteRule,
    deleteArticulo,
  }

  return (
    <GroupBimLinkContext.Provider value={data}>
      {children}
    </GroupBimLinkContext.Provider>
  )
}

export const useGroupBimLink = () => {
  const context = useContext(GroupBimLinkContext)
  if (context === undefined) {
    throw new Error(
      'useGroupBimLink must be used within a GroupBimLinkProvider'
    )
  }
  return context
}
