import React, { useEffect, useState } from 'react'
import { getAllManoDeObra } from '../../../api/jitApi'
import { useQuery } from '@tanstack/react-query'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import TextInput from 'components/ui/Forms/TextInput'
import { OptionsItem, SelectInput } from 'components/ui/Forms/SelectInput'

const SelectorManoDeObra = ({ mo, setMo, manodeobra, projectMo }) => {
  const handleChange = event => {
    setMo(manodeobra.data.data.find(mo => mo._id === event.target.value))
  }

  useEffect(() => {
    setMo(manodeobra.data.data.find(el => el._id === projectMo))
  }, [])

  return (
    <FormControl
      fullWidth
      style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}
    >
      <div>
        <SelectInput
          labelId="rendimiento-mo"
          value={mo?._id || null}
          label="Rendimiento MO"
          name="rendimiento-mo"
          onChange={handleChange}
          style={{ width: '300px' }}
        >
          {manodeobra.data.data.map(moValue => (
            <OptionsItem
              name="rendimiento-mo"
              value={moValue._id}
            >{`${moValue.rendimiento}`}</OptionsItem>
          ))}
        </SelectInput>
      </div>
      <div>
        <TextInput
          label="Personas"
          value={mo?.personas || null}
          disabled
        ></TextInput>
      </div>
    </FormControl>
  )
}

export default SelectorManoDeObra
