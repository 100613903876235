import { useParams } from 'react-router-dom'
import ArticulosTableLoader from 'components/Loaders/ArticulosTableLoader'
import { useCelulasImportByModulo } from 'lib/react-query/queries'
import { ModuloProvider } from 'context'
import ModuloTable from 'components/Modulo/ModuloTable'
import StatsSingleModulo from 'components/Modulo/StatsSingleModulo'
import StatsSingleVinculo from 'components/Modulo/StatsSingleVinculo'
import { useContext, useEffect, useMemo, useState } from 'react'
import { viewer } from 'utils/launchViewer'
import { ViewerContext } from 'context/ViewerContext'
import { deepFlatten } from 'utils/arrayFunctions'
import { getAllExternalidsByModulo } from 'utils/handleModuloInfo'
import { CustomButton } from 'components/ui'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useVersionModel } from 'hooks/useVersionModel'
import { useGetVinculosByMatricula } from 'lib/react-query/queriesModuloType'

const OneModuloPage = () => {
  const { moduloId, idProject } = useParams()
  const { currentVersion } = useVersionModel()
  const {
    data: celulasInfo,
    isLoading: celulasLoading,
    isFetching: celulasIsFetching,
  } = useCelulasImportByModulo(idProject, moduloId, currentVersion)
  const { data: instances, isLoading: instancesLoading } =
    useGetVinculosByMatricula()

  const { externalIdsMapping } = useContext(ViewerContext)
  const [isViewerIsolate, setIsViewerIsolate] = useState(false)

  const allDbIds = useMemo(() => {
    if (!celulasInfo || !externalIdsMapping) return []
    const allExternalIds = deepFlatten(
      getAllExternalidsByModulo(celulasInfo.data.celulas)
    ).filter(item => item !== null)
    return allExternalIds.map(externalId => externalIdsMapping[externalId])
  }, [celulasInfo, externalIdsMapping])

  useEffect(() => {
    if (allDbIds.length > 0) {
      viewer.isolate(allDbIds)
      setIsViewerIsolate(true)
    }
  }, [allDbIds])

  const handleOnClick = () => {
    if (isViewerIsolate) {
      viewer.isolate([])
      setIsViewerIsolate(false)
    } else {
      viewer.isolate(allDbIds)
      setIsViewerIsolate(true)
    }
  }

  if (
    !celulasInfo ||
    celulasLoading ||
    celulasIsFetching ||
    !instances ||
    instancesLoading
  )
    return <ArticulosTableLoader />

  return (
    <div className="modulo-page-wrapper">
      <CustomButton
        type="secondary"
        className="isolate-modulo-button"
        onClick={handleOnClick}
      >
        <RemoveRedEyeIcon />
        {'Aislar matrícula / Mostrar modelo'}
      </CustomButton>
      <div className="stats-modulo-container">
        <div>
          <StatsSingleModulo celulasInfo={celulasInfo} />
        </div>
        <div>
          <StatsSingleVinculo celulasInfo={celulasInfo} />
        </div>
      </div>
      <ModuloProvider modulo={celulasInfo?.data.celulas}>
        <ModuloTable />
      </ModuloProvider>
    </div>
  )
}

export { OneModuloPage }
