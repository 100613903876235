import TextInput from 'components/ui/Forms/TextInput'
import { CustomButton } from 'components/ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { useGroupBimLink } from 'context/GroupBimLinkContext'
import RulesGroupBimLink from './GroupBimLink/RulesGroupBimLink'
import TypeBoqBimLink from './GroupBimLink/TypeBoqBimLink'
import ArticulosGroupBimLink from './GroupBimLink/ArticulosGroupBimLink'
import { useMemo } from 'react'
import { Tooltip } from 'react-tooltip'

const NewGroupBimlink = () => {
  const {
    code,
    handleCode,
    name,
    handleName,
    typeBoq,
    createGroup,
    articulos,
    updateSingleGroup,
    isEditGroupMode,
    rules,
  } = useGroupBimLink()
  console.log(code, name, typeBoq, articulos, rules)
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const disabled = useMemo(() => {
    if (!code || !name || articulos.length == 0) return true

    if (typeBoq == 2) {
      if (rules.length == 0) return true
      return false
    } else {
      return false
    }
  }, [code, name, articulos, rules, typeBoq])

  const handleBack = () => {
    if (isEditGroupMode) {
      navigate(changeLocation(location.pathname.split('/edit-group')[0]))
    } else {
      navigate(changeLocation(location.pathname.replace('/group', '')))
    }
  }

  const handleCreateGroup = () => {
    isEditGroupMode ? updateSingleGroup() : createGroup()

    handleBack()
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className="new-bimlink-body-container overflow-auto">
        <h1 className="new-bimlink-title">
          {isEditGroupMode ? 'Editar grupo' : 'Crear grupo'}
        </h1>
        <div className="new-bimlink-form overflow-unset">
          <TextInput
            label="Código"
            placeholder="EIFC-AA01"
            name="code"
            value={code}
            onChange={handleCode}
          />
          <TextInput
            label="Nombre"
            placeholder="Tubería Agua PEX 16mm"
            value={name}
            onChange={handleName}
          />
          <TypeBoqBimLink />

          {typeBoq == 2 && <RulesGroupBimLink />}
          <ArticulosGroupBimLink />
        </div>
      </div>
      <div className="new-bimlink-footer-buttons">
        <CustomButton type="secondary" onClick={handleBack}>
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={handleCreateGroup}
          disabled={disabled}
          className="create-group-button"
        >
          {isEditGroupMode ? 'Editar grupo' : 'Crear grupo'}
        </CustomButton>
        <Tooltip
          anchorSelect=".create-group-button"
          style={{
            fontSize: '9px',
            display: disabled ? 'block' : 'none',
          }}
        >
          No puede haber ningún campo vacío.
        </Tooltip>
      </div>
    </div>
  )
}

export default NewGroupBimlink
