import NewBimLink from 'components/Bimlink/NewBimLink'
import Modal from 'components/ui/Modal/Modal'
import { BimLinkProvider } from 'context/BimLinkContext'
import { useHandleUrl } from 'hooks/useHandleUrl'
import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

export const NewBimlinkPageWrapper = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { changeLocation } = useHandleUrl()

  const handleOnClose = () => {
    navigate(changeLocation(location.pathname.split('/new-bimlink')[0]))
  }
  return (
    <Modal
      modalClass="new-bimlink-modal"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <BimLinkProvider>
        <div className="new-bimlink-container">
          <div className="new-bimlink-body">
            <Outlet />
          </div>
        </div>
      </BimLinkProvider>
    </Modal>
  )
}

export const NewBimlinkPage = () => {
  return <NewBimLink />
}
