export const getPropertiesAsync = (viewer, dbId) => {
  return new Promise((resolve, reject) => {
    viewer.getProperties(
      dbId,
      res => {
        resolve(res)
      },
      err => {
        reject(err)
      }
    )
  })
}

export const getBulkPropertiesAsync = (viewer, dbIds, properties = []) => {
  return new Promise((resolve, reject) => {
    try {
      viewer.model.getBulkProperties(
        dbIds,
        properties,
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    } catch (err) {
      console.log('ERRORrrrrr EN EL BULK PROPERTIES: ', err.message)
    }
  })
}

export const getBulkProperties2Async = (viewer, dbIds, properties = []) => {
  return new Promise((resolve, reject) => {
    try {
      viewer.model.getBulkProperties2(
        dbIds,
        {
          propFilter: properties,
          needsExternalId: true,
        },
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    } catch (err) {
      console.log('ERRORrrrrr EN EL BULK PROPERTIES: ', err.message)
    }
  })
}


export const findLeafNodes = viewer => {
  return new Promise(function (resolve, reject) {
    viewer.model.getObjectTree(function (tree) {
      let leaves = []
      tree.enumNodeChildren(
        tree.getRootId(),
        function (dbId) {
          if (tree.getChildCount(dbId) === 0) {
            leaves.push(dbId)
          }
        },
        true
      )
      resolve(leaves)
    }, reject)
  })
}

export const findPropertynamesCb = (viewer, cb) => {
  findLeafNodes(viewer).then(dbIds => {
    const groupSize = 1000
    const groups = []
    for (let i = 0; i < dbIds.length; i += groupSize) {
      groups.push(dbIds.slice(i, i + groupSize))
    }
    const promises = groups.map(group => {
      return new Promise((resolve, reject) => {
        viewer.model.getBulkProperties(
          group,
          {},
          res => {
            let propNames = new Set()
            for (const result of res) {
              for (const prop of result.properties) {
                propNames.add(prop.displayName)
              }
            }
            resolve(Array.from(propNames.values()))
          },
          err => {
            reject(err)
          }
        )
      })
    })
    Promise.all(promises)
      .then(propNamesList => {
        let allPropNames = new Set()
        for (const propNames of propNamesList) {
          for (const propName of propNames) {
            allPropNames.add(propName)
          }
        }
        cb(Array.from(allPropNames.values()))
      })
      .catch(err => {
        console.error(err)
      })
  })
}

export const findPropertyNames = async viewer => {
  const dbIds = await findLeafNodes(viewer)
  return new Promise(function (resolve, reject) {
    viewer.model.getBulkProperties(
      dbIds,
      {},
      function (results) {
        let propNames = new Set()
        for (const result of results) {
          for (const prop of result.properties) {
            propNames.add(prop.displayName)
          }
        }
        resolve(Array.from(propNames.values()))
      },
      reject
    )
  })
}
