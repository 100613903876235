import { createContext, useContext, useState } from 'react'

const ArticulosTablePageContext = createContext()

export const ArticulosTablePageProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState('')
  const [searchedValue, setSearchedValue] = useState('')

  const handleOnChange = e => {
    setInputValue(e.target.value)
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    setSearchedValue(inputValue)
  }

  const resetInfo = () => {
    setInputValue('')
    setSearchedValue('')
  }

  const value = {
    inputValue,
    searchedValue,
    handleOnChange,
    handleOnSubmit,
    resetInfo,
  }
  return (
    <ArticulosTablePageContext.Provider value={value}>
      {children}
    </ArticulosTablePageContext.Provider>
  )
}

export const useArticulosTablePage = () => {
  const context = useContext(ArticulosTablePageContext)
  if (!context) {
    throw new Error(
      'useArticulosTablePage must be used within a ArticulosTablePageProvider'
    )
  }
  return context
}
