import { CustomButton } from 'components/ui'
import React, { useEffect, useRef, useState } from 'react'
import { redondearDecimalesToLocaleString } from 'utils/roundNumber'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { useUpdatePriceArticuloProyecto } from 'lib/react-query/queries'
const ArticuloTableRowPrice = ({ articulo }) => {
  const inputRef = useRef(null)
  const [isEdit, setIsEdit] = useState(false)
  const [precio, setPrecio] = useState(articulo.precio || 1)
  const { mutateAsync: updateProject } =
    useUpdatePriceArticuloProyecto()

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus()
    } else {
      setPrecio(articulo.precio || 1)
    }
  }, [isEdit])

  const handleBlur = () => {
    setTimeout(() => {
      setIsEdit(false)
    }, 100)
  }

  const handleOnClick = e => {
    e.stopPropagation()
    updateProject({ id: articulo.precioArticuloProyecto, body: { precio } })
    setIsEdit(false)
  }

  const handleOnKeyDown = e => {
    if (e.key === 'Enter' && precio.length > 0) {
      handleOnClick(e)
    }
  }
  return (
    <>
      {!isEdit && (
        <td
          className="price-articulo-container price-articulo-container--isEdit"
          onClick={e => {
            e.stopPropagation()
            setIsEdit(true)
          }}
        >
          {redondearDecimalesToLocaleString(articulo.precio, 4)}€/
          {articulo.unit || 'not-unit'}{' '}
          <CustomButton type="secondary" className="button-edit-articulo-price">
            <EditIcon />
          </CustomButton>
        </td>
      )}
      {isEdit && (
        <td className="price-articulo-container price-articulo-container--isNotEdit">
          {
            <input
              ref={inputRef}
              value={precio}
              type="number"
              onChange={e => setPrecio(e.target.value)}
              onBlur={handleBlur}
              onKeyDown={precio.length > 0 && handleOnKeyDown}
              className="number-input input-edit-articulo-price"
            />
          }
          <CustomButton
            type="secondary"
            className="button-edit-articulo-price"
            onClick={() => setIsEdit(false)}
          >
            <CloseIcon />
          </CustomButton>
          <CustomButton
            className="button-edit-articulo-price"
            onClick={handleOnClick}
            disabled={precio == ''}
          >
            <CheckIcon />
          </CustomButton>
        </td>
      )}
    </>
  )
}

export default ArticuloTableRowPrice
