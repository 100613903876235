import SelectDropdown from 'components/ui/Forms/SelectDropdown'
import { useAddUsersToProject } from 'context/AddUsersToProjectContext'
import { useState } from 'react'
import SelectUserItem from './SelectUserItem'

const SelectDropdownUsers = () => {
  const [showOptions, setShowOptions] = useState(false)
  const { addUser, users, inputValue, handleInputValue } =
    useAddUsersToProject()

  return (
    <SelectDropdown
      showOptions={showOptions}
      setShowOptions={setShowOptions}
      label="Usuarios"
      name="Usuarios"
      containerClassName="select-dropdown-add-user"
      selectValue={inputValue}
      onChangeValue={handleInputValue}
      placeholder="Escribe al menos 3 letras para empezar a filtrar"
    >
      {users.map(user => (
        <SelectUserItem key={user.user_id} user={user} addUser={addUser} />
      ))}
      {users.length === 0 && (
        <p className="text-not-data-found">No hay usuarios disponibles</p>
      )}
    </SelectDropdown>
  )
}

export default SelectDropdownUsers
