import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateMatriculas } from 'api/estructuraCostes/rvtlinksJitApi'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

export const useUpdateMatriculas = () => {
  const { idProject } = useParams()
  const queryClient = useQueryClient()
  let toastId
  return useMutation({
    mutationFn: version => updateMatriculas(idProject, version),
    onMutate: () => {
      toastId = toast.loading('Actualizando matrículas...')
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['all-matriculas', idProject])
      queryClient.invalidateQueries(['modulos-articulos', idProject])
      toast.dismiss(toastId)
      toast.success('Matrículas actualizadas correctamente')
    },
    onError: () => {
      toast.dismiss(toastId)
      toast.error('Error al actualizar matrículas')
    },
  })
}
