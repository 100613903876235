import { Chip } from '@mui/material'
import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const AdminsToProject = ({ admins, allUsers }) => {
  const { user } = useAuth0()
  return (
    <div className="d-flex d-column pb-4">
      <p className="gray-text mb-2">Administradores del proyecto</p>
      <div className="d-flex d-wrap g-04">
        <Chip label={user.email} className="chip-border" />
        {admins?.map(admin =>
          allUsers.map(u => {
            if (u.id == admin) {
              return <Chip className="chip-border" label={u.email} />
            }
          })
        )}
      </div>
    </div>
  )
}

export default AdminsToProject
