import Calendar from 'components/ui/Forms/Calendar'
import TextInput from 'components/ui/Forms/TextInput'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/ui/Popover/Popover'
import { format } from 'date-fns'
import EventIcon from '@mui/icons-material/Event'

const DatesInputsCalendar = ({ label, name, date, handleChange, ...props }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="input-calendar-container">
          <TextInput
            label={label}
            name={name}
            value={date ? format(date, 'dd/MM/yyyy') : null}
            style={{ cursor: 'pointer' }}
            autocomplete="off"
            readonly
            {...props}
          />

          <EventIcon className="event-icon-calendar" />
        </div>
      </PopoverTrigger>
      <PopoverContent className="custom-body-100" align="start">
        <Calendar selected={date} onDayClick={handleChange} />
      </PopoverContent>
    </Popover>
  )
}

export default DatesInputsCalendar
