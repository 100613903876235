import React from 'react'
import { ordenarPorPropiedad } from '../../utils/formatText'
import CelulaActivity from './CelulaActivity'
import CelulaActivityModulo from './CelulaActivityModulo'

const CelulaActivities = ({ activities, showArticulos = true }) => {
  return (
    <tr
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        width: '100%',
        paddingLeft: '1.3rem',
      }}
    >
      {activities.sort(ordenarPorPropiedad('code')).map(activity => (
        <CelulaActivity
          activity={activity}
          key={activity?._id}
          showArticulos={showArticulos}
        />
      ))}
    </tr>
  )
}

export default CelulaActivities
