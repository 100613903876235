import TextInput from 'components/ui/Forms/TextInput'
import { useGetAllActivities } from 'lib/react-query/queries'
import { useEffect, useMemo, useRef, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import SearchOptionsGroup, {
  SearchOptionsGroupItem,
} from './SearchOptionsGroup'
import { compararPorPropiedad } from 'components/Celulas/NewCelula/ActivitiesCheckbox'
import { useClickOutside } from 'hooks/useClickOutside'
import { useArticuloBimLink } from 'context/ArticuloBimLinkContext'

export const ActividadesInput = () => {
  const { data } = useGetAllActivities()
  const rootEl = useRef(null)
  const { selectedActividad, setSelectedActividad } = useArticuloBimLink()
  const [textInput, setTextInput] = useState(
    selectedActividad
      ? `${selectedActividad?.code} - ${selectedActividad?.name}`
      : ''
  )

  const [showOptions, setShowOptions] = useState(false)
  const absoluteEl = useRef(null)

  const resetInfo = () => {
    setShowOptions(false)
    if (!showOptions) return

    if (selectedActividad) {
      setTextInput(`${selectedActividad.code} - ${selectedActividad.name}`)
    } else {
      setTextInput('')
    }
  }
  useClickOutside(rootEl, resetInfo)

  const nameInputSelectedActividad = useMemo(() => {
    if (selectedActividad) {
      return `${selectedActividad.code} - ${selectedActividad.name}`
    } else {
      return null
    }
  }, [selectedActividad])

  const handleSelectedActividad = actividad => {
    setSelectedActividad(actividad)
    setTextInput(`${actividad.code} - ${actividad.name}`)
    setShowOptions(false)
  }
  const filterData = options => {
    if (selectedActividad) {
      if (
        textInput === `${selectedActividad.code} - ${selectedActividad.name}`
      ) {
        return options
      }
    }

    if (textInput.length >= 2) {
      return options.filter(
        articulo =>
          articulo.code.toLowerCase().includes(textInput.toLowerCase()) ||
          articulo.name.toLowerCase().includes(textInput.toLowerCase())
      )
    }

    return data?.data.data
  }

  const handleMenuOpen = () => {
    setShowOptions(true)
  }

  useEffect(() => {
    if (!textInput) {
      setSelectedActividad(null)
    }
  }, [textInput])
  return (
    <div style={{ position: 'relative' }} ref={rootEl}>
      <TextInput
        label="Actividades"
        name="activities"
        onChange={e => setTextInput(e.target.value)}
        placeholder="Buscar por código o nombre de actividad. Escribe, al menos, dos caracteres"
        value={textInput || nameInputSelectedActividad || ''}
        onClick={handleMenuOpen}
        autoComplete="off"
      />
      {selectedActividad && (
        <div
          className="icon-text-input-reset"
          onClick={() => {
            setSelectedActividad(null)
            setTextInput('')
          }}
        >
          <ClearIcon />
        </div>
      )}
      {showOptions && (
        <SearchOptionsGroup ref={absoluteEl} width={rootEl?.current}>
          {data ? (
            filterData(data?.data.data).length == 0 ? (
              <div style={{ padding: '1rem', fontStyle: 'oblique' }}>
                No hay ningún elemento que coincida con la búsqueda
              </div>
            ) : (
              filterData(data?.data.data)
                .sort(compararPorPropiedad('code'))
                .map(articulo => (
                  <SearchOptionsGroupItem
                    title={`${articulo.code} - ${articulo.name}`}
                    item={articulo}
                    handleSelectedItem={handleSelectedActividad}
                    isSelected={selectedActividad?._id === articulo._id}
                  />
                ))
            )
          ) : null}
        </SearchOptionsGroup>
      )}
    </div>
  )
}
