const THREE = window.THREE

export const setColorByCode = (data, code) => {
  let filterData = data.filter(item => item.code === code)
  // console.log(filterData, 'ESTE ES MI CODE FILTRADO')
  // data.find((item) => {
  //   if (item.code === code) {
  //     console.log(item)
  //     // return item.color
  //   }
  // })

  // const selectedColor = colors[code];
  // if (selectedColor) return selectedColor;

  // return colors.default;

  return hexToVector4(filterData[0]?.color)
}

export const setColorByCodeRGB = code => {
  const selectedColor = colorsRGB[code]
  if (selectedColor) return selectedColor

  return colorsRGB.default
}

export const baseColors = {
  red: new THREE.Vector4(255 / 255, 0 / 255, 0 / 255, 1),
}

export const hexToVector4 = hex => {
  const rgbColor = parseInt(hex?.slice(1), 16) // eliminar el símbolo # y convertir a entero
  const r = (rgbColor >> 16) & 255 // obtener el valor de rojo
  const g = (rgbColor >> 8) & 255 // obtener el valor de verde
  const b = rgbColor & 255 // obtener el valor de azul

  const color = new THREE.Vector4(r / 255, g / 255, b / 255, 0.8)

  return color
}

export const colors = {
  '2D-P4-D1': new THREE.Vector4(84 / 255, 255 / 255, 0 / 255, 0.8), //green
  '2D-PT-D1': new THREE.Vector4(38 / 255, 189 / 255, 167 / 255, 0.8), //dark green
  '2D-PT-E1': new THREE.Vector4(255 / 255, 0 / 255, 0 / 255, 0.8), //red
  '2D-PT-E2': new THREE.Vector4(255 / 255, 255 / 255, 0 / 255, 0.8), //yellow
  '2D-PT-E3': new THREE.Vector4(255 / 255, 163 / 255, 15 / 255, 0.8), // orange
  '3D-P4-D1': new THREE.Vector4(0 / 255, 12 / 255, 255 / 255, 0.8), //blue
  rest: new THREE.Vector4(255 / 255, 255 / 255, 255 / 255, 0.8),
  default: new THREE.Vector4(84 / 255, 255 / 255, 0 / 255, 1), //green
}

export const colorsRGB = {
  '2D-P4-D1': 'rgba(84,255,0, 0.8)', //green
  '2D-PT-D1': 'rgba(38, 189, 167, 0.8)', //dark green
  '2D-PT-E1': 'rgba(255, 0, 0, 0.8)', //red
  '2D-PT-E2': 'rgba(255, 255, 0, 0.8)', //yellow
  '2D-PT-E3': 'rgba(255, 163, 15, 0.8)', //orange
  '3D-P4-D1': 'rgba(0, 12, 255, 0.8)', //blue
  rest: 'rgba(255, 255, 255, 0.8)',
  default: 'rgba(84, 255, 0, 0.8)', //green
}

export const colorsDescompuestos = type => {
  switch (type) {
    case 'Porcentaje':
      return '#c3ffb2'

    case 'Material indirecto':
      return '#c3d7ffb2'

    default: {
      return 'inherit'
    }
  }
}
