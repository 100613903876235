import ViewerLayoutProvider from 'components/Providers/ViewerLayoutProvider'
import React from 'react'
import ViewerLayout from './ViewerLayout'

const ViewerLayoutWrapper = () => {
  return (
    <ViewerLayoutProvider>
      <ViewerLayout />
    </ViewerLayoutProvider>
  )
}

export default ViewerLayoutWrapper
