import FormNewActividad from 'components/admin/Actividades/FormNewActividad/FormNewActividad'
import Modal from 'components/ui/Modal/Modal'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NewActividadAdminPage = () => {
  console.log('entro aquí')
  const navigate = useNavigate()
  const handleOnClose = () => {
    navigate('/admin/actividades')
  }

  return (
    <Modal
      modalClass="modal modal-new-articulo"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div className="modal-link-bimlink-to-tipo-container--width">
        <h2>Crear actividad</h2>
        <FormNewActividad />
      </div>
    </Modal>
  )
}

export default NewActividadAdminPage
