import { useQuery } from '@tanstack/react-query'
import { getOneBimLink } from 'api/estructuraCostes/bimLinksJitApi'

export const useGetBimlinkById = bimlinkId => {
  return useQuery({
    queryKey: ['bimlink', bimlinkId],
    queryFn: () => getOneBimLink(bimlinkId),
    retry: false,
  })
}
