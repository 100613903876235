const totalActivityImport = activity => {
  return activity.articulos.reduce(
    (total, item) => total + item.quantity * item.precio,
    0
  )
}

const totalCelulaImport = celula => {
  return celula.activities.reduce((total, activity) => {
    return total + totalActivityImport(activity)
  }, 0)
}

const totalModuloImport = celulasInfo => {
  return celulasInfo.data.celulas.reduce((total, celula) => {
    return total + totalCelulaImport(celula)
  }, 0)
}

const getAllExternalidsByModulo = data => {
  return data.map(celula =>
    celula.activities.map(activity => {
      if (activity.articulos.length == 0) return null
      return activity.articulos.map(articulo => articulo.externalids)
    })
  )
}

export { totalModuloImport, getAllExternalidsByModulo }
