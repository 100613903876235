import React from 'react'

const UploadImgProyecto = ({ handleImageChange }) => {
  return (
    <div className="not-image-project-container">
      <div className="not-image-icons">
        <ion-icon
          name="cloud-upload-outline"
          style={{ fontSize: '10rem' }}
        ></ion-icon>
        <h3>Subir imagen del proyecto</h3>
      </div>
      <input type="file" accept="image/*" onChange={handleImageChange}></input>
    </div>
  )
}

export default UploadImgProyecto
