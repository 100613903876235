import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  ButtonsContainerCosteManoDeObra,
  CosteManoDeObra,
  SelectorManoDeObra,
  TotalCosteManoDeObra,
  TotalDiasPorModulo,
} from './ManoDeObraForm'
import { ViewerContext } from '../../context/ViewerContext'
import { getAllModulos } from '../../utils/handleModulo'
import ArticulosTableLoader from '../Loaders/ArticulosTableLoader'
import { useGetManoDeObra } from '../../lib/react-query/queries'
import TextInput from 'components/ui/Forms/TextInput'
import { useTotalMo } from 'hooks/useTotalMo'
import { useGetModulosByProject } from 'lib/react-query/queriesModuloType'

const CreateManoDeObraWrapper = () => {
  const { idProject } = useParams()
  const { data: proyecto } = useQuery(['project', idProject], null)
  const { data: manodeobra } = useGetManoDeObra()
  const { data: modulosType } = useGetModulosByProject()

  const [mo, setMo] = useState(
    manodeobra?.data?.data.find(
      mo => mo._id === proyecto?.data?.proyecto?.manoDeObraRendimiento?._id
    ) || null
  )

  const [costeMo, setCosteMo] = useState(
    proyecto?.data?.proyecto?.manoDeObraCoste || null
  )
  const [modulos, setModulos] = useState(null)
  const { externalIdsMapping } = useContext(ViewerContext)

  // useEffect(() => {
  //   if (externalIdsMapping) {
  //     getAllModulos(externalIdsMapping).then(res => setModulos(res.length))
  //   }
  // }, [externalIdsMapping])

  const bodyProject = useMemo(() => {
    return {
      manoDeObraRendimiento: mo?._id,
      manoDeObraCoste: Number(costeMo),
    }
  }, [mo, costeMo])

  if (!modulosType || !manodeobra) return <ArticulosTableLoader />

  return (
    <div className="create-mo-wrapper">
      <div>
        <TextInput
          label="Total de módulos"
          value={modulosType.instances || null}
          disabled
          containerStyles={{ width: '300px' }}
        />
      </div>
      <SelectorManoDeObra
        mo={mo}
        setMo={setMo}
        manodeobra={manodeobra}
        projectMo={proyecto?.data?.proyecto?.manoDeObraRendimiento?._id}
      />
      <CosteManoDeObra costeMo={costeMo} setCosteMo={setCosteMo} />
      {!mo || !costeMo ? (
        <p
          style={{ textAlign: 'center', margin: '3rem', fontStyle: 'oblique' }}
        >
          Completa los campos "Rendimiento MO" y "Coste mano de obra" para para
          ver la información
        </p>
      ) : (
        <>
          <TotalDiasPorModulo totalModulos={modulosType.instances} mo={mo} />
          <TotalCosteManoDeObra
            totalModulos={modulosType.instances}
            mo={mo}
            costeMo={costeMo}
          />
        </>
      )}
      <ButtonsContainerCosteManoDeObra
        disabled={!mo || !costeMo}
        body={bodyProject}
      />
    </div>
  )
}

export default CreateManoDeObraWrapper
