import React from 'react'
import Box from '../Box/Box'

export const StatData = ({ children }) => {
  return <p className="stats-box__value">{children}</p>
}

export const StatSubData = ({ children }) => {
  return <p className="stats-box__subvalue">{children}</p>
}

const StatsBox = ({ title, Icon, children, ...props }) => {
  return (
    <Box style={{ flex: '1' }} {...props}>
      <div className="stats-box">
        <h3 className="stats-box__title">{title}</h3>
        {Icon && <Icon fontSize="large" />}
      </div>
      {children}
    </Box>
  )
}

export default StatsBox
