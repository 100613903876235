import ButtonUpdateMatriculas from 'components/ConfigMatriculas/ButtonUpdateMatriculas'
import ConfigMatriculasBuscador from 'components/ConfigMatriculas/ConfigMatriculasBuscador'
import ConfigMatriculasTable from 'components/ConfigMatriculas/ConfigMatriculasTable'
import { ConfigMatriculasProvider } from 'context/ConfigMatriculasContext'
import React from 'react'

const ConfigMatriculas = () => {
  return (
    <ConfigMatriculasProvider>
      <div className="row-buscador-container">
        <ConfigMatriculasBuscador />
        <ButtonUpdateMatriculas />
      </div>
      <ConfigMatriculasTable />
    </ConfigMatriculasProvider>
  )
}

export default ConfigMatriculas
