import Modal from 'components/ui/Modal/Modal'
import { BimLinkProvider } from 'context/BimLinkContext'
import { EditBimLinkProvider } from 'context/EditBimLinkContext'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { useGetBimlinkById } from 'lib/react-query/queriesBimLink'
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

const EditBimLinkPageWrapper = () => {
  const { bimlinkId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { changeLocation } = useHandleUrl()
  const { data, error, isLoading } = useGetBimlinkById(bimlinkId)

  const handleOnClose = () => {
    navigate(changeLocation(location.pathname.split('/edit-bimlink')[0]))
  }

  if (error)
    return (
      <Navigate
        to={changeLocation(location.pathname.split('/edit-bimlink')[0])}
      />
    )

  if (isLoading) return
  return (
    <BimLinkProvider bimlink={data.data}>
      <Modal
        modalClass="new-bimlink-modal"
        containerChildrenClass={'new-bimlink-container'}
        onClose={handleOnClose}
      >
        <div className="new-bimlink-container">
          <div className="new-bimlink-body">
            <Outlet />
          </div>
        </div>
      </Modal>
    </BimLinkProvider>
  )
}

export default EditBimLinkPageWrapper
