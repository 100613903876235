import React, { createContext,  useState } from 'react'

export const DashboardContext = createContext()

export const DashboardProvider = ({ children }) => {
  const [currentProject, setCurrentProject] = useState(null)

  const data = {
    currentProject,
    setCurrentProject,
  }

  return (
    <DashboardContext.Provider value={data}>
      {children}
    </DashboardContext.Provider>
  )
}
