import { useAuth0 } from '@auth0/auth0-react'
import Page404 from 'pages/Error404'
import { useEffect, useState } from 'react'
import { isAuthorizedByRole } from 'utils/auth0'

const ProtectedRouteByRole = ({ role, children }) => {
  const { user } = useAuth0()
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (user) {
      const authorized = isAuthorizedByRole(user, role)
      setIsAuthorized(authorized)
      setIsLoading(false)
    }
  }, [user, role])

  if (!user || isLoading) return null
  if (user && !isAuthorized) return <Page404 />
  if (user && isAuthorized) return <>{children}</>
}

export default ProtectedRouteByRole
