import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'
import EngineeringIcon from '@mui/icons-material/Engineering'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import StatsBox, { StatData } from 'components/ui/StatsBox/StatsBox'
import { redondearDecimalesToLocaleString } from 'utils/roundNumber'
import { totalModuloImport } from 'utils/handleModuloInfo'
import { useTotalMo } from 'hooks/useTotalMo'

const StatsSingleVinculo = ({ celulasInfo }) => {
  const { totalMoByVinculo } = useTotalMo()

  const instances = celulasInfo?.data?.modulo?.instances.length
  return (
    <>
      <h2 className="stats-modulo-title">Único vínculo: </h2>
      <div className="modulo-stats-container">
        <StatsBox title="Importe artículos" Icon={WorkspacesIcon}>
          <StatData>
            {redondearDecimalesToLocaleString(
              totalModuloImport(celulasInfo) / instances
            )}
            €
          </StatData>
        </StatsBox>
        <StatsBox title="Coste mano de obra" Icon={EngineeringIcon}>
          <StatData>
            {redondearDecimalesToLocaleString(totalMoByVinculo)}€
          </StatData>
        </StatsBox>
        <StatsBox title="Importe total" Icon={EuroSymbolIcon}>
          <StatData>
            {redondearDecimalesToLocaleString(
              totalModuloImport(celulasInfo) / instances + totalMoByVinculo
            )}
            €
          </StatData>
        </StatsBox>
      </div>
    </>
  )
}

export default StatsSingleVinculo
