import { useCustomPagination } from 'hooks/useCustomPagination'
import React from 'react'
import { CustomButton } from '..'

const Pagination = ({ count = 2, currentPage, totalPages, handlePage }) => {
  const { obtenerNumeros } = useCustomPagination()
  const leftNumbers = obtenerNumeros(
    'anteriores',
    currentPage,
    count,
    totalPages
  )
  const rightNumbers = obtenerNumeros(
    'siguientes',
    currentPage,
    count,
    totalPages
  )

  return (
    <div className="pagination-container">
      {leftNumbers.length === 0 ? null : (
        <CustomButton
          type="secondary"
          onClick={() => handlePage(currentPage - 1)}
          className="button-pagination"
        >
          {'<'}
        </CustomButton>
      )}
      {leftNumbers.length === 0 || Math.min(...leftNumbers) === 1 ? null : (
        <CustomButton
          type="secondary"
          onClick={() => handlePage(1)}
          className="button-pagination"
        >
          {1}
        </CustomButton>
      )}
      {leftNumbers.length > 0 && Math.min(...leftNumbers) > 2 && (
        <p className="more-info-points">...</p>
      )}
      {leftNumbers.map(number => (
        <CustomButton
          type="secondary"
          className="button-pagination"
          key={number}
          onClick={() => handlePage(number)}
        >
          {number}
        </CustomButton>
      ))}
      <CustomButton
        onClick={() => handlePage(currentPage)}
        className="button-pagination"
      >
        {currentPage}
      </CustomButton>
      {rightNumbers.map(number => (
        <CustomButton
          className="button-pagination"
          type="secondary"
          key={number}
          onClick={() => handlePage(number)}
        >
          {number}
        </CustomButton>
      ))}
      {rightNumbers.length > 0 &&
        totalPages > Math.max(...rightNumbers) + 1 && (
          <p className="more-info-points">...</p>
        )}
      {totalPages === Math.max(...rightNumbers) ||
      rightNumbers.length === 0 ? null : (
        <CustomButton
          type="secondary"
          className="button-pagination"
          onClick={() => handlePage(totalPages)}
        >
          {totalPages}
        </CustomButton>
      )}
      {rightNumbers.length > 0 && (
        <CustomButton
          type="secondary"
          className="button-pagination"
          onClick={() => handlePage(currentPage + 1)}
        >
          {'>'}
        </CustomButton>
      )}
    </div>
  )
}

export default Pagination
