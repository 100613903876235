import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient, useQuery, isError } from '@tanstack/react-query'
import {
  getCurrentProjectAndCheckVersion,
  getVersionsByProject,
  getVersionsByProjectV2,
} from '../api/jitApi'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetCurrentProject } from 'lib/react-query/queries'

export const ProyectoContext = createContext()

export const ProyectoProvider = ({ children }) => {
  const { idProject } = useParams()

  const {
    data: currentProject,
    isLoading: isLoadingCurrentProject,
    isError: isErrorCurrentProject,
  } = useQuery(
    ['project', idProject],
    () => getCurrentProjectAndCheckVersion(idProject),
    {
      retry: 1,
    }
  )

  const data = {
    currentProject: currentProject?.data?.proyecto,
    versions: currentProject?.data?.versions,
    isLoadingCurrentProject,
    isErrorCurrentProject,
  }

  return (
    <ProyectoContext.Provider value={data}>{children}</ProyectoContext.Provider>
  )
}

export const useProject = () => {
  const { ...context } = React.useContext(ProyectoContext)
  if (context === undefined) {
    throw new Error('useProject must be used within a ProyectoProvider')
  }
  return context
}
