import { createContext, useContext, useEffect, useState } from 'react'
import { useGroupBimLink } from './GroupBimLinkContext'

const RulesBimLinkContext = createContext()

export const RulesBimLinkProvider = ({ children }) => {
  const { addRule } = useGroupBimLink()
  const [selectedDisplayName, setSelectedDisplayName] = useState(null)
  const [selectedDisplayValue, setSelectedDisplayValue] = useState(null)

  const createRule = () => {
    addRule({ name: selectedDisplayName.name, value: selectedDisplayValue })
  }
  const value = {
    selectedDisplayName,
    setSelectedDisplayName,
    setSelectedDisplayValue,
    selectedDisplayValue,
    createRule,
  }

  useEffect(() => {
    setSelectedDisplayValue(null)
  }, [selectedDisplayName])

  return (
    <RulesBimLinkContext.Provider value={value}>
      {children}
    </RulesBimLinkContext.Provider>
  )
}

export const useRulesBimLInk = () => {
  const context = useContext(RulesBimLinkContext)
  if (context === undefined) {
    throw new Error(
      'useArticuloBimLink must be used within a ArticuloBimLinkProvider'
    )
  }
  return context
}
