import { CustomButton } from 'components/ui'
import Dropdown, { DropDownItem } from 'components/ui/Dropdown/Dropdown'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ModalDeleteActividadAdmin from './ModalDeleteActividadAdmin'

const ActividadesRowAdmin = ({ item }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setIsOpenModal] = useState(false)

  return (
    <>
      <tr className="fabrica-table-header">
        <td>{item?.code}</td>
        <td>{item?.name}</td>
        <td className="container-buttons-row-articulo">
          <Dropdown
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            button={
              <CustomButton
                type="secondary"
                className="button-edit-articulo-price"
                onClick={() => setIsOpen(prevState => !prevState)}
              >
                <MoreVertIcon />
              </CustomButton>
            }
          >
            <DropDownItem
              item="Editar actividad"
              onClick={() =>
                navigate(`/admin/actividades/edit-actividad/${item._id}`)
              }
            ></DropDownItem>
            <DropDownItem
              item="Eliminar actividad"
              onClick={() => setIsOpenModal(true)}
            ></DropDownItem>
          </Dropdown>
        </td>
      </tr>
      {openModal && (
        <ModalDeleteActividadAdmin
          handleOnClose={() => setIsOpenModal(false)}
          actividad={item}
        />
      )}
    </>
  )
}

export default ActividadesRowAdmin
