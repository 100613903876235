import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  createArticulo,
  deleteArticulo,
  getArticuloById,
  updateArticulo,
} from 'api/estructuraCostes/articulosJitApi'

export const useCreateArticulo = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: body => createArticulo(body),
    onSuccess: () => {
      toast.success('Artículo creado correctamente')
      navigate('/admin/articulos')
      queryClient.invalidateQueries(['articulos'])
    },
    onError: () => {
      toast.error('Error al crear el artículo')
    },
  })
}

export const useGetArticulo = id => {
  const navigate = useNavigate()
  return useQuery({
    queryKey: ['articulo', id],
    queryFn: () => getArticuloById(id),
    onError: () => {
      toast.error('Error al obtener el artículo')
      navigate('/admin/articulos')
    },
  })
}

export const useUpdateSingleArticulo = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, ...rest }) => updateArticulo(id, rest),
    onSuccess: () => {
      toast.success('Artículo actualizado correctamente')
      navigate('/admin/articulos')
      queryClient.invalidateQueries(['articulos'])
    },
    onError: () => {
      toast.error('Error al actualizar el artículo')
    },
  })
}

export const useDeleteArticulo = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: id => deleteArticulo(id),
    onSuccess: () => {
      toast.success('Artículo eliminado correctamente')
      queryClient.invalidateQueries(['articulos'])
    },
    onError: () => {
      toast.error('Error al eliminar el artículo')
    },
  })
}
