import axios from 'axios'

const getAllCelulasByProjectId = async idProject => {
  try {
    const { data } = await axios.get(`/api/celulas/${idProject}`)
    return data
  } catch (e) {
    console.log(e)
  }
}

const createCelula = async (projectId, body) => {
  try {
    const { data } = await axios.post(`/api/celulas/${projectId}`, body)
    console.log('data', data)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const deleteCelula = async celulaId => {
  try {
    const { data } = await axios.delete(`/api/celulas/all/${celulaId}`)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const getCelula = async celulaId => {
  try {
    const { data } = await axios.get(`/api/celulas/all/${celulaId}`)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const updateCelula = async (celulaId, body) => {
  try {
    const { data } = await axios.patch(`/api/celulas/all/${celulaId}`, body)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const getImportCelulasByModuloId = async (project, modulo, version) => {
  try {
    const { data } = await axios.get(
      `/api/celulas/project/${project}/modulo/${modulo}/version/${version}`
    )

    console.log('celulas', data)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export {
  getAllCelulasByProjectId,
  createCelula,
  deleteCelula,
  getCelula,
  updateCelula,
  getImportCelulasByModuloId,
}
