import axios from 'axios'
import toast from 'react-hot-toast'
const getBimlinks = async () => {
  try {
    const { data } = await axios.get('/api/bimlinks')
    return data.data
  } catch (e) {
    console.log(e)
  }
}

const getAllBimLinks = async codes => {
  const filterCodes = codes.filter(code => code !== '')
  const bimLinks = await axios.post('/api/bimlinks/bycodes', filterCodes)
  return bimLinks?.data
}

const createBimlink = async bimlink => {
  try {
    const newBimlink = await axios.post('/api/bimlinks', bimlink)
    return newBimlink?.data
  } catch (e) {
    console.log(e)
  }
}

const updateBimlink = async bimlink => {
  const { id, ...rest } = bimlink
  try {
    const updatedBimlink = await axios.patch(`/api/bimlinks/${id}`, { rest })
    return updatedBimlink?.data
  } catch (e) {
    console.log(e)
  }
}

const deleteBimlink = async bimlink => {
  try {
    const deletedBimlink = await axios.delete(`/api/bimlinks/${bimlink._id}`)
    return deletedBimlink?.data
  } catch (e) {
    console.log(e)
  }
}

const getOneBimLink = async id => {
  try {
    const bimlink = await axios.get(`/api/bimlinks/${id}`)
    return bimlink
  } catch (e) {
    toast.error('Error al cargar bimlink. Por favor, vuélvelo a intentarlo')
    throw new Error(e)
  }
}

export {
  getAllBimLinks,
  createBimlink,
  updateBimlink,
  getBimlinks,
  deleteBimlink,
  getOneBimLink,
}
