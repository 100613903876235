import axios from 'axios'

const getAllUsersAuth0 = async () => {
  try {
    const { data } = await axios.get('/api/v1/auth0/getallusers')
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export { getAllUsersAuth0 }
