import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ViewerPanelHeaderBreadcrumbs from './ViewerPanelHeaderBreadcrumbs'
import ViewerPanelBreadcrumbsContainer from './ViewerPanelBreadcrumbsContainer'
import { useHandleUrl } from '../../../hooks/useHandleUrl'

const ViewerPanelHeaderNavigation = () => {
  const [allSegments, setAllSegments] = useState([])
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const { idProject } = useParams()

  useEffect(() => {
    const segments = location.pathname.split('/')

    const index = segments.findIndex(segment => segment === idProject)

    if (index !== -1) {
      const newSegments = segments.slice(index + 1)
      setAllSegments(newSegments)
    }
  }, [location.pathname])

  if (!allSegments || allSegments.length == 0) return
  return (
    <ViewerPanelBreadcrumbsContainer>
      {allSegments.map((seg, i) => (
        <ViewerPanelHeaderBreadcrumbs
          link={changeLocation(
            `/viewer/${idProject}/${allSegments.slice(0, i + 1).join('/')}`
          )}
          param={seg}
          lastElement={i == allSegments.length - 1}
          key={i}
        />
      ))}
    </ViewerPanelBreadcrumbsContainer>
  )
}

export default ViewerPanelHeaderNavigation
