import { useGetAllUsersAuth0 } from 'lib/react-query/queriesAuth0'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import ListUsers from './ListUsers'
import HeaderUsersProyecto from './HeaderUsersProyecto'
import ProyectosArraySkeleton from 'components/Loaders/ProyectosArraySkeleton'
import ListUsersLoader from 'components/Loaders/ListUsersLoader'

const UserProyecto = () => {
  const { data, error, isFetching } = useGetAllUsersAuth0()

  useEffect(() => {
    if (data) {
      console.log('data', data)
    }
  }, [data])

  if (error) <Navigate to="/admin/proyectos" />
  if (isFetching) return <ListUsersLoader height="60px" />
  return (
    <>
      <HeaderUsersProyecto />
      <ListUsers />
    </>
  )
}

export default UserProyecto
