import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const InputThemeProvider = ({ children }) => {
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            // padding: '0 !important',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '0',
                // height: '70%',
              },
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& .MuiInputLabel-root': {
              fontFamily: '"Roboto Condensed", sans-serif',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: '0',
          },
        },
      },
    },
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default InputThemeProvider
