import VisibilityIcon from '@mui/icons-material/Visibility'
import { useContext, useMemo, useState } from 'react'
import { ModuloContext } from '../../context/ModuloContext'
import { redondearDecimalesToLocaleString } from '../../utils/roundNumber'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ordenarPorPropiedad } from '../../utils/formatText'
import ActivityTableRow from './ActivityTableRow'
import ActivityContainerTableRow from './ActivityContainerTableRow'

const CelulaTableRow = ({ celula }) => {
  const { addDbIdSelected, removeDbIdSelected } = useContext(ModuloContext)
  const [activitiesOpen, setActivitiesOpen] = useState(false)

  const handleOnClick = () => {
    setActivitiesOpen(oldState => !oldState)
  }

  const handleOnFilter = e => {
    e.stopPropagation()
    if (celula.isSelected) {
      removeDbIdSelected(celula._id, true)
    } else {
      addDbIdSelected(celula._id, true)
    }
    // setIsFilter(oldState => !oldState)
  }

  const importeTotal = useMemo(() => {
    return celula.activities.reduce((total, activity) => {
      return (
        total +
        activity.articulos.reduce(
          (total, item) => total + item.quantity * (item.precio),
          0
        )
      )
    }, 0)
  }, [celula])
  return (
    <>
      <tr onClick={handleOnClick} className="modulo-table__celula-row">
        <td style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
          {activitiesOpen ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )}
          <div className='celula-box celula-color' />
          <p>{celula.name}</p>
          <VisibilityIcon
            style={{ color: celula.isSelected ? '#1976D2' : '#84898e' }}
            size="small"
            onClick={handleOnFilter}
          />
        </td>
        <td></td>
        <td></td>
        <td><p className='celula-amount'>{redondearDecimalesToLocaleString(importeTotal)}€</p></td>

        {/* <td>{celula.cantidad}</td>
      <td>{celula.precio}</td>
      <td>{celula.importe}</td> */}
      </tr>
      {activitiesOpen && (
        <ActivityContainerTableRow activities={celula.activities} />
      )}
    </>
  )
}

export default CelulaTableRow
