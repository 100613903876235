import axios from 'axios'
import { CustomButton } from 'components/ui'
import Modal from 'components/ui/Modal/Modal'
import { useUpdateImageProject } from 'lib/react-query/queriesProject'
import React, { useEffect, useRef, useState } from 'react'
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useParams } from 'react-router-dom'
import getCanvasPreview from 'utils/getCanvasPreview'
import { calculateCenteredCrop } from 'utils/handleImage'
const ASPECT_RATIO = 1
const MIN_DIMENSION = 150

function base64ToBlob(base64Image) {
  // Divide la cadena Base64 en partes
  var parts = base64Image.split(';base64,')
  var contentType = parts[0].split(':')[1]
  var raw = window.atob(parts[1])
  var rawLength = raw.length
  var uInt8Array = new Uint8Array(rawLength)

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  // Devuelve el objeto Blob
  return new Blob([uInt8Array], { type: contentType })
}

const ModalImageSelected = ({ preview, handleOnClose }) => {
  const { idProject } = useParams()
  const { mutateAsync } = useUpdateImageProject(idProject, handleOnClose)
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState()

  const uploadImage = async () => {
    getCanvasPreview(
      imgRef.current, // HTMLImageElement
      previewCanvasRef.current, // HTMLCanvasElement
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    )
    const dataUrl = previewCanvasRef.current.toDataURL()

    mutateAsync(dataUrl)
  }

  const onImageLoad = e => {
    const { width, height } = e.currentTarget
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    )
    const centeredCrop = centerCrop(crop, width, height)
    setCrop(centeredCrop)
  }

  useEffect(() => {
    if (imgRef.current) {
      const { width, height } = imgRef.current
      setCrop(calculateCenteredCrop(width, height, ASPECT_RATIO))
    }
  }, [imgRef.current])

  return (
    <Modal
      modalClass="update-image-modal"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div>
        <ReactCrop crop={crop} onChange={c => setCrop(c)} aspect={4 / 3}>
          <img
            src={preview}
            alt="Preview"
            ref={imgRef}
            className="img-edit-project"
            onImageLoad={onImageLoad}
            onChange={percentCrop => setCrop(percentCrop)}
            keepSelection
          />
        </ReactCrop>
        {crop && (
          <canvas ref={previewCanvasRef} style={{ display: 'none' }}></canvas>
        )}
        <div className="buttons-container-edit-img">
          <CustomButton className="btn-l" onClick={uploadImage}>
            Guardar
          </CustomButton>
        </div>
      </div>
    </Modal>
  )
}

export default ModalImageSelected
