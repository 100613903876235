import { CustomButton } from 'components/ui'
import React, { useState } from 'react'
import ModalAddUsers from './AddUsers/ModalAddUsers'
import {  AddUsersToProjectProvider } from 'context/AddUsersToProjectContext'

const HeaderUsersProyecto = () => {
  const [isModalOpened, stIsModalOpened] = useState(false)

  const handleOnClick = () => {
    stIsModalOpened(true)
  }

  const handleOnClose = () => {
    stIsModalOpened(false)
  }

  return (
    <>
      {isModalOpened && (
        <AddUsersToProjectProvider>
          <ModalAddUsers handleOnClose={handleOnClose} />
        </AddUsersToProjectProvider>
      )}
      <div className="header-users-edit-project">
        <p className="users-project-text">
          Usuarios que están asignados a este proyecto
        </p>
        <CustomButton className="btn-l" onClick={handleOnClick}>
          Añadir usuarios
        </CustomButton>
      </div>
    </>
  )
}

export default HeaderUsersProyecto
