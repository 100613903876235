import CheckIcon from '@mui/icons-material/Check'
import { useEffect, useRef, useState } from 'react'

export const SearchOptionsGroupItem = ({
  item,
  title,
  handleSelectedItem = null,
  isSelected,
}) => {
  const handleOnClick = () => {
    handleSelectedItem && handleSelectedItem(item)
  }
  return (
    <div
      style={{ padding: '1rem' }}
      className={`option-group-item-articulo ${isSelected && 'selected'}`}
      onClick={handleOnClick}
    >
      {isSelected && <CheckIcon />}
      {title}
    </div>
  )
}

const SearchOptionsGroup = ({ children, width, ...props }) => {
  const { offsetWidth } = width || {}
  const ref = useRef(null)
  const [bottomOffset, setBottomOffset] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      const element = ref?.current
      if (element) {
        const newBottomOffset = -element.offsetHeight // Adding a small offset for better positioning
        setBottomOffset(newBottomOffset)
      }
    }

    // Initial calculation
    if (ref.current) {
      handleResize()
    }

    // Recalculate when height changes
    const observer = new ResizeObserver(() => {
      handleResize()
    })

    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [ref?.current, ref?.current?.offsetHeight])

  return (
    <div
      style={{
        position: 'absolute',
        width: `${offsetWidth}px`,
        bottom: `${bottomOffset}px`,
        visibility: bottomOffset == 0 ? 'hidden' : 'visible',
        maxHeight: '250px',
        overflowY: 'auto',
        border: '1px solid #ccc',
        borderRadius: '5px',
        zIndex: '2',
        background: 'white',
      }}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default SearchOptionsGroup
