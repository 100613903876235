import React from 'react'

const BoxSkeleton = ({ children }) => {
  return (
    <div
      style={{
        display: 'block',
        marginBottom: '10px',
      }}
    >
      {children}
    </div>
  )
}

export default BoxSkeleton
