import React, { useContext, useMemo, useState } from 'react'
import { Collapse } from 'reactstrap'
import CelulaArticulo from './CelulaArticulo'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { capitalizarPalabras } from '../../utils/formatText'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { ModuloContext } from '../../context/ModuloContext'
import { redondearDecimalesToLocaleString } from '../../utils/roundNumber'

const CelulaActivity = ({ activity, showArticulos }) => {
  // const { addDbIdSelected, removeDbIdSelected } = useContext(ModuloContext)
  const [articulosAreOpened, setArticulosAreOpened] = useState(false)

  const handleOnClick = () => {
    setArticulosAreOpened(prevState => !prevState)
  }

  // const handleOnFilter = e => {
  //   e.stopPropagation()
  //   if (activity.isSelected) {
  //     removeDbIdSelected(activity._id)
  //   } else {
  //     addDbIdSelected(activity._id)
  //   }
  // }

  const totalCelula = useMemo(() => {
    return (
      activity.articulos
        ?.reduce((total, item) => total + item.quantity * (item.precio || 1), 0)
        .toFixed(2) || 0
    )
  }, [activity])
  return (
    <div key={activity._id}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          background: articulosAreOpened && '#f9f9f9',
          padding: '1rem',
          paddingLeft: '1.5rem',
          // fontWeight: 600,
          fontFamily: "'Roboto Condensed', sans-serif",
        }}
        onClick={showArticulos ? handleOnClick : undefined}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* {articulosAreOpened ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )} */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '.75rem' }}>
            <p style={{ margin: 0 }}>
              {activity.code} - {capitalizarPalabras(activity.name)}
            </p>
            {/* <VisibilityIcon
              style={{ color: activity.isSelected ? '#1976D2' : '#84898e' }}
              size="small"
              onClick={handleOnFilter}
            /> */}
          </div>
        </div>
        {showArticulos && (
          <p>{redondearDecimalesToLocaleString(totalCelula)}€</p>
        )}
      </div>
      {showArticulos && (
        <Collapse isOpen={articulosAreOpened}>
          {activity.articulos.length == 0 ? (
            <p
              style={{
                textAlign: 'center',
                fontStyle: 'oblique',
                fontSize: '.825rem',
                margin: '1rem',
              }}
            >
              No hay artículos
            </p>
          ) : (
            <CelulaArticulo articulos={activity.articulos} />
          )}
        </Collapse>
      )}
    </div>
  )
}

export default CelulaActivity
