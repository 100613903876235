import BadgeCell from 'features/tipos/BadgeCell'
import React from 'react'
import { viewer } from 'utils/launchViewer'
import ButtonsTipoConfigItem from './ButtonsTipoConfigItem'

const TipoConfigItem = ({ t }) => {
  const { lmvId: dbId, name, revitCategory, tipoBimlink, items } = t
  const dbIds = items.map(item => item.lmvId) || []
  const isHide = tipoBimlink && tipoBimlink.visible == false
  const { bimlink: { code } = {} } = tipoBimlink || {}

  const handleClick = () => {
    viewer.isolate(dbIds)
    viewer.fitToView(dbIds)
  }

  return (
    <tr style={{ background: isHide && 'rgb(237, 237, 237)' }}>
      <td onClick={handleClick}>{name}</td>
      <td>{revitCategory}</td>
      <td>
        {tipoBimlink && tipoBimlink.bimlink && (
          <BadgeCell code={code} areSame={true} />
        )}
      </td>
      <td>
        <ButtonsTipoConfigItem
          tipoBimlink={tipoBimlink}
          isHide={isHide}
          name={name}
          externalIds={items.map(item => item.externalId)}
        />
      </td>
    </tr>
  )
}

export default TipoConfigItem
