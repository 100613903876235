import ProyectosAdminList from 'components/Proyectos/ProyectosAdminList'
import PageTitle from 'components/ui/Title/PageTitle'

export const ProyectosPage = () => {
  return (
    <>
      <PageTitle>Proyectos</PageTitle>
      <ProyectosAdminList />
    </>
  )
}
