import { useAuth0 } from '@auth0/auth0-react'
import ProyectosArraySkeleton from 'components/Loaders/ProyectosArraySkeleton'
import ProyectoCardItem from 'components/Proyectos/ProyectoCardItem'
import Pagination from 'components/ui/Pagination/Pagination'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { useGetAllProjectsByUserAdmin } from 'lib/react-query/queriesProject'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NotProjectsAvailable from './NotProjectsAvailable'
const LIMIT_PAGINATION = 8

const ProyectosList = ({ disabled }) => {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const { changeLocation } = useHandleUrl()
  const [page, setPage] = useState(1)

  const { data, isLoading } = useGetAllProjectsByUserAdmin(user.sub, {
    isUser: true,
    disabled,
    page,
    limit: LIMIT_PAGINATION,
  })

  const goToViewer = idProject => {
    navigate(changeLocation(`/viewer/${idProject}`))
  }

  if (isLoading) return <ProyectosArraySkeleton length={8} />
  if (data.data.data.length === 0) return <NotProjectsAvailable />
  return (
    <div className="proyectos-container-layout">
      <div className="proyectos-container">
        {data.data.data.map(item => (
          <ProyectoCardItem item={item} handleOnClick={id => goToViewer(id)} />
        ))}
      </div>
      {Math.ceil(data.total / LIMIT_PAGINATION) !== 1 && (
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(data.total / LIMIT_PAGINATION)}
          handlePage={value => setPage(value)}
        />
      )}
    </div>
  )
}

export default ProyectosList
