import TextInput from 'components/ui/Forms/TextInput'
import { useArticuloBimLink } from 'context/ArticuloBimLinkContext'
import { useClickOutside } from 'hooks/useClickOutside'
import {
  useGetAllArticulos,
} from 'lib/react-query/queries'
import { useEffect, useMemo, useRef, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import SearchOptionsGroup, {
  SearchOptionsGroupItem,
} from './SearchOptionsGroup'
import { compararPorPropiedad } from 'components/Celulas/NewCelula/ActivitiesCheckbox'

const ArticulosInput = () => {
  const { data } = useGetAllArticulos()
  const rootEl = useRef(null)
  const { selectedArticulo, setSelectedArticulo } = useArticuloBimLink()
  const [textInput, setTextInput] = useState(
    selectedArticulo
      ? `${selectedArticulo?.code} - ${selectedArticulo?.name}`
      : ''
  )

  const [showOptions, setShowOptions] = useState(false)

  const resetInfo = () => {
    setShowOptions(false)
    if (!showOptions) return

    if (selectedArticulo) {
      setTextInput(`${selectedArticulo.code} - ${selectedArticulo.name}`)
    } else {
      setTextInput('')
    }
  }
  useClickOutside(rootEl, resetInfo)

  const nameInputselectedArticulo = useMemo(() => {
    if (selectedArticulo) {
      return `${selectedArticulo.code} - ${selectedArticulo.name}`
    } else {
      return null
    }
  }, [selectedArticulo])

  const handleselectedArticulo = articulo => {
    setSelectedArticulo(articulo)
    setTextInput(`${articulo.code} - ${articulo.name}`)
    setShowOptions(false)
  }
  const filterData = options => {
    if (selectedArticulo) {
      if (textInput == `${selectedArticulo.code} - ${selectedArticulo.name}`) {
        return options
      }
    }

    if (textInput.length >= 2) {
      return options.filter(
        articulo =>
          articulo.code.toLowerCase().includes(textInput.toLowerCase()) ||
          articulo.name.toLowerCase().includes(textInput.toLowerCase())
      )
    }

    return data?.data.data
  }

  const handleMenuOpen = () => {
    setShowOptions(true)
  }

  useEffect(() => {
    if (!textInput) {
      setSelectedArticulo(null)
    }
  }, [textInput])

  return (
    <div style={{ position: 'relative' }} ref={rootEl}>
      <TextInput
        label="Artículos"
        name="articulos"
        onChange={e => setTextInput(e.target.value)}
        placeholder="Buscar por código o nombre de artículo. Escribe, al menos, dos caracteres"
        value={textInput || nameInputselectedArticulo || ''}
        onClick={handleMenuOpen}
        autoComplete="off"
      />
      {selectedArticulo && (
        <div
          className="icon-text-input-reset"
          onClick={() => {
            setSelectedArticulo(null)
            setTextInput('')
          }}
        >
          <ClearIcon />
        </div>
      )}
      {showOptions && (
        <SearchOptionsGroup width={rootEl?.current}>
          {data ? (
            filterData(data?.data.data).length == 0 ? (
              <div style={{ padding: '1rem', fontStyle: 'oblique' }}>
                No hay ningún elemento que coincida con la búsqueda
              </div>
            ) : (
              filterData(data?.data.data)
                .sort(compararPorPropiedad('code'))
                .map(articulo => (
                  <SearchOptionsGroupItem
                    title={`${articulo.code} - ${articulo.name}`}
                    item={articulo}
                    handleSelectedItem={handleselectedArticulo}
                    isSelected={selectedArticulo?._id === articulo._id}
                  />
                ))
            )
          ) : null}
        </SearchOptionsGroup>
      )}
    </div>
  )
}

export default ArticulosInput
