export const handleUnit = unit => {
  if (unit == 'm2' || unit == 'm3')
    return (
      <>
        {unit[0]}
        <sup>{unit[1]}</sup>
      </>
    )
  return unit
}

export const fromMtoMm = value => value * 1000
