import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from '../../hooks/useHandleUrl'
import { CustomButton } from '../ui'
import EngineeringIcon from '@mui/icons-material/Engineering'
const ButtonConfigMO = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const handleOnClick = () => {
    navigate(changeLocation(`${location.pathname}/config-mo`))
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',

      }}
    >
      <div>
        <CustomButton
          onClick={handleOnClick}
          style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
        >
          <EngineeringIcon />
          Gestionar coste de mano de obra
        </CustomButton>
      </div>
    </div>
  )
}

export default ButtonConfigMO
