import { useAuth0 } from '@auth0/auth0-react'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router-dom'
import UserItem from './UserItem'
import {
  useGetSingleProjectByUserAdmin,
  useUpdateSingleProjectByUserAdmin,
} from 'lib/react-query/queriesProject'

const ListUsers = () => {
  const queryClient = useQueryClient()
  const { idProject } = useParams()
  const { user } = useAuth0()
  const { mutateAsync } = useUpdateSingleProjectByUserAdmin(user.sub, idProject)

  const { data: users } = queryClient.getQueryData(['users-auth0'])
  const { data: project } = useGetSingleProjectByUserAdmin(
    user.sub,
    idProject,
    false
  )

  const handleDeleteUser = (user, rol) => {
    const { data } = project
    if (rol === 'Administrador') {
      const currentAdministradores = data.user.filter(item => item !== user)
      mutateAsync({ body: { user: currentAdministradores } })
    } else if (rol === 'Usuario') {
      const currentUsers = data.users.filter(item => item !== user)
      mutateAsync({ body: { users: currentUsers } })
    }
  }

  return (
    <div className="user-list-container">
      <h3 className="user-title-list">Usuario</h3>
      {project?.data.user.map(user => {
        const userAuth0 = users.find(u => u.user_id === user)
        return (
          <UserItem
            key={userAuth0.user_id}
            user={userAuth0}
            rol="Administrador"
            handleDeleteUser={() => handleDeleteUser(user, 'Administrador')}
          />
        )
      })}
      {project?.data.users.map(user => {
        const userAuth0 = users.find(u => u.user_id === user)
        return (
          <UserItem
            key={userAuth0.user_id}
            user={userAuth0}
            rol="Usuario"
            handleDeleteUser={() => handleDeleteUser(user, 'Usuario')}
          />
        )
      })}
    </div>
  )
}

export default ListUsers
