import FormNewActividad from 'components/admin/Actividades/FormNewActividad/FormNewActividad'
import BoxSkeleton from 'components/admin/BoxSkeleton'
import Modal from 'components/ui/Modal/Modal'
import { useGetActividad } from 'lib/react-query/queriesActividad'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from 'react-router-dom'

const EditActividadAdminPage = () => {
  const { idActividad } = useParams()
  const { data, isLoading } = useGetActividad(idActividad)
  const navigate = useNavigate()
  const handleOnClose = () => {
    navigate('/admin/actividades')
  }

  return (
    <Modal
      modalClass="modal modal-new-articulo"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div className="modal-link-bimlink-to-tipo-container--width">
        {isLoading ? (
          <Skeleton count={3} height={20} wrapper={BoxSkeleton} />
        ) : (
          <>
            <h2>Editar artículo</h2>
            <FormNewActividad data={data.data} />
          </>
        )}
      </div>
    </Modal>
  )
}

export default EditActividadAdminPage
