import { useAddUsersToProject } from 'context/AddUsersToProjectContext'
import UserSelectedList from './UserSelectedList'

const ListUserSelected = ({ user }) => {
  const { userSelected } = useAddUsersToProject()
  return (
    <div className="users-selected-container-add-user">
      {userSelected.map(user => (
        <UserSelectedList user={user} />
      ))}
      {userSelected.length === 0 && (
        <p className="text-not-data-found">No hay usuarios seleccionados</p>
      )}
    </div>
  )
}

export default ListUserSelected
