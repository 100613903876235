import { useGetAllArticulosByProject } from 'lib/react-query/queries'
import { useParams } from 'react-router-dom'
import ArticulosRow from './ArticulosRow'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Loader from 'components/Spinner/Loader'
import ArticulosTableLoader from 'components/Loaders/ArticulosTableLoader'
import { useVersionModel } from 'hooks/useVersionModel'
import { TABLE_HEADER_ARTICULOS } from 'utils/constant/constantsArticulos'
import { useArticulosTablePage } from 'context/ArticulosTablePageContext'

const ArticulosTable = () => {
  const { idProject } = useParams()
  const { currentVersion } = useVersionModel()
  const { ref, inView } = useInView()
  const { searchedValue } = useArticulosTablePage()
  const { data, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetAllArticulosByProject(idProject, {
      limit: 15,
      sort: 'articulo.code',
      filter: searchedValue == '' ? '' : searchedValue,
      version: currentVersion,
    })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView])

  const handleDataPages = data => {
    return data.pages.map(page => page.data.articulos).flat()
  }

  if (isLoading) return <ArticulosTableLoader />
  if (data?.pages?.length == 0 || data?.pages[0]?.data?.articulos?.length == 0)
    return (
      <p className="not-articulos-text">
        No hay artículos asignados a este proyecto
      </p>
    )

  return (
    <div
      className="fabrica-matriculas-table-container"
      style={{ flex: 1, overflowY: 'auto' }}
    >
      <table>
        <thead>
          <tr>
            {TABLE_HEADER_ARTICULOS.map(({ name, className }, i) => (
              <th className={className}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {handleDataPages(data).map((articulo, i) => (
            <ArticulosRow articulo={articulo} key={i} />
          ))}
        </tbody>
      </table>
      <div ref={ref} style={{ height: '1px' }}>
        {isFetchingNextPage && (
          <div className="spinner-loading-container">
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}

export default ArticulosTable
