import { TextField } from '@mui/material'
import TextInput from 'components/ui/Forms/TextInput'
import React from 'react'

const TotalDiasPorModulo = ({ totalModulos, mo }) => {
  return (
    <div>
      <TextInput
        type="number"
        label="Días"
        value={Number(totalModulos) / Number(mo.rendimiento)}
        disabled
        containerStyles={{ width: '300px' }}
      />
    </div>
  )
}

export default TotalDiasPorModulo
