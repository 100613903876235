import { Autocomplete, Box, Chip, TextField, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import React, { useState, useEffect } from 'react'

const CustomSearch = ({ usersFilter, setSelectedUser }) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    console.log('Estos son los usuarios que le paso al buscador: ', usersFilter)
  }, [usersFilter])

  return (
    <div className="search-container">
      <Autocomplete
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault() // evita que se añada el valor como tag
            event.stopPropagation() // PUESTA MAL APOSTA PARA EVITAR QUE SE AÑADA UNA ETIQUETA VACIA
          }
        }}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.email}
              {...getTagProps({ index })}
            />
          ))
        }
        options={usersFilter?.filter(
          option =>
            option.email.toLowerCase().includes(inputValue.toLowerCase()) &&
            inputValue.length >= 3
        )}
        multiple
        style={{ width: '95%' }}
        getOptionLabel={option => option.email}
        filterSelectedOptions
        renderOption={(props, option, { selected }) => {
          if (!option) {
            return <li>No hay opciones disponibles</li>
          }

          return (
            <li {...props}>
              <Box className="container-user-search ">
                <img
                  src={option.img}
                  className="avatar-img"
                  style={{ marginLeft: '1rem' }}
                  referrerPolicy="no-referrer"
                />
                <p className="m-0">{option.email}</p>
              </Box>
            </li>
          )
        }}
        renderInput={params => (
          <TextField
            className="label-search"
            {...params}
            label="Search users..."
            variant="outlined"
            onChange={event => {
              setInputValue(event.target.value)
            }}
          />
        )}
        onChange={(event, value) => {
          setInputValue('')
          setSelectedUser(value?.map(user => user))
        }}
      />

      <SearchIcon style={{ marginRight: '.5rem' }} />
    </div>
  )
}

export default CustomSearch
