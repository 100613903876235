import cn from 'classnames'

export const GroupSingleItem = ({
  name,
  Icon,
  ButtonsComponent,
  buttonsProps = {},
  children,
}) => {
  return (
    <div className="group-single-item-container">
      <div className="group-single-item">
        <div className="group-single-item-text-container">
          {Icon && <Icon />}
          <p className="group-single-item-text">{name}</p>
        </div>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {ButtonsComponent && <ButtonsComponent {...buttonsProps} />}
        </div>
      </div>
      <div>{children}</div>
    </div>
  )
}

export const GroupItemsBody = ({ className, children, ...props }) => {
  return (
    <div className={cn('group-bimlinks-items-container', className)} {...props}>
      {children}
    </div>
  )
}

export const GroupItemsTitle = ({ className, children, ...props }) => {
  return (
    <p className={cn('group-bimlinks-container-title', className)} {...props}>
      {children}
    </p>
  )
}

const GroupItems = ({ className, children, ...props }) => {
  return (
    <div className={cn('group-bimlinks-container', className)} {...props}>
      {children}
    </div>
  )
}

export default GroupItems
