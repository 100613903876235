import { useBimLink } from 'context/BimLinkContext'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { NewGroupBimLinkWrapper } from 'pages/NewGroupBimlinkPage'
import React from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

const EditGroupBimLinkWrapper = () => {
  const { groupUuid } = useParams()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  const { groups } = useBimLink()
  const isGroupExists = groups.find(group => group.uuid === groupUuid)
  console.log('isGroupExists', isGroupExists)
  if (!isGroupExists)
    return (
      <Navigate to={changeLocation(location.pathname.split('/group')[0])} />
    )
  return <NewGroupBimLinkWrapper group={isGroupExists} />
}

export default EditGroupBimLinkWrapper
