import HeaderBimLinksButtons from 'components/Bimlink/ViewerBimLinkPage/HeaderBimLinksButtons'
import TableAllBimLinks from 'components/Bimlink/ViewerBimLinkPage/TableAllBimLinks'
import { compararPorPropiedad } from 'components/Celulas/NewCelula/ActivitiesCheckbox'
import { useGetAllBimlinks } from 'lib/react-query/queries'
import React, { useEffect } from 'react'

const BimLinkPage = () => {
  const { data } = useGetAllBimlinks()

  useEffect(() => {
    console.log('data', data)
  }, [data])
  if (!data) return <div>Loading...</div>
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        flex: 1,
        height: '100%',
      }}
    >
      <HeaderBimLinksButtons />
      <TableAllBimLinks />
    </div>
  )
}

export default BimLinkPage
