import Modal from 'components/ui/Modal/Modal'
import BimLinkInput from './BimLinkInput'
import { CustomButton } from 'components/ui'
import {
  useAddBimLinkToTipo,
} from 'context/AddBimLinkToTipoContext'

const ModalAddBimlinkTipo = ({ handleOnClose }) => {
  const { selectedBimlink, createTipo } = useAddBimLinkToTipo()

  const handleOnClick = async () => {
    await createTipo()
    handleOnClose()
  }
  return (
    <Modal
      modalClass="modal"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div className="modal-link-bimlink-to-tipo-container">
        <h2>Vincular BimLink con tipo</h2>
        <BimLinkInput />
        <div className="modal-link-bimlink-to-tipo-buttons">
          <CustomButton type="secondary" onClick={handleOnClose}>
            Cancelar
          </CustomButton>
          <CustomButton disabled={!selectedBimlink} onClick={handleOnClick}>
            Añadir bimlink
          </CustomButton>
        </div>
      </div>
    </Modal>
  )
}

export default ModalAddBimlinkTipo
