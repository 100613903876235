import cn from 'classnames'

const TextInput = ({
  label,
  containerStyles,
  containerclass,
  labelClass,
  inputclass,
  adornment,
  ...props
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        fontSize: '12px',
        fontWeight: '600',

        ...containerStyles,
      }}
      containerclass
    >
      <label className={cn(labelClass)}>{label}</label>
      {adornment ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            background: !props.disabled && 'white',
          }}
          className="custom-input-container"
        >
          <input
            {...props}
            className={cn('custom-input', inputclass)}
            style={{ minWidth: 'auto' }}
          />
          <div
            style={{
              display: 'flex',
              padding: '1rem',
              color: '#555',
              fontWeight: '400',
            }}
          >
            {adornment}
          </div>
        </div>
      ) : (
        <div className="custom-input-container">
          <input {...props} className={cn('custom-input', inputclass)} />
        </div>
      )}
    </div>
  )
}

export default TextInput
