import { CircularProgress } from '@mui/material'

const Loader = ({ size = 18 }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color="inherit" size={size} />
    </div>
  )
}

export default Loader
