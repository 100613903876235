import { useHandleUrl } from 'hooks/useHandleUrl'
import { useCreateBimlink, useUpdateBimlink } from 'lib/react-query/queries'
import { getUUID } from 'lib/uuid/get-id.lib'
import { createContext, useContext, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const BimLinkContext = createContext()

export const BimLinkProvider = ({ bimlink, children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { changeLocation } = useHandleUrl()
  const [nameBimLink, setNameBimLink] = useState(bimlink ? bimlink.code : '')
  const [groups, setGroups] = useState(
    bimlink ? bimlink.groups.map(group => ({ uuid: getUUID(), ...group })) : []
  )
  const mutationBimlink = useCreateBimlink()
  const mutationEditBimlik = useUpdateBimlink()
  const isEditMode = useMemo(
    () => location.pathname.includes('edit-bimlink'),
    [location]
  )

  const onChangeNameBimLink = e => {
    setNameBimLink(e.target.value)
  }

  const addGroup = newGroup => {
    setGroups(prevState => [...prevState, { uuid: getUUID(), ...newGroup }])
  }

  const deleteGroup = group => {
    setGroups(prevState => prevState.filter(g => g.uuid !== group.uuid))
  }

  const updateGroup = group => {
    setGroups(prevState => {
      const index = prevState.findIndex(g => g.uuid === group.uuid)
      prevState[index] = group
      return [...prevState]
    })
  }

  const createBimlink = async () => {
    // En cada artículo, hay que quedarse solo con el id de artículo y actividad
    const newBimlink = groups.map(group => {
      const newGroup = {
        ...group,
        articulos: group.articulos.map(articulo => ({
          articulo: articulo.articulo._id,
          actividad: articulo.actividad._id,
          formula: articulo.formula,
        })),
      }
      return newGroup
    })

    if (isEditMode) {
      mutationEditBimlik.mutate({
        id: bimlink._id,
        code: nameBimLink,
        groups: newBimlink,
      })
    } else {
      mutationBimlink.mutate({
        code: nameBimLink,
        groups: newBimlink,
      })
    }

    
  }

  const data = {
    isEditMode,
    nameBimLink,
    onChangeNameBimLink,
    groups,
    updateGroup,
    addGroup,
    createBimlink,
    deleteGroup,
  }

  return (
    <BimLinkContext.Provider value={data}>{children}</BimLinkContext.Provider>
  )
}

export const useBimLink = () => {
  const context = useContext(BimLinkContext)
  if (context === undefined) {
    throw new Error('useBimlink must be used within a BimlinkProvider')
  }
  return context
}
