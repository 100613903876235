import {
  CapitulosTreeDashboardProvider,
  FiltersPartidaProvider,
  NavPresupuestoProvider,
  PresupuestoProvider,
  ProyectoProvider,
  SidebarProvider,
} from '../../context'

const ViewerLayoutProvider = ({ children }) => {
  return (
    <ProyectoProvider>
      <FiltersPartidaProvider>
        <PresupuestoProvider>
          <NavPresupuestoProvider>
            <CapitulosTreeDashboardProvider>
              <SidebarProvider>{children}</SidebarProvider>
            </CapitulosTreeDashboardProvider>
          </NavPresupuestoProvider>
        </PresupuestoProvider>
      </FiltersPartidaProvider>
    </ProyectoProvider>
  )
}

export default ViewerLayoutProvider
