import { CustomButton } from 'components/ui'
import { useGetAllFields } from 'lib/react-query/queries'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DisplayNameInput from './RuleBimLink/DisplayNameInput'
import DisplayValueInput from './RuleBimLink/DisplayValueInput'
import { useRulesBimLInk } from 'context/RulesBimLinkContext'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { Tooltip } from 'react-tooltip'

const NewRuleBimlink = () => {
  const { idProject } = useParams()
  const { data, isLoading } = useGetAllFields(idProject, 10)
  const {
    selectedDisplayName,
    setSelectedDisplayName,
    setSelectedDisplayValue,
    selectedDisplayValue,
    createRule,
  } = useRulesBimLInk()
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()

  const handleBack = () => {
    setSelectedDisplayName(null)
    setSelectedDisplayValue(null)
    navigate(changeLocation(location.pathname.replace('/new-rule', '')))
  }

  const handleCreateRule = () => {
    createRule()
    handleBack()
  }

  return (
    <div className="new-bimlink-body-container">
      <h1 className="new-bimlink-title">Crear regla</h1>
      <div className="new-bimlink-form">
        {isLoading || !data ? (
          <div>Cargando info...</div>
        ) : (
          <DisplayNameInput data={data?.data?.fields} />
        )}
        {selectedDisplayName && <DisplayValueInput />}
      </div>
      <div className="new-bimlink-footer-buttons">
        <CustomButton type="secondary" onClick={handleBack}>
          Cancelar
        </CustomButton>

        <CustomButton
          onClick={handleCreateRule}
          className="create-rule-button"
          disabled={!selectedDisplayName || !selectedDisplayValue}
        >
          Crear regla
        </CustomButton>
      </div>
      <Tooltip
        anchorSelect=".create-rule-button"
        style={{
          fontSize: '9px',
          display:
            !selectedDisplayName || !selectedDisplayValue ? 'block' : 'none',
        }}
      >
        Tiene que seleccionar un name y un value
      </Tooltip>
    </div>
  )
}

export default NewRuleBimlink
