import ArticulosTableLoader from 'components/Loaders/ArticulosTableLoader'
import ConfigTipos from 'components/Tipos/ConfigTipos/ConfigTipos'
import { CustomButton } from 'components/ui'
import { ArticulosTablePageProvider } from 'context/ArticulosTablePageContext'
import { useVersionModel } from 'hooks/useVersionModel'
import { useUpdateAllTiposBimLink } from 'lib/react-query/queriesTipoBimLinkProyectos'
import React from 'react'

const ConfigTiposPage = () => {
  const { currentVersion } = useVersionModel()

  if (!currentVersion) return <ArticulosTableLoader />
  return (
    <ArticulosTablePageProvider>
      <ConfigTipos />
    </ArticulosTablePageProvider>
  )
}

export default ConfigTiposPage
