import NewGroupBimlink from 'components/Bimlink/NewGroupBimlink'
import { useBimLink } from 'context/BimLinkContext'
import { GroupBimLinkProvider } from 'context/GroupBimLinkContext'
import { useHandleUrl } from 'hooks/useHandleUrl'
import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

export const NewGroupBimLinkWrapper = ({ group = null }) => {
  console.log('entro aquí')
  return (
    <GroupBimLinkProvider group={group}>
      <Outlet />
    </GroupBimLinkProvider>
  )
}

export const NewGroupBimlinkPage = () => {
  const { nameBimLink } = useBimLink()
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  useEffect(() => {
    if (!nameBimLink) {
      return navigate(changeLocation(location.pathname.replace('/group', '')))
    }
  }, [])
  return <NewGroupBimlink />
}
