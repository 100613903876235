import axios from 'axios'

const getAllArticulos = async query => {
  try {
    const { data } = await axios.get(`/api/articulos`, {
      params: query || {},
    })
    return data
  } catch (e) {}
}

const getArticuloById = async id => {
  try {
    const { data } = await axios.get(`/api/articulos/${id}`)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

// Traer el artículo con el precioArticuloProyecto correspondiente.
const getArticuloProyecto = async (id, proyecto) => {
  try {
    const { data } = await axios.get(
      `/api/articulos/${id}/proyecto/${proyecto}`
    )
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const getArticuloByCode = async code => {
  try {
    const { data } = await axios.get(`/api/articulos/code/${code}`)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const updateArticulo = async (id, body) => {
  try {
    await axios.patch(`/api/articulos/${id}`, body)
  } catch (e) {
    throw new Error(e)
  }
}

const getAllArticulosByProject = async (projectId, params) => {
  try {
    const { data } = await axios.get(`/api/articulos/proyecto/${projectId}`, {
      params,
    })

    return data
  } catch (e) {
    throw new Error(e)
  }
}

const updatePriceArticuloProyecto = async (id, body) => {
  try {
    await axios.patch(`/api/precioarticuloproyecto/${id}`, body)
  } catch (e) {
    throw new Error(e)
  }
}

const createMultipleArticulos = async body => {
  try {
    const { data } = await axios.post(`/api/articulos/multiple`, body)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const createArticulo = async body => {
  try {
    const { data } = await axios.post(`/api/articulos`, body)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

const deleteArticulo = async id => {
  try {
    await axios.delete(`/api/articulos/${id}`)
  } catch (e) {
    throw new Error(e)
  }
}

export {
  getAllArticulos,
  getAllArticulosByProject,
  updatePriceArticuloProyecto,
  updateArticulo,
  getArticuloByCode,
  getArticuloById,
  createMultipleArticulos,
  getArticuloProyecto,
  createArticulo,
  deleteArticulo,
}
