import NewArticuloBimlink from 'components/Bimlink/NewArticuloBimlink'
import { ArticuloBimLinkProvider } from 'context/ArticuloBimLinkContext'
import { useGroupBimLink } from 'context/GroupBimLinkContext'
import { useHandleUrl } from 'hooks/useHandleUrl'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const NewArticuloPage = ({ articulo = null }) => {
  const { code, name } = useGroupBimLink()
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()
  useEffect(() => {
    if (!code || !name) {
      return navigate(
        changeLocation(location.pathname.replace('/new-articulo', ''))
      )
    }
  }, [])
  return (
    <ArticuloBimLinkProvider articulo={articulo}>
      <NewArticuloBimlink />
    </ArticuloBimLinkProvider>
  )
}

export default NewArticuloPage
