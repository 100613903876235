import { configureStore } from '@reduxjs/toolkit'
import proyectoReducer from '../features/proyecto/proyectoSlice'
import authReducer from '../features/auth/authSlice'

export const store = configureStore({
  reducer: {
    proyecto: proyectoReducer,
    auth: authReducer,
  },
})
