import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getAllProjectsByUserAdmin,
  getSingleProjectByUserAdmin,
  updateImageProject,
  updateSingleProjectByUserAdmin,
} from 'api/projectApi'
import toast from 'react-hot-toast'

export const useGetAllProjectsByUserAdmin = (email, options) => {
  return useQuery({
    queryKey: ['projects-admin', email, options],
    queryFn: () => getAllProjectsByUserAdmin(options),
  })
}


export const useGetSingleProjectByUserAdmin = (
  email,
  idProject,
  enabled = true
) => {
  return useQuery({
    queryKey: ['project-admin', idProject, email],
    queryFn: () => getSingleProjectByUserAdmin(idProject),
    enabled: enabled,
  })
}

export const useUpdateSingleProjectByUserAdmin = (email, idProject) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ body }) => updateSingleProjectByUserAdmin(idProject, body),

    onSuccess: () => {
      toast.success('Proyecto actualizado correctamente')
      queryClient.invalidateQueries(['projects-admin', email])
      queryClient.invalidateQueries(['project-admin', idProject, email])
    },
    onError: () => {
      toast.error('Error al actualizar el proyecto')
    },
  })
}

export const useUpdateImageProject = (idProject, onSuccess) => {
  const queryClient = useQueryClient()
  let toastId

  return useMutation({
    mutationFn: dataurl => updateImageProject(idProject, dataurl),
    onMutate: () => {
      toastId = toast.loading('Actualizando imagen del proyecto...')
    },
    onSuccess: () => {
      toast.dismiss(toastId)
      onSuccess()
      toast.success('Imagen actualizada correctamente')
      queryClient.invalidateQueries(['projects-admin'])
      queryClient.invalidateQueries(['project-admin', idProject])
    },
    onError: () => {
      toast.dismiss(toastId)
      toast.error('Error al actualizar la imagen')
    },
  })
}
