import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getAllMatriculasByProject,
  getAllModulosVisiblesByProject,
  getInstancesByMatricula,
  updateMatricula,
} from 'api/estructuraCostes/moduloTypeApi'
import { useVersionModel } from 'hooks/useVersionModel'
import useVersions from 'hooks/useVersions'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

export const useGetModulosByProject = () => {
  const { idProject } = useParams()
  return useQuery({
    queryKey: ['matriculas-visibles', idProject],
    queryFn: () => getAllModulosVisiblesByProject(idProject),
  })
}

export const useGetAllMatriculasByProject = () => {
  const { idProject } = useParams()
  return useQuery({
    queryKey: ['all-matriculas', idProject],
    queryFn: () => getAllMatriculasByProject(idProject),
  })
}

export const useUpdateMatriculaByProject = () => {
  const queryClient = useQueryClient()
  const { currentVersion } = useVersionModel()

  const { idProject } = useParams()
  return useMutation({
    mutationFn: ({ id, body }) => updateMatricula(id, body),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['all-matriculas', idProject],
      })
      queryClient.invalidateQueries({
        queryKey: ['modulos-articulos', idProject, currentVersion],
      })

      toast.success('Matrícula actualizada correctamente')
    },
  })
}

export const useGetVinculosByMatricula = (enabled = true) => {
  const { idProject, moduloId } = useParams()
  const { version } = useVersions()
  return useQuery({
    queryKey: ['instances-modulo', idProject, moduloId],
    queryFn: () => getInstancesByMatricula(idProject, moduloId, version),
    enabled,
  })
}
