import { createContext, useEffect, useState } from 'react'

export const ViewerContext = createContext()

export const ViewerProvider = ({ children }) => {
  const [externalIdsMapping, setExternalIdsMapping] = useState(null)
  const [currentVersion, setCurrentVersion] = useState(null)
  const [uniqueProperties, setUniqueProperties] = useState([])
  const [allModulos, setAllModulos] = useState([])
  const [allDbIds, setAllDbIds] = useState([])
  const [modulesSelected, setModulesSelected] = useState([])
  const [loadingUpdateMediciones, setLoadingUpdateMediciones] = useState(false)
  const [checkVersion, setCheckVersion] = useState(false)

  useEffect(() => {
    console.log('externalIdsMapping', externalIdsMapping)
  }, [externalIdsMapping])

  useEffect(() => {
    console.log('allModulos', allModulos)
  }, [allModulos])

  const value = {
    externalIdsMapping,
    setExternalIdsMapping,
    uniqueProperties,
    setUniqueProperties,
    allModulos,
    setAllModulos,
    allDbIds,
    setAllDbIds,
    modulesSelected,
    setModulesSelected,
    loadingUpdateMediciones,
    setLoadingUpdateMediciones,
    checkVersion,
    setCheckVersion,
    currentVersion,
    setCurrentVersion,
  }

  return (
    <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
  )
}
