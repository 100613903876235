import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { createPortal } from 'react-dom'
import cn from 'classnames'

const Modal = ({ onClose, modalClass, containerChildrenClass, children }) => {
  return createPortal(
    <div className="overlay">
      <div className={cn('modal', modalClass)}>
        {onClose && (
          <button className="modal-button-close" onClick={onClose}>
            <CloseIcon />
          </button>
        )}
        <div className={cn(containerChildrenClass)}>{children}</div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
