import React from 'react'
import { redondearDecimalesToLocaleString } from '../../../utils/roundNumber'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from '../../../hooks/useHandleUrl'
import { useTotalMo } from 'hooks/useTotalMo'

const FabricaRow = ({ modulo, celulas, mo }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { totalMoByModulo } = useTotalMo()
  const { changeLocation } = useHandleUrl()
  const handleOnClick = () => {
    navigate(changeLocation(`${location.pathname}/${modulo._id}`))
  }

  return (
    <tr onClick={handleOnClick} className="fabrica-table-header">
      <td>
        <div className="fabrica-table-header-name">
          <p className="fabrica-table-header-code">{modulo.code}</p>{' '}
          <p className="fabrica-table-header-type">({modulo.type})</p>
        </div>
      </td>
      <td className="ta-right">
        {redondearDecimalesToLocaleString(
          Number(modulo.totalImport) +
            (Number(totalMoByModulo(modulo.instances.length)) || 0)
        ) || 0}
        €
      </td>
    </tr>
  )
}

export default FabricaRow
