import { useQueryClient } from '@tanstack/react-query'
import FormNewArticulo from 'components/admin/Articulos/FormNewArticulo/FormNewArticulo'
import BoxSkeleton from 'components/admin/BoxSkeleton'
import Modal from 'components/ui/Modal/Modal'
import { useGetArticulo } from 'lib/react-query/queriesArticulos'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from 'react-router-dom'

const EditArticuloAdminPage = () => {
  const { idArticulo } = useParams()
  const { isLoading } = useGetArticulo(idArticulo)
  const navigate = useNavigate()
  const handleOnClose = () => {
    navigate('/admin/articulos')
  }
  const queryClient = useQueryClient()

  const data = queryClient.getQueryData(['articulo', idArticulo])
  return (
    <Modal
      modalClass="modal modal-new-articulo"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div className="modal-link-bimlink-to-tipo-container--width">
        {isLoading ? (
          <Skeleton count={3} height={20} wrapper={BoxSkeleton} />
        ) : (
          <>
            <h2>Editar artículo</h2>
            <FormNewArticulo data={data.data} />
          </>
        )}
      </div>
    </Modal>
  )
}

export default EditArticuloAdminPage
