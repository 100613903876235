import { IconButton, Popover, Tooltip } from '@mui/material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import DeleteIcon from '@mui/icons-material/Delete'
import { CustomButton } from 'components/ui'

const DeleteBimLinkButton = () => {

  return (
    <PopupState variant="popover" popupId="pop-over-delete">
      {popupState => (
        <div>
          <Tooltip
            title={<p className="tooltip-text">Eliminar Bimlink</p>}
            placement="right-end"
          >
            <IconButton onClick={e => e.stopPropagation()}>
              <DeleteIcon {...bindTrigger(popupState)} fontSize="large" />
            </IconButton>
          </Tooltip>
          <Popover
            {...bindPopover(popupState)}
            onClick={e => e.stopPropagation()}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <div style={{ padding: '1rem' }}>
              <p style={{ fontSize: '1.2rem' }}>
                {`¿Estás seguro que quieres eliminar este BimLink?`}
              </p>
              <div className="popover-container">
                <CustomButton
                  type="primary"
                  className="button-popover"
                  onClick={() => {
                    popupState.setOpen(false)
                  }}
                >
                  No
                </CustomButton>
                <CustomButton
                  type="secondary"
                  className="button-popover"
                  onClick={() => {
                    // deleteCelulaMutation.mutate(celula._id)
                    // popupState.setOpen(false)
                  }}
                >
                  Sí
                </CustomButton>
              </div>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

export default DeleteBimLinkButton
