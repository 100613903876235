import FormNewArticulo from 'components/admin/Articulos/FormNewArticulo/FormNewArticulo'
import Modal from 'components/ui/Modal/Modal'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NewArticuloAdminPage = () => {
  const navigate = useNavigate()
  const handleOnClose = () => {
    navigate('/admin/articulos')
  }

  return (
    <Modal
      modalClass="modal modal-new-articulo"
      containerChildrenClass={'new-bimlink-container'}
      onClose={handleOnClose}
    >
      <div className="modal-link-bimlink-to-tipo-container--width">
        <h2>Crear artículo</h2>
        <FormNewArticulo />
      </div>
    </Modal>
  )
}

export default NewArticuloAdminPage
