const default_image = 'https://demofree.sirv.com/nope-not-here.jpg'

const UserItemImage = ({ user }) => {
  return (
    <img
      src={user.picture}
      alt="profile"
      style={{ borderRadius: '50%', width: '50px', height: '50px' }}
      onError={e => (e.target.src = default_image)}
    ></img>
  )
}

export default UserItemImage
