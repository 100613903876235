import axios from 'axios'
import toast from 'react-hot-toast'
export {
  getAllCelulasByProjectId,
  createCelula,
  deleteCelula,
  getCelula,
  updateCelula,
  getImportCelulasByModuloId,
} from './estructuraCostes/celulasJitApi'
export { getAllActivitiesNotInProject } from './estructuraCostes/activitiesJitApi'
export { getModuloC } from './estructuraCostes/modulosJitApi'
export { getAllBimLinks } from './estructuraCostes/bimLinksJitApi'
export {
  createBoq,
  deleteAllBoqByProjectAndVersion,
  getImportByProject,
  createMultipleBoq,
} from './estructuraCostes/boqJitApi'

export { getAllManoDeObra } from './estructuraCostes/manoDeObraApi'
export { getVersionsImportByProject, updateProject } from './projectApi'
/* -------------------------------------------------------------------------- */
/*                              PARTIDA PROYECTO                              */
/* -------------------------------------------------------------------------- */
export const updateVersionPartidasProyecto = async projectId => {
  try {
    const { data } = await axios.patch(
      `/api/v1/proyectos/${projectId}/partidas/status`
    )
    return data
  } catch (err) {
    console.log(
      'Error al actualizar la version de las partidas proyecto: ',
      err
    )
  }
}

export const checkPartidasProjectStatus = async projectId => {
  try {
    const { data } = await axios.get(
      `/api/v1/proyectos/${projectId}/partidas/status`
    )
    return data.data
  } catch (error) {
    console.log(
      'Error al comprobar si las partidas de un proyecto estan actualizadas ',
      error
    )
  }
}

export const updatePartidaProyecto = (partidaProyectoId, body) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/api/v1/partidaproyecto/${partidaProyectoId}`, body)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject('Error en updatePartidaProyecto', err)
      })
  })
}

// ACTUALIZAR TODAS LAS PARTIDAS PROYECTO DE UN PROYECTO
export const updateAllPartidasProyecto = async (projectId, versionId) => {
  const { data } = await axios.patch(
    `/api/v1/proyectos/${projectId}/partidas/updateall/${versionId}`
  )
}

export const getUnitPriceDescompuestoPartidaProyecto = partidaProyectoId => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/v1/partidaproyecto/unitpricedescompuesto/${partidaProyectoId}`)
      .then(res => resolve(res.data.data.data))
      .catch(err => reject('Error', err))
  })
}

export const getQuantityPartidaProyecto = partidaProyectoId => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/v1/partidaproyecto/quantityPartidaProyecto/${partidaProyectoId}`
      )
      .then(res => resolve(res.data.data.data))
      .catch(err => reject('Error', err))
  })
}

export const getAllVersionsByPartidaProyecto = partidaProyectoId => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/v1/partidaproyecto/${partidaProyectoId}/getallversions`)
      .then(res => {
        console.log(res)
        resolve(res?.data?.data)
      })
      .catch(err => reject('Error', err))
  })
}

/* -------------------------------------------------------------------------- */
/*                            DESCOMPUESTO PROYECTO                           */
/* -------------------------------------------------------------------------- */
export const checkIfQuantityVersionDescompuestosPartidaProyectoExists = async (
  version,
  projectId
) => {
  try {
    const { data } = await axios.get(
      `/api/v1/descompuestopartidaproyecto/version/${version}/project/${projectId}/existsquantityversion`
    )
    return data
  } catch (err) {
    console.log(
      'ERROR AL TRAER QUANTITY VERSIONS DESCOMPUESTO PROYECTO BY VERSION'
    )
  }
}

export const getDescompuestosNotInPartidaProyecto = async partidaProyectoId => {
  try {
    const { data } = await axios.get(
      `/api/v1/descompuesto/notincludedinpp/${partidaProyectoId}`
    )
    console.log('data', data)
    return data.data
  } catch (e) {
    console.log(e)
  }
}

/* -------------------------------------------------------------------------- */
/*               QUANTITYVERSIONS DESCOMPUESTO PARTIDA PROYECTO               */
/* -------------------------------------------------------------------------- */

export const createQuantityVersionDescompuestoPartidaProyecto = async body => {
  try {
    const { data: quantityVersion } = await axios.post(
      `/api/v1/quantityversiondescompuestopartidaproyecto`,
      body
    )
    return quantityVersion.data
  } catch (e) {
    console.log('e', e)
  }
}

export const updateQuantityVersionDescompuestoPartidaProyecto = async (
  id,
  body
) => {
  try {
    const { data: quantityVersion } = await axios.patch(
      `/api/v1/quantityversiondescompuestopartidaproyecto/${id}`,
      body
    )
    return quantityVersion.data
  } catch (e) {
    console.log('e', e)
  }
}

/* -------------------------------------------------------------------------- */
/*                      QUANTITYVERSIONS PARTIDA PROYECTO                     */
/* -------------------------------------------------------------------------- */
export const updateQuantityVersionPartidaProyecto = async (id, body) => {
  try {
    const { data: quantityVersion } = await axios.patch(
      `/api/v1/quantityversionpartidaproyecto/${id}`,
      body
    )
    return quantityVersion.data
  } catch (e) {
    console.log('e', e)
  }
}

export const getQuantityVersionsPartidaProyectoByPartidaProyectoAndVersion =
  async (partidaProyectoId, version) => {
    try {
      const { data } = await axios.get(
        `/api/v1/quantityversionpartidaproyecto/${partidaProyectoId}/version/${version}/bypartidaproyectoandversion`
      )
      return data.data
    } catch (err) {
      console.log(
        'ERROR AL TRAER QUANTITY VERSIONS PARTIDA PROYECTO BY PARTIDA PROYECTO AND VERSION'
      )
    }
  }

export const getQuantityVersionsPartidaProyectoByPartidaProyecto =
  async partidaProyectoId => {
    try {
      const { data } = await axios.get(
        `/api/v1/quantityversionpartidaproyecto/${partidaProyectoId}/bypartidaproyecto`
      )
      return data.data
    } catch (err) {
      console.log(
        'ERROR AL TRAER QUANTITY VERSIONS PARTIDA PROYECTO BY PARTIDA PROYECTO'
      )
    }
  }

export const checkIfQuantityVersionPartidaProyectoExists = async (
  version,
  projectId
) => {
  try {
    const { data } = await axios.post(
      `/api/v1/quantityversionpartidaproyecto/version/${version}/project/${projectId}/exists`
    )
    return data
  } catch (err) {
    console.log(
      'ERROR AL TRAER QUANTITY VERSIONS PARTIDA PROYECTO BY PARTIDA PROYECTO'
    )
  }
}

/* -------------------------------------------------------------------------- */
/*                                  PROYECTO                                  */
/* -------------------------------------------------------------------------- */
export const getCurrentProjectAndCheckVersion = async (projectId, version) => {
  console.log('entro aquí también')
  try {
    const { data } = await axios.get(
      `/api/v1/proyectos/${projectId}/currentproject`,
      {
        params: {
          autodeskProjectId: process.env.REACT_APP_AUTODESK_HUB,
          version,
        },
      }
    )
    return data
  } catch (e) {
    console.log('e getCurrentProjectAndCheckVersion', e.response.status)
    if (e.response.status == 404) {
      toast.error(
        'El proyecto al que estás intentando acceder no existe. Redirigiendo a todos los proyectos...'
      )
      setTimeout(() => {
        window.location.href = '/'
      }, 3000)
    }
  }
}

export const getProject = async projectId => {
  const { data } = await axios.get(`/api/v1/proyectos/${projectId}`)
  return data.data
}

export const patchProyecto = async (projectId, body) => {
  try {
    const { data } = await axios.patch(`/api/v1/proyectos/${projectId}`, body)
    return data.data
  } catch (e) {
    throw new Error(e)
  }
}

export const getTotalImportProject = async projectId => {
  try {
    const { data } = await axios.get(
      `/api/v1/proyectos/${projectId}/projectimport`
    )
    console.log('data', data)
    return data
  } catch (e) {}
}

/* -------------------------------------------------------------------------- */
/*                              MODEL DERIVATIVE                              */
/* -------------------------------------------------------------------------- */

export const checkIfUrnHasGeometryInModelDerivatives = async urn => {
  try {
    const data = await axios.get(
      `/api/v1/forge/modelderivative/objectTree/${urn}`
    )
    return data
  } catch (e) {
    throw new Error(e)
  }
}

/* -------------------------------------------------------------------------- */
/*                                  VERSIONS                                  */
/* -------------------------------------------------------------------------- */

export const getVersionByProjectAndNumberVersion = async (
  project,
  numberVersion
) => {
  const { data } = await axios.get(
    `/api/v1/proyectos/${project}/version/byprojectandversion`,
    {
      params: {
        numberVersion,
      },
    }
  )
  return data.data
}

export const getVersionsByProjectV2 = async (project, options = null) => {
  const data = options
  const { data: result } = await axios.get(
    `/api/v1/proyectos/${project}/version/v2`,
    {
      params: {
        ...data,
      },
    }
  )

  return result
}

export const getVersionsByProject = async (project, options = null) => {
  const data = options
  const { data: result } = await axios.get(
    `/api/v1/proyectos/${project}/version`,
    {
      params: {
        ...data,
      },
    }
  )

  return result
}

export const createVersion = async body => {
  const { data } = await axios.post(`/api/v1/version`, body)
  console.log('data', data)
  return data
}

/* -------------------------------------------------------------------------- */
/*                                    HUBS                                    */
/* -------------------------------------------------------------------------- */
export const getVersionsByItem = async item => {
  const itemId = item
  console.log('itemId', itemId)
  return await axios.get(
    `/api/v1/projects/${
      itemId == process.env.REACT_APP_TEST_ITEM
        ? process.env.REACT_APP_TEST_HUB
        : process.env.REACT_APP_AUTODESK_HUB
    }/contents/${itemId}/versions`
  )
}

export const getItem = async item => {
  const itemId = item
  return await axios.get(
    `/api/v1/projects/${
      itemId == process.env.REACT_APP_TEST_ITEM
        ? process.env.REACT_APP_TEST_HUB
        : process.env.REACT_APP_AUTODESK_HUB
    }/contents/${itemId} `
  )
}

export const getVersionByItemAndVersionNumber = async (
  itemId,
  version = null
) => {
  try {
    const currentVersion = version ? version : 'not-defined'
    // console.log('currentVersion', currentVersion)
    const versions = await axios.get(
      `/api/v1/projects/${
        itemId == process.env.REACT_APP_TEST_ITEM
          ? process.env.REACT_APP_TEST_HUB
          : process.env.REACT_APP_AUTODESK_HUB
      }/contents/${itemId}/versions/${currentVersion}`
    )
    return versions
  } catch (e) {
    return e
  }
}

/* -------------------------------------------------------------------------- */
/*                               PARTIDAPROYECTO                              */
/* -------------------------------------------------------------------------- */

export const getPrecioPartidaProyectoSinPorcentajes =
  async partidaProyectoId => {
    const { data } = await axios.get(
      `/api/v1/partidaproyecto/${partidaProyectoId}/descompuesto/getpreciopartida`
    )
    return data.data
  }

export const getCurrentPartidaProyecto = async partidaProyectoId => {
  const { data } = await axios.get(
    `/api/v1/partidaproyecto/${partidaProyectoId} `
  )
  return data.data
}

export const getPartidaProyecto = async (projectId, partidaId) => {
  const { data } = await axios.get(
    `/api/v1/proyectos/${projectId}/partidas/${partidaId}/getpartida`
  )
  return data.data
}

export const getPartidaProyectoByProjectId = async (projectId, paramsQuery) => {
  const params = {
    page: paramsQuery.page,
    limit: paramsQuery.limit,
    sort: paramsQuery.sortBy,
    minPrice: paramsQuery.minPrice,
    maxPrice: paramsQuery.maxPrice,
    ['partida.name']: paramsQuery.search,
    ['partida.code']: paramsQuery.search,
    version: paramsQuery.version,
    capitulos: paramsQuery.capitulos,
    version: paramsQuery.version,
  }

  const { data } = await axios.get(`/api/v1/proyectos/${projectId}/partidas`, {
    params,
  })

  console.log('DATAAAAA: ', data)

  return data
}

export const deletePartidaProyecto = async (proyectoId, partidaId) => {
  await axios.delete(`/api/v1/proyectos/${proyectoId}/partidas/${partidaId}`)
  return
}

export const getAllPartidasProyectosFilteredByModulo = async (
  proyectoId,
  options
) => {
  const { modules, page, limit } = options
  const modulesIdString = modules.join(',')
  const { data: modulos } = await axios.get(
    `/api/v1/proyectos/${proyectoId}/partidas`,
    {
      params: {
        modulos: modulesIdString,
        page,
        limit,
      },
    }
  )
  return modulos
}

export const getAllPartidasProyectosByProjectId = async proyectoId => {
  const { data: partidas } = await axios.get(
    `/api/v1/proyectos/${proyectoId}/partidas`
  )
  return partidas
}

/* -------------------------------------------------------------------------- */
/*                       QuantityVersionPartidaProyecto                       */
/* -------------------------------------------------------------------------- */
export const createQuantityVersionPartidaProyecto = async body => {
  try {
    const { data: quantityVersion } = await axios.post(
      `/api/v1/quantityversionpartidaproyecto`,
      body
    )
    return quantityVersion
  } catch (e) {
    console.log('e', e)
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Mediciones                                 */
/* -------------------------------------------------------------------------- */
export const checkIfMedicionesByVersionExists = async (version, projectId) => {
  try {
    const { data } = await axios.get(
      `/api/v1/medicion/version/${version}/project/${projectId}/exists`
    )
    return data
  } catch (err) {
    console.log('ERROR AL TRAER MEDICIONES BY VERSION')
  }
}

export const deleteAllMedicionsByPartidaProyecto = async currentPartidaId => {
  await axios.delete(`/api/v1/medicion/partidaProyecto/${currentPartidaId}`)
  return
}

export const addMedicionByPartidaProyecto = async (projectId, body) => {
  const { data } = await axios.post(`/api/v1/medicion/`, body)
  return data
}

export const deleteMedicion = async idMedicion => {
  await axios.delete(`/api/v1/medicion/${idMedicion}`)
  return
}

export const getAlMedicionsByProyecto = async idProject => {
  const { data } = await axios.get(`/api/v1/medicion/proyecto/${idProject}`)
  return data
}

export const getAllMedicionesByPartidaProyectoAndVersion =
  partidaProyectoId => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/medicion/partidaproyecto/${partidaProyectoId}/medicions`)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject('Error', err))
    })
  }
/* -------------------------------------------------------------------------- */
/*                                   MODULOS                                  */
/* -------------------------------------------------------------------------- */
export const getModuloName = async moduloId => {
  try {
  } catch (err) {
    console.log('Error al traer el nombre del modulo: ', err)
  }
}

// ! NO SE USA
export const getModulo = async moduloId => {
  try {
    const [data, moduloName] = await Promise.all([
      axios.get(`/api/v1/modulo/${moduloId}/getmodulo`),
      axios.get(`/api/v1/modulo/${moduloId}/getmoduloname`),
    ])

    return {
      descompuestos: data.data.descompuestosAgrupados,
      moduloName: moduloName.data.data[0].name,
    }
  } catch (err) {
    console.log('Error al traer un modulo: ', err)
    throw new Error(err)
  }
}

export const getDataModulo = async moduloId => {
  const { data } = await axios.get(`/api/v1/modulo/${moduloId}/getdata`)
  return data.data
}

export const createModulo = async ({ projectId, body }) => {
  const { data } = await axios.post(
    `/api/v1/proyectos/${projectId}/modulos`,
    body
  )
  return data.data
}

export const deleteModulo = async ({ projectId, moduloId }) => {
  const { data } = await axios.delete(
    `/api/v1/proyectos/${projectId}/modulos/${moduloId}`
  )
  return
}

export const patchModulo = async ({ projectId, moduloId, body }) => {
  const { data } = await axios.patch(
    `/api/v1/proyectos/${projectId}/modulos/${moduloId}`,
    body
  )
  return data.data
}

export const getModulosByProject = async projectId => {
  const { data } = await axios.get(
    `/api/v1/proyectos/${projectId}/modulos/project`
  )
  return data
}

export const getModulosByProjectId = async projectId => {
  const { data } = await axios.get(`/api/v1/proyectos/${projectId}/modulos`)
  return data.data
}

export const getCodeAndNameModulosByProjectId = async projectId => {
  try {
    const { data } = await axios.get(
      `/api/v1/proyectos/${projectId}/modulos/codename`
    )
    return data.data
  } catch (err) {
    console.log('Error al traer los modulos por id de proyecto: ', err)
  }
}

export const getModulosNameByProjectId = async projectId => {
  const { data } = await axios.get(
    `/api/v1/proyectos/${projectId}/modulos/name`
  )
  return data.data
}

/* -------------------------------------------------------------------------- */
/*                                  DESCOMPUESTOS                                  */
/* -------------------------------------------------------------------------- */

export const deleteDescompuesto = async descompuestoId => {
  await axios.delete(`/api/v1/descompuesto/${descompuestoId}`)
  return
}

export const deleteDescompuestoProjectById = async descompuestoId => {
  await axios.delete(`/api/v1/preciodescompuestoproyecto/${descompuestoId}`)
  return
}

export const getDescompuestoById = async descompuestoId => {
  const { data } = await axios.get(`/api/v1/descompuesto/${descompuestoId}`)
  return data.data
}

export const getDescompuestoByCode = async code => {
  const { data } = await axios.get(`/api/v1/descompuesto/code/${code}`)
  return data.data
}

export const getAllDescompuestos = async () => {
  const { data } = await axios.get(`/api/v1/descompuesto`)
  return data.data
}

export const getAllDescompuestosWithSearch = async search => {
  const { data } = await axios.get(`/api/v1/descompuesto/search`, {
    params: { search },
  })
  return data.data
}

export const updateDescompuesto = async (descompuestoId, body) => {
  const { data } = await axios.patch(
    `/api/v1/descompuesto/${descompuestoId}`,
    body
  )
  return data
}

export const deleteAllDescompuestosProyectoByProjectId = async projectId => {
  await axios.delete(
    `/api/v1/preciodescompuestoproyecto/${projectId}/descompuestos`
  )
  return
}

export const deleteDescompuestosPartidaProyecto = async partidaProyectoId => {
  await axios.delete(`/api/v1/descompuestopartidaproyecto/${partidaProyectoId}`)
  return
}

export const getDescompuestosByProjectIdNotIncluded = async (
  projectId,
  descompuestos
) => {
  const idDescompuestos = descompuestos.map(
    descompuesto => descompuesto.precioDescompuestoProyecto._id
  )
  const { data } = await axios.get(
    `/api/v1/preciodescompuestoproyecto/noincluded/${projectId}`,
    { params: idDescompuestos }
  )
  return data.data
}

export const getDescompuestosNoIncludedByProjectId = async projectId => {
  const { data } = await axios.get(
    `/api/v1/descompuesto/noincluded/${projectId}`
  )
  return data.data
}

export const getDescompuestosIncludedByProjectId = async projectId => {
  const { data } = await axios.get(
    `/api/v1/preciodescompuestoproyecto/${projectId}/descompuestos`
  )
  return data.data
}

export const createDescompuestoPartidaProyecto = async body => {
  const { data } = await axios.post('/api/v1/descompuestopartidaproyecto', body)
  return data.data
}

export const getDescompuestosByProjectId = async (projectId, inputSearch) => {
  const { data } = await axios.get(
    `/api/v1/preciodescompuestoproyecto/${projectId}/descompuestos`,
    {
      params: {
        search: inputSearch,
      },
    }
  )
  return data.data
}

export const createMultipleDescompuestos = async body => {
  try {
    const { data } = await axios.post(`/api/v1/descompuesto/multiple`, body)
    return data
  } catch (e) {
    console.log('e', e)
  }
}

/* -------------------------------------------------------------------------- */
/*                        DESCOMPUESTOSPARTIDAPROYECTO                        */
/* -------------------------------------------------------------------------- */
export const getInfoDescompuestoPartidaProyecto = async descompuestoId => {
  try {
    const { data } = await axios.get(
      `/api/v1/descompuestopartidaproyecto/${descompuestoId}/getdetailinfodescompuesto/`
    )
    return data?.data
  } catch (e) {}
}

export const getDescompuestosByPartidaProyectoId = async partidaProyectoId => {
  const { data } = await axios.get(
    `/api/v1/descompuestopartidaproyecto/${partidaProyectoId}/getall`
  )
  return data.data
}

export const addDescompuesto = async (projectId, body) => {
  const { data } = await axios.post(
    `/api/v1/preciodescompuestoproyecto/${projectId}/descompuestos`,
    body
  )
  return data.data
}

export const createDescompuesto = async body => {
  const { data } = await axios.post(`/api/v1/descompuesto`, body)
  return data
}

export const updateFieldDescompuestoProyecto = async (
  projectId,
  descompuestoId,
  body
) => {
  const { data } = await axios.patch(
    `/api/v1/preciodescompuestoproyecto/${projectId}/descompuestos/${descompuestoId}`,
    body
  )
  return data.status
}

export const updateFieldDescompuesto = async (descompuestoId, body) => {
  const { data } = await axios.patch(
    `/api/v1/descompuesto/${descompuestoId}`,
    body
  )
  return data.status
}

export const createDescompuestoProject = async (
  descompuestoId,
  projectId,
  price
) => {
  const { data } = await axios.post(`/api/v1/preciodescompuestoproyecto`, {
    descompuesto: descompuestoId,
    proyecto: projectId,
    precio: price,
  })
  return data.data
}

export const updatePrecioDescompuestoProyecto = async (projectId, body) => {
  try {
    const { data } = await axios.patch(
      `/api/v1/preciodescompuestoproyecto/${projectId}`,
      body
    )
    return data.data
  } catch (err) {
    console.log('ERROR AL ACTUALIZAR PRECIO DESCOMPUESTO PROYECTO: ', err)
  }
}

export const updateDescompuestoPartidaProyecto = async (
  partidaProyectoId,
  body
) => {
  const { data } = await axios.patch(
    `/api/v1/descompuestopartidaproyecto/${partidaProyectoId}`,
    body
  )
  return data.data
}

/* -------------------------------------------------------------------------- */
/*                                 MEDICIONES                                 */
/* -------------------------------------------------------------------------- */

export const addMedicion = async body => {
  const { data } = await axios.post('/api/v1/medicion', body)
  return data
}

export const patchMedicion = async body => {
  const { id, ...rest } = body

  const { data } = await axios.patch(`/api/v1/medicion/${id}`, { ...rest })
  return data
}

/* -------------------------------------------------------------------------- */
/*                                  CAPÍTULOS PROYECTO                                 */
/* -------------------------------------------------------------------------- */

export const createInitialsCapitulos = async projectId => {
  const { data } = await axios.post(
    `/api/v1/proyectos/${projectId}/capitulos/initial`
  )
  return data
}

export const createCapituloProyecto = async (projectId, selectedCapitulo) => {
  const { data } = await axios.post(
    `/api/v1/proyectos/${projectId}/capitulos`,
    { proyecto: projectId, capitulo: selectedCapitulo }
  )

  return data
}

export const getAllCapitulosProyectosByProjectId = async (projectId, query) => {
  const { data } = await axios.get(`/api/v1/proyectos/${projectId}/capitulos`, {
    params: query,
  })
  return data.data
}

export const getAllCapitulosByProjectNoParams = async projectId => {
  const { data } = await axios.get(`/api/v1/proyectos/${projectId}/capitulos`)
  return data.data.data
}

export const getOneCapituloProyecto = async (projectId, capituloProyectoId) => {
  const { data } = await axios.get(
    `/api/v1/proyectos/${projectId}/capitulos/${capituloProyectoId}`
  )
  return data.data
}

/* -------------------------------------------------------------------------- */
/*                                  CAPITULOS                                 */
/* -------------------------------------------------------------------------- */
export const getAllCapitulos = async () => {
  const { data } = await axios.get('/api/v1/capitulos')
  return data
}

export const createProject = async body => {
  const { data } = await axios.post('/api/v1/proyectos', body)
  return data
}

export const getPartida = async partidaId => {
  const { data } = await axios.get(`/api/v1/partidas/${partidaId}`)
  return data.data
}

export const editPartida = async (partidaId, body) => {
  const { data } = await axios.patch(`/api/v1/partidas/${partidaId}`, body)
  return data.data
}

export const getPartidProyectoByPartida = async partidaId => {
  const { data } = await axios.get(
    `/api/v1/partidaproyecto/bypartida/${partidaId}`
  )
  return data.data
}

export const getPartidaStats = async () => {
  const { data } = await axios.get(`/api/v1/partidas/stats`)
  return data.data
}

/* -------------------------------------------------------------------------- */
/*                                DIGITAL OCEAN                               */
/* -------------------------------------------------------------------------- */

export const deleteFile = async key => {
  if (!key) return
  try {
    await axios.delete(`/api/v1/do/${key}`)
  } catch (error) {
    console.log('Error al eliminar el archivo: ', error)
  }
}

export const uploadFileDigitalOcean = async (file, nameImg) => {
  let formData = new FormData()
  let name = '?file_name=' + nameImg
  let setAcl = 'public-read'
  let acl = '&acl=' + setAcl
  let type = '&content_type=' + file.type

  const url = '/api/v1/do/upload' + name + acl + type

  formData.append('image', file)
  formData.append('MAX_FILE_SIZE', '20000000') // 20MB

  try {
    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    return res.data
  } catch (error) {
    console.log('Error al subir el archivo: ', error)
  }
}
