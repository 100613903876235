import { useAuth0 } from '@auth0/auth0-react'
import UserItemImage from './UserItemProyecto/UserItemImage'
import UserItemEmail from './UserItemProyecto/UserItemEmail'
import UserItemBadgeRole from './UserItemProyecto/UserItemBadgeRole'
import DeleteUserProyectoButton from './UserItemProyecto/DeleteUserProyectoButton'

const UserItem = ({ user, rol, handleDeleteUser = null }) => {
  const { user: currentUser } = useAuth0()
  const isCurrentUser = user.user_id === currentUser.sub
  return (
    <div className="item-user-proyecto-container">
      <UserItemImage user={user} />
      <UserItemEmail user={user} />
      <UserItemBadgeRole rol={rol} />
      {!isCurrentUser && (
        <DeleteUserProyectoButton user={user} handleDeleteUser={handleDeleteUser} />
      )}
    </div>
  )
}

export default UserItem
