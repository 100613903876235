import React from 'react'
import FormEditProyecto from './FormEditProyecto'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams } from 'react-router-dom'
import EditImageProyecto from './EditImageProyecto'

const ConfigProyecto = () => {
  const queryClient = useQueryClient()
  const { idProject } = useParams()
  const { user } = useAuth0()
  const { data } = queryClient.getQueryData([
    'project-admin',
    idProject,
    user.sub,
  ])
  return (
    <div className="edit-proyecto-form-config-container">
      <FormEditProyecto proyecto={data} />
      <EditImageProyecto />
    </div>
  )
}

export default ConfigProyecto
