import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { getCelula } from '../../../api/jitApi'
import EditCelula from './EditCelula'
import ArticulosTableLoader from '../../Loaders/ArticulosTableLoader'

const EditCelulaWrapper = () => {
  const { celulaId, idProject } = useParams()
  // TODO: Comprobar que el id pasado corresponde a una célula y al proyecto asignado
  const { data, isLoading, isFetching, error } = useQuery(
    ['edit-celula', celulaId],
    () => getCelula(celulaId)
  )

  if (isLoading) return <ArticulosTableLoader />
  if (error) {
    return (
      <Navigate
        to={`/viewer/${idProject}/fabrica/gestionar-celulas`}
        replace={true}
      />
    )
  }

  if (!data || isLoading || isFetching) return <ArticulosTableLoader />
  if (data.proyecto !== idProject)
    return (
      <Navigate
        to={`/viewer/${idProject}/fabrica/gestionar-celulas`}
        replace={true}
      />
    )

  //TODO: Si da error o el proyecto no corresponde con el proyecto asignado, redirigir a gestionar-celulas

  return <EditCelula celula={data} />
}

export default EditCelulaWrapper
