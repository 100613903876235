import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSearch } from './useSearch'

export const useHandleUrl = () => {
  const { idItem } = useParams()
  let query = useSearch()
  let version = useMemo(() => query.get('version'), [query])

  const changeLocation = path => {
    return version ? `${path}?version=${version}` : path
  }

  return { idItem, version, changeLocation }
}
