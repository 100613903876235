import { createContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

export const RoleContext = createContext()

export const RoleProvider = ({ children }) => {
  const { user } = useAuth0()
  const [role, setRole] = useState(null)

  useEffect(() => {
    if (user) {
      ;(async () => {
        const { data } = await axios.get(
          `/api/v1/auth0/getUserData/${user.sub}`
        )
        setRole(data?.data[0]?.name)
      })()
    }
  }, [user])

  const value = {
    role,
    setRole,
  }

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>
}
