import { Tooltip } from 'react-tooltip'

const BadgeCell = ({ code, areSame, onClick }) => {
  return (
    <div className="badge-container ">
      <div className="badge">{code}</div>
      {!areSame && (
        <div className="warning-tipos-button" onClick={onClick}>
          <p className="warning-tipos-icon">!</p>
        </div>
      )}
      <Tooltip
        anchorSelect=".warning-tipos-button"
        style={{ zIndex: '99999999' }}
      >
        Hay diferencias con los elementos guardados en BD y la versión actual.
      </Tooltip>
    </div>
  )
}

export default BadgeCell
