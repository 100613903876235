import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cls } from 'lib/classnames/cn'
import { forwardRef } from 'react'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverContent = forwardRef(
  ({ className, align = 'center', sideOffset = 4, ...props }, ref) => {
    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          sideOffset={sideOffset}
          className={cls('custom-popover', className)}
          {...props}
        />
      </PopoverPrimitive.Portal>
    )
  }
)

PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent }
