import { Button, CircularProgress, MenuItem, TextField } from '@mui/material'
import React, { useState } from 'react'
import InputThemeProvider from '../Common/InputThemeProvider'
import { useFormik } from 'formik'
import { createProject } from '../../api/jitApi'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import toast from 'react-hot-toast'

const CreateProject = () => {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const [loadingAdd, setLoadingAdd] = useState(false)

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      description: '',
      client: 20000,
      status: '',
      codeType: '',
      user: user.sub,
    },
    onSubmit: values => {
      setLoadingAdd(true)
      addNewProject(values)
    },
  })

  const codeType = [
    {
      value: 'Jit',
    },
    {
      value: 'Cliente',
    },
  ]

  const status = [
    {
      value: 'Planificación',
    },
    {
      value: 'Oferta',
    },
    {
      value: 'Abierto',
    },
    {
      value: 'Completado',
    },
  ]

  const addNewProject = async project => {
    try {
      let res = await createProject(project)
      console.log('PROYECTO NUEVO: ', res)
      if (res.status === 'success') {
        setLoadingAdd(false)
        toast.success('Proyecto creado correctamente')
        navigate('/')
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <form style={{ height: '100%' }} onSubmit={formik.handleSubmit}>
      <div className="main-container d-flex j-center">
        <div style={{ width: '70%', padding: '0 5rem' }}>
          <h1 className="montserrat title-form">Crear proyecto</h1>
          <InputThemeProvider>
            <div className="pt-4">
              <div className="d-flex g-04">
                <div className="d-flex d-column pb-4" style={{ width: '50%' }}>
                  <p>Código *</p>
                  <TextField
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    style={{ width: '100%' }}
                    name="code"
                    InputLabelProps={{ shrink: false }}
                    label
                  />
                </div>
                <div className="d-flex d-column pb-4" style={{ width: '50%' }}>
                  <p>Tipo de Código *</p>
                  <TextField
                    value={formik.values.codeType}
                    onChange={formik.handleChange}
                    style={{ width: '100%' }}
                    name="codeType"
                    label
                    InputLabelProps={{ shrink: false }}
                    select
                  >
                    {codeType.map(item => (
                      <MenuItem value={item.value}>{item.value}</MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <div className="d-flex d-column pb-4">
                <p>Name *</p>
                <TextField
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                  label
                  InputLabelProps={{ shrink: false }}
                />
              </div>
              <div className="d-flex d-column pb-4">
                <p>Descripcion</p>
                <TextField
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  name="description"
                  label
                  InputLabelProps={{ shrink: false }}
                  multiline
                  rows={4}
                />
              </div>
              <div className="d-flex d-column pb-4">
                <p>Status *</p>
                <TextField
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                  label
                  InputLabelProps={{ shrink: false }}
                  name="status"
                  select
                  value={formik.values.status}
                >
                  {status.map(item => (
                    <MenuItem value={item.value}>{item.value}</MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="d-flex d-column pb-4">
                <p>Cliente *</p>
                <TextField
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                  name="client"
                  label
                  InputLabelProps={{ shrink: false }}
                  disabled
                  value={formik.values.client}
                />
              </div>
            </div>
          </InputThemeProvider>

          {/* BOTONES  */}
          <div className="d-flex a-center j-flex-end g-15 ">
            <Link to="/">
              <Button
                type="button"
                color="primary"
                variant="contained"
                className="button-white t-transform-none"
                style={{ borderRadius: '0' }}
              >
                Cancelar
              </Button>
            </Link>
            <Button
              disabled={
                !formik.values.client ||
                !formik.values.status ||
                !formik.values.codeType ||
                !formik.values.name ||
                !formik.values.code
              }
              endIcon={loadingAdd && <CircularProgress size={20} />}
              type="submit"
              variant="contained"
              className={`t-transform-none ${
                !formik.values.client ||
                !formik.values.status ||
                !formik.values.codeType ||
                !formik.values.name ||
                !formik.values.code
                  ? 'btn-submit-disabled'
                  : 'btn-submit'
              }`}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default CreateProject
