import TextInput from 'components/ui/Forms/TextInput'
import { useConfigMatriculas } from 'context/ConfigMatriculasContext'
import React from 'react'

const ConfigMatriculasBuscador = () => {
  const { textFieldValue, onChangeTextFieldValue } = useConfigMatriculas()
  return (
    <div className="buscador-container-matriculas">
      <TextInput
        name="matrícula"
        onChange={onChangeTextFieldValue}
        placeholder="Buscar por matrícula"
        value={textFieldValue}
        containerStyles={{ height: '100%', width: '100%' }}
      />
    </div>
  )
}

export default ConfigMatriculasBuscador
