import React from 'react'

const ButtonUploadImage = ({
  description,
  classNameButton,
  handleOnChangeImage,
}) => {
  return (
    <button type="button" className={classNameButton}>
      <label className="label-button-new-image-do" htmlFor="upload-image-do">
        {description}
      </label>
      <input
        id="upload-image-do"
        accept="image/*"
        type="file"
        hidden
        onChange={handleOnChangeImage}
      />
    </button>
  )
}

export default ButtonUploadImage
