import React from 'react'
import GroupItems, {
  GroupItemsBody,
  GroupItemsTitle,
  GroupSingleItem,
} from '../GroupItems/GroupItems'
import { CustomButton } from 'components/ui'
import AddIcon from '@mui/icons-material/Add'
import { useGroupBimLink } from 'context/GroupBimLinkContext'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHandleUrl } from 'hooks/useHandleUrl'
import { ButtonsGroupItemBimLink } from '../ButtonsGroupItemBimLink'

const RulesGroupBimLink = () => {
  const { rules, deleteRule } = useGroupBimLink()
  const navigate = useNavigate()
  const location = useLocation()
  const { changeLocation } = useHandleUrl()

  const goToNewRule = () => {
    navigate(changeLocation(`${location.pathname}/new-rule`))
  }
  return (
    <GroupItems className="overflow-unset">
      <GroupItemsTitle>Reglas</GroupItemsTitle>
      <CustomButton
        type="light"
        className="custom-input-container group-bimlinks-container-button"
        onClick={goToNewRule}
      >
        <AddIcon />
        <p style={{ fontSize: '14px', marginLeft: '1rem' }}>
          Crear nueva regla
        </p>
      </CustomButton>
      {rules.length > 0 ? (
        <GroupItemsBody>
          {rules.map((rule, index) => (
            <GroupSingleItem
              key={index}
              name={`${rule.name} - ${rule.value}`}
              Icon={WorkspacesIcon}
              ButtonsComponent={ButtonsGroupItemBimLink}
              buttonsProps={{
                handleEdit: () => console.log('Edit'),
                handleDelete: () => deleteRule(rule.uuid),
              }}
            ></GroupSingleItem>
          ))}
        </GroupItemsBody>
      ) : (
        <p
          style={{
            textAlign: 'center',
            margin: '1rem 0',
            fontStyle: 'italic',
          }}
        >
          No hay ninguna regla creada todavía
        </p>
      )}
    </GroupItems>
  )
}

export default RulesGroupBimLink
