import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useVersionModel } from './useVersionModel'

export const useHeaderNavigation = () => {
  const queryClient = useQueryClient()
  const { idProject, moduloId, celulaId, idarticulo } = useParams()
  const { currentVersion } = useVersionModel()

  const moduloName = useMemo(() => {
    return (
      queryClient
        .getQueryData(['modulos-articulos', idProject, currentVersion])
        ?.modulos?.find(mod => mod._id == moduloId)?.code || null
    )
  }, [
    queryClient.getQueryData(['modulos-articulos', idProject, currentVersion]),
    moduloId,
  ])

  const celulaName = useMemo(() => {
    return (
      queryClient
        .getQueryData(['celulas', idProject])
        ?.data?.find(cel => cel._id == celulaId)?.name || null
    )
  }, [queryClient.getQueryData(['celulas', idProject])])

  const articuloName = useMemo(() => {
    const articulo = queryClient.getQueryData([
      'articulo',
      idProject,
      idarticulo,
    ])

    if (!articulo) return null

    return `${articulo.data.code} - ${articulo.data.name}`
  }, [queryClient.getQueryData(['articulo', idProject, idarticulo])])

  const getParamName = param => {
    const displaySegments = {
      fabrica: 'Fábrica',
      settings: 'Gestionar módulos',
      newmodulo: 'Agregar módulo',
      [moduloId]: moduloName || '',
      'gestionar-celulas': 'Gestionar células',
      'nueva-celula': 'Nueva célula',
      [celulaId]: celulaName || '',
      'config-mo': 'Gestionar coste de mano de obra',
      'config-matriculas': 'Configurar matrículas',
      'config-tipos': 'Configurar tipos',
      datos: 'Datos',
      transporte: 'Transporte',
      montaje: 'Montaje',
      repasos: 'Repasos',
      bimlink: 'BimLinks',
      articulos: 'Artículos',
      edit: 'Editar',
      tipos: 'Tipos',
      [idarticulo]: articuloName || '',
    }

    const displaySegment = displaySegments[param] || ''
    return displaySegment
  }

  return { getParamName, moduloName, celulaName }
}
