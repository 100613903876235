import React from 'react'

const LoadingPage = () => {
  return (
    <div className="container-loader-page">
      <span class="loader-page"></span>
    </div>
  )
}

export default LoadingPage
