import axios from 'axios'

export const getAllModulosVisiblesByProject = async idProject => {
  try {
    const { data } = await axios.post(`/api/modulos/${idProject}/getmodulos`, {
      filter: {
        visible: true,
      },
    })

    return data
  } catch (e) {
    throw new Error(e)
  }
}

export const getAllMatriculasByProject = async idProject => {
  try {
    const { data } = await axios.get(`/api/modulos/${idProject}`)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export const updateMatricula = async (id, body) => {
  try {
    const { data } = await axios.patch(`/api/modulos/${id}`, body)
    return data
  } catch (e) {
    throw new Error(e)
  }
}

export const getInstancesByMatricula = async (idProject, idModulo, version) => {
  try {
    const { data } = await axios.get(
      `/api/modulos/${idProject}/getinstances/${idModulo}`,
      {
        params: {
          version: version,
        },
      }
    )

    return data
  } catch (e) {
    throw new Error(e)
  }
}
