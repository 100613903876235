import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ListUsersLoader = ({ length = 8, height = '300px' }) => {
  return (
    <div className="list-user-loader-skeleton">
      {[
        ...new Array(length)
          .fill(0)
          .map(() => <Skeleton count={1} height={height} />),
      ]}
    </div>
  )
}

export default ListUsersLoader
