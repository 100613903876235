/* global Autodesk, Chart */
import { BaseExtension } from './BaseExtension.js'
import { HistogramPanel } from './HistogramPanel.js'

class HistogramExtension extends BaseExtension {
  constructor(viewer, options) {
    super(viewer, options)
    this._barChartButton = null
    this._barChartPanel = null
  }

  async load() {
    super.load()
    await this.loadScript(
      'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.5.1/chart.min.js',
      'Chart'
    )
    Chart.defaults.plugins.legend.display = false
    console.log('HistogramExtension loaded.')
    return true
  }

  unload() {
    super.unload()
    this.removeToolbarButton(this._barChartButton)
    this._barChartButton = null
    this._barChartPanel.setVisible(false)
    this._barChartPanel.uninitialize()
    this._barChartPanel = null
    console.log('HistogramExtension unloaded.')
    return true
  }

  onToolbarCreated() {
    this._barChartPanel = new HistogramPanel(
      this,
      'dashboard-barchart-panel',
      'Auditoria JIT Housing',
      { x: 100, y: 100, chartType: 'bar', height: 450 }
    )
    this._barChartButton = this.createToolbarButton(
      'dashboard-barchart-button',
      'https://img.icons8.com/small/32/bar-chart.png',
      'Auditoria JIT Housing'
    )
    this._barChartButton.onClick = () => {
      this._barChartPanel.setVisible(!this._barChartPanel.isVisible())
      this._barChartButton.setState(
        this._barChartPanel.isVisible()
          ? Autodesk.Viewing.UI.Button.State.ACTIVE
          : Autodesk.Viewing.UI.Button.State.INACTIVE
      )
      if (this._barChartPanel.isVisible() && this.viewer.model) {
        this._barChartPanel.setModel(this.viewer.model)
      }
    }
  }

  onModelLoaded(model) {
    super.onModelLoaded(model)
    if (this._barChartPanel && this._barChartPanel.isVisible()) {
      this._barChartPanel.setModel(model)
    }
  }

  async findPropertyValueOccurrences(model, propertyName) {
    const dbids = await this.findLeafNodes(model)
    return new Promise(function (resolve, reject) {
      model.getBulkProperties(
        dbids,
        { propFilter: [propertyName] },
        function (results) {
          let histogram = new Map()
          for (const result of results) {
            if (result.properties.length > 0) {
              const key = result.properties[0].displayValue
              if (histogram.has(key)) {
                histogram.get(key).push(result.dbId)
              } else {
                histogram.set(key, [result.dbId])
              }
            }
          }
          resolve(histogram)
        },
        reject
      )
    })
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  'HistogramExtension',
  HistogramExtension
)
