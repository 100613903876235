import { CustomButton } from 'components/ui'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Tooltip } from 'react-tooltip'
export const ButtonsGroupItemBimLink = ({
  handleEdit = null,
  handleDelete = null,
}) => {
  return (
    <>
      <CustomButton
        type="secondary"
        style={{ padding: '.5rem 1rem' }}
        onClick={handleEdit && handleEdit}
        // disabled={true}
        className="edit-button-bimlink"
      >
        <EditIcon />
      </CustomButton>
      <CustomButton
        type="secondary"
        style={{ padding: '.5rem 1rem' }}
        onClick={handleDelete && handleDelete}
        className="delete-button-bimlink"
      >
        <DeleteIcon />
      </CustomButton>
      <Tooltip
        anchorSelect=".edit-button-bimlink"
        style={{
          fontSize: '9px',
        }}
      >
        Editar
      </Tooltip>
      <Tooltip
        anchorSelect=".delete-button-bimlink"
        style={{
          fontSize: '9px',
        }}
      >
        Eliminar
      </Tooltip>
    </>
  )
}
