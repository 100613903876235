import React, { useState } from 'react'
import CelulaActivities from './CelulaActivities'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Tooltip } from '@mui/material'
import ButtonDeleteCelula from './ButtonDeleteCelula'
import { useLocation, useNavigate } from 'react-router-dom'

const CelulaRow = ({ celula }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activitiesOpen, setActivitiesOpen] = useState(false)

  const handleOnClick = () => {
    setActivitiesOpen(oldState => !oldState)
  }
  return (
    <>
      <tr
        className="modulo-table__celula-row"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          width: '100%',
        }}
        onClick={handleOnClick}
      >
        <td
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
          }}
        >
          {activitiesOpen ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )}
          <div>{celula.name}</div>
        </td>
        <div style={{ display: 'flex' }}>
          <Tooltip
            title={<p className="tooltip-text">Editar célula</p>}
            placement="top"
          >
            <IconButton
              onClick={e => {
                e.stopPropagation()
                navigate(`${location.pathname}/${celula._id}`)
              }}
            >
              <EditIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <ButtonDeleteCelula celula={celula} />
        </div>
      </tr>

      {activitiesOpen && (
        <CelulaActivities
          activities={celula.activities}
          showArticulos={false}
        />
      )}
    </>
  )
}

export default CelulaRow
