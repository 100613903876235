const UserItemBadgeRole = ({ rol, icon = false }) => {
  return (
    <div>
      <div className="user-item-badge-role-container">
        <div
          className={`user-item-badge-role ${rol === 'Administrador' ? 'user-item-badge-role--admin' : 'user-item-badge-role--user'}`}
        >
          <p
            className={`user-item-badge-role-text  ${rol === 'Administrador' ? 'user-item-badge-role-text--admin' : 'user-item-badge-role-text--user'}`}
          >
            {rol}
          </p>
          {icon && <ion-icon name="chevron-down-outline" style={{color: ""}}></ion-icon>}
        </div>
      </div>
    </div>
  )
}

export default UserItemBadgeRole
