const { useEffect } = require('react')

export const useClickOutside = (ref, callback) => {
  const handleClick = e => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      ref.current &&
      !ref.current.contains(e.target)
    ) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}
