import React from 'react'
import WrapperPage from '../components/Wrapper/WrapperPage'
import Header from '../components/Header'
import CreateProject from '../components/project/CreateProject'
import { useAuth0 } from '@auth0/auth0-react'

const NewProject = () => {
  const { user } = useAuth0()
  const { email, picture } = user
  return (
    <WrapperPage>
      <CreateProject />
    </WrapperPage>
  )
}

export default NewProject
