import ArticulosTable from 'components/ArticulosTable/ArticulosTable'
import BuscadorArticulos from 'components/ArticulosTable/BuscadorArticulos'
import { ArticulosTablePageProvider } from 'context/ArticulosTablePageContext'

function Articulos() {
  return (
    <ArticulosTablePageProvider>
      <div className="articulos-page-container">
        <BuscadorArticulos />
        <ArticulosTable />
      </div>
    </ArticulosTablePageProvider>
  )
}

export default Articulos
