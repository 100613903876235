import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import UserItemImage from '../UserItemProyecto/UserItemImage'
import UserItemEmail from '../UserItemProyecto/UserItemEmail'
import ModifyRolUser from './ModifyRolUser'
import DeleteUserProyectoButton from '../UserItemProyecto/DeleteUserProyectoButton'
import { useAddUsersToProject } from 'context/AddUsersToProjectContext'

const UserSelectedList = ({ user }) => {
  const { handleDeleteUser } = useAddUsersToProject()

  const { user: currentUser } = useAuth0()
  const isCurrentUser = user.user_id === currentUser.sub
  return (
    <div className="item-user-proyecto-container">
      <UserItemImage user={user} />
      <UserItemEmail user={user} />
      <ModifyRolUser user={user} role={user.rol} />
      {!isCurrentUser && (
        <DeleteUserProyectoButton
          user={user}
          handleDeleteUser={handleDeleteUser}
        />
      )}
    </div>
  )
}

export default UserSelectedList
