import { NavLink, useParams } from 'react-router-dom'
import { useHandleUrl } from '../../hooks/useHandleUrl'

const routes = [
  {
    label: 'Datos',
    href: '/datos',
    icon: <ion-icon class="icon" name="analytics-outline"></ion-icon>,
    value: 'datos',
  },
  {
    label: 'Tipos',
    href: '/tipos',
    icon: <ion-icon class="icon" name="code-slash-outline"></ion-icon>,
    value: 'tipos',
  },
  {
    label: 'Fábrica',
    href: '/fabrica',
    icon: <ion-icon class="icon" name="business-outline"></ion-icon>,
    value: 'fabrica',
  },
  {
    label: 'Artículos',
    href: '/articulos',
    icon: <ion-icon class="icon" name="build-outline"></ion-icon>,
    value: 'articulos',
  },
  {
    label: 'BimLinks',
    href: '/bimlink',
    icon: <ion-icon class="icon" name="share-social-outline"></ion-icon>,
    value: 'bimlink',
  },
  {
    label: 'Transporte',
    href: '/transporte',
    icon: <ion-icon class="icon" name="cube-outline"></ion-icon>,
    value: 'transporte',
  },
  {
    label: 'Montaje',
    href: '/montaje',
    icon: <ion-icon class="icon" name="extension-puzzle-outline"></ion-icon>,
    value: 'montaje',
  },
  {
    label: 'Repasos',
    href: '/repasos',
    icon: <ion-icon class="icon" name="build-outline"></ion-icon>,
    value: 'repasos',
  },
]

const routeBack = {
  label: 'Volver',
  href: '/',
  icon: <ion-icon class="icon" name="home-outline"></ion-icon>,
  value: 'volver',
}

const Sidebar = () => {
  const { idProject } = useParams()
  const { changeLocation } = useHandleUrl()
  return (
    <nav className="sidebar">
      <div className="sidebar-items">
        {routes.map((item, index) => (
          <div className="sidebar-item" key={index}>
            <NavLink to={changeLocation(`/viewer/${idProject}${item.href}`)}>
              {item.icon}
            </NavLink>

            <span>{item.label}</span>
          </div>
        ))}
      </div>

      {/* <a onClick={() => alert('holaaaaa')}>{routeBack.icon}</a> */}
    </nav>
  )
}

export default Sidebar
