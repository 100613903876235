import axios from 'axios'

export const createTipoBimLinkProyecto = async body => {
  console.log('body', body)
  try {
    const data = await axios.post(`/api/v1/tipobimlink`, body)

    return data.data
  } catch (e) {
    throw new Error(e)
  }
}

export const updateTipoBimLinkProyecto = async (id, body) => {
  try {
    const data = await axios.patch(`/api/v1/tipobimlink/${id}`, {
      ...body,
    })

    return data.data
  } catch (e) {
    throw new Error(e)
  }
}

export const updateAllTiposBimLInkProyecto = async (idProject, version) => {
  await axios.patch(`/api/v1/tipos/${idProject}`, {
    version,
  })
}
