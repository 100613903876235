import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createTipoBimLinkProyecto,
  updateAllTiposBimLInkProyecto,
} from 'api/estructuraCostes/tipobimlinkproyecto'
import { updateTipo } from 'api/estructuraCostes/tiposJitApi'
import { useVersionModel } from 'hooks/useVersionModel'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

export const useCreateTipoBimLinkProyecto = () => {
  const { idProject } = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: body =>
      createTipoBimLinkProyecto({ ...body, proyecto: idProject }),
    onSuccess: resp => {
      const { data } = resp
      queryClient.invalidateQueries({
        queryKey: ['tipos', idProject],
      })
      toast.success('Tipo vinculado con éxito')
    },
    onError: () => {
      toast.error('Error al vincular el tipo')
    },
  })
}

export const useUpdateTipoBimLink = () => {
  const { idProject } = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, body }) => updateTipo(id, body),
    onSuccess: resp => {
      queryClient.invalidateQueries({
        queryKey: ['tipos', idProject],
      })
      toast.success('Tipo actualizado con éxito')
    },
    onError: () => {
      toast.error('Error al actualizar el tipo')
    },
  })
}


export const useUpdateAllTiposBimLink = () => {
  const { idProject } = useParams()
  const { currentVersion } = useVersionModel()
  const queryClient = useQueryClient()
  let toastId
  return useMutation({
    mutationFn: () => updateAllTiposBimLInkProyecto(idProject, currentVersion),
    onMutate: () => {
      toastId = toast.loading('Actualizando tipos...')
    },
    onSuccess: resp => {
      queryClient.invalidateQueries({
        queryKey: ['tipos', idProject],
      })
      toast.dismiss(toastId)
      toast.success('Tipos actualizados con éxito')
    },
    onError: () => {
      toast.dismiss(toastId)
      toast.error('Error al actualizar los tipos')
    },
  })
}
