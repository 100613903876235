import {
  findPropertyNames,
  findPropertynamesCb,
  getBulkPropertiesAsync,
} from './getPropertiesViewer'
import './../extensions/HistogramExtension'
import './../extensions/ModulosExtension'
import './../extensions/SkeletonExtension'
import {
  getBulkPropertiesAllDbIdsPromise,
  getDataAecModelDataDemo,
  getModulos,
} from './getModulosInfo'
import { hexToVector4 } from './colors'

export var viewer
export var allPropertiesModel
export const Autodesk = window.Autodesk
export var externalIds

const launchViewer = (
  urn,
  viewableId,
  setExternalIdsMapping,
  setUniqueProperties,
  setAllModulos,
  setAllDbIds,
  openModalOptions
) => {
  // console.log('setExternalIdsMapping', setExternalIdsMapping)
  setExternalIdsMapping && setExternalIdsMapping(null)
  setUniqueProperties && setUniqueProperties([])
  setAllModulos && setAllModulos([])
  var options = {
    getAccessToken: getForgeToken,
    env: 'AutodeskProduction2',
    api: 'streamingV2',
    // env: 'AutodeskProduction',
    // api:
    //   'derivativeV2' +
    //   (atob(urn.replace('_', '/')).indexOf('emea') > -1 ? '_EU' : ''), // handle BIM 360 US and EU regions
  }

  Autodesk.Viewing.Initializer(options, () => {
    viewer = new Autodesk.Viewing.GuiViewer3D(
      document.getElementById('forgeViewer'),
      {
        extensions: [
          'Autodesk.DocumentBrowser',
          'HistogramExtension',
          // "ModulosExtension",
          // 'SkeletonExtension',
        ],
      }
    )

    viewer.start()

    viewer.setTheme('light-theme')
    var documentId = 'urn:' + urn
    Autodesk.Viewing.Document.load(
      documentId,
      onDocumentLoadSuccess,
      onDocumentLoadFailure
    )

    // SPINNER PARA EL VISOR DE FORGE
    // var spinners = document.getElementsByClassName("forge-spinner");
    // var spinner = spinners[0];
    // spinner.classList.remove("forge-spinner");
    // spinner.classList.add("loaderViewer");
    // spinner.innerHTML =
    //   "<div class='loaderViewer'><img src='./cropped-jit-housing-01-192x192.png' class='loader-image'></img><div class='lds-ellipsis'><div></div><div></div><div></div><div></div></div></div>";
  })

  function onDocumentLoadSuccess(doc) {
    // if a viewableId was specified, load that view, otherwise the default view
    var viewables = viewableId
      ? doc.getRoot().findByGuid(viewableId)
      : doc.getRoot().getDefaultGeometry()
    viewer.loadDocumentNode(doc, viewables).then(i => {
      // any additional action here?
      viewer.addEventListener(
        Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
        () => {
          viewer.setQualityLevel(false, false)
          viewer.setGroundShadow(false)
          viewer.setGroundReflection(false)
          viewer.setProgressiveRendering(true)
          viewer.setEnvMapBackground(false)
          viewer.setGhosting(false)
        }
      )

      viewer.addEventListener(
        Autodesk.Viewing.EXTENSION_LOADED_EVENT,
        event => {
          if (event.extensionId === 'Autodesk.Measure') {
            console.log('Existe la extensión de medidas')
            const units = viewer
              .getExtension('Autodesk.Measure')
              .getUnitOptions()
            console.log('Unit options model', units)
            // Setear las unidades a m (por defecto están en milímetros). Se setean pero no sirve para nada 🙂
            // const measureExtension = viewer.getExtension("Autodesk.Measure");
            // measureExtension.setUnits("m");
          }
        }
      )

      viewer.addEventListener(
        Autodesk.Viewing.MODEL_LAYERS_LOADED_EVENT,
        () => {
          // getBOQ()
          findPropertynamesCb(viewer, res => {
            allPropertiesModel = res
            setUniqueProperties && setUniqueProperties(allPropertiesModel)
          })

          // viewer.impl.setGhostingBrightness(true)

          getBulkPropertiesAllDbIdsPromise().then(res => {
            setAllDbIds(res)
          })

          viewer.model.getExternalIdMapping(res => {
            setExternalIdsMapping && setExternalIdsMapping(res)
            externalIds = res
            console.log('gettingExternalIds')
            getDataAecModelDataDemo().then(result => {
              const linkedDocuments = result.linkedDocuments.map(x => ({
                dbId: res[x.instanceId],
                instanceId: x.instanceId,
              }))

              getModulos(linkedDocuments).then(modules => {
                setAllModulos(modules)
              })
            })
          })
        }
      )
    })
  }

  function onDocumentLoadFailure(viewerErrorCode, viewerErrorMsg) {
    console.error(
      'onDocumentLoadFailure() - errorCode:' +
        viewerErrorCode +
        '\n- errorMessage:' +
        viewerErrorMsg
    )
  }
}

const getForgeToken = callback => {
  fetch('/api/v1/forge/oauth/token').then(res => {
    res.json().then(data => {
      callback(data.access_token, data.expires_in)
    })
  })
}

export default launchViewer

export const getCurrentModelVersion = () => {
  if (!viewer)
    throw new Error('Viewer not initialized yet or not found in DOM tree 😥')
  if (!viewer.model) throw new Error('Model not loaded yet 😥')

  const urn = viewer.model.getSeedUrn()
  const currentVersion = atob(urn.replace('_', '/')).split('?version=')[1]

  return currentVersion
}

export const getSelectionViewer = () => {
  if (!viewer)
    throw new Error('Viewer not initialized yet or not found in DOM tree 😥')
  if (!viewer.model) throw new Error('Model not loaded yet 😥')

  const selection = viewer.getSelection()
  return selection
}
export const filterAndColor = data => {
  const allDbIds = data.reduce((acc, item) => acc.concat(item.dbIds), [])

  viewer.isolate(allDbIds)
  viewer.fitToView(allDbIds)

  if (allDbIds.length === 0) {
    viewer.clearThemingColors(viewer.model)
  } else {
    data.forEach(item => {
      item.dbIds.forEach(dbId => {
        viewer.setThemingColor(
          dbId,
          hexToVector4(item.color),
          viewer.model,
          true
        )
      })
    })
  }
}
