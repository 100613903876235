import { createContext, useContext, useEffect, useState } from 'react'
import { ViewerContext } from './ViewerContext'
import { viewer } from '../utils/launchViewer'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { createModulos } from '../utils/handleModulo'

export const ArticulosContext = createContext()

export const ArticulosProvider = ({ children }) => {
  const { idProject } = useParams()
  const queryClient = useQueryClient()
  const { externalIdsMapping } = useContext(ViewerContext)
  const [modulosAreLoaded, setModulosAreLoaded] = useState(false)
  const [currentModulosInModel, setCurrentModulosInModel] = useState([])

  const data = {
    modulosAreLoaded,
    currentModulosInModel,
  }

  return (
    <ArticulosContext.Provider value={data}>
      {children}
    </ArticulosContext.Provider>
  )
}
