import { Navigate, Outlet, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getAllCelulasByProjectId } from '../../api/jitApi'
import ArticulosTableLoader from '../Loaders/ArticulosTableLoader'
import FabricaContainer from '../Fabrica/FabricaContainer'

const ArticulosContainer = () => {
  const { idProject } = useParams()
  // TODO: Sacar las células
  const {
    isLoading,
    isError,
  } = useQuery(['celulas', idProject], () =>
    getAllCelulasByProjectId(idProject)
  )

  if (isLoading) return <ArticulosTableLoader />
  if (isError) return <Navigate to="" />

  return (
    <FabricaContainer>
      <Outlet />
    </FabricaContainer>
  )
}

export default ArticulosContainer
