import React from 'react'
import { Button } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdateProject } from '../../../lib/react-query/queries'
import { toast } from 'react-hot-toast'
import { useHandleUrl } from '../../../hooks/useHandleUrl'

const ButtonsContainerCosteManoDeObra = ({ disabled, body }) => {
  const navigate = useNavigate()
  const { changeLocation } = useHandleUrl()
  const { idProject } = useParams()
  const { mutateAsync: updateProject, isLoading: isLoadingUpdate } =
    useUpdateProject()

  const handleSubmit = async () => {
    await updateProject({ idProject, body })
    console.log('Update Project!')
    toast.success('Coste de mano de obra añadido correctamente')
    navigate(changeLocation(`/viewer/${idProject}/fabrica`))
  }
  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flex: '1',
      }}
    >
      <div>
        <Button
          className="button-new-partida button-new-partida--white"
          color="none"
          // onClick={() =>
          //   navigate(
          //     changeLocation(
          //       `/viewer/${idProject}/articulos/gestionar-celulas`
          //     )
          //   )
          // }
          onClick={() =>
            navigate(changeLocation(`/viewer/${idProject}/fabrica`))
          }
        >
          Cancelar
        </Button>
      </div>
      <div>
        <Button
          className="button-new-partida"
          color="none"
          disabled={disabled}
          onClick={handleSubmit}
        >
          Añadir coste de mano de obra
        </Button>
      </div>
    </div>
  )
}

export default ButtonsContainerCosteManoDeObra
